import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Avatar from '@material-ui/core/Avatar'
import { IconButton } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { defaultPallet} from '../../assets/jss/colorSystem';


const styles = (theme) => ({
  paper:{
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '10px',
    margin: '10px',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#808080',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#808070',
    },
    scrollbarColor: '#808080',
    scrollbarWidth: 'thin', 
  },
  table: {
    margin:40,
  },
  tableRoot:{    
    [theme.breakpoints.down('xl')]:{
      width: '90%',
    },
    [theme.breakpoints.down('lg')]:{
      width: '92%',
    },
    [theme.breakpoints.down('md')]:{
      width: '94%',
    },
    [theme.breakpoints.down('sm')]:{
      width: '95%',
    },
     [theme.breakpoints.down('xs')]:{
      width: '96%',
    }
  },
  tableHeader: {
    paddingRight: 4,
    paddingLeft: 5,
    fontWeight: 'bold'
  },
  avatar: {
    width: 60,
    height: 60,
    // marginLeft:'35%',
    marginBottom: '10%'
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40,
    paddingRight: 4,
    paddingLeft: 5,
  },

  
})


class TableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteModalOpen: false,
      isEditMode: false,
      previous:{},
      selectedProject:{},
      selectedProjectId:''
    }
    this.handleClickDelete = this.handleClickDelete.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.onCellChange = this.onCellChange.bind(this)
    this.handleDeleteProject = this.handleDeleteProject.bind(this)
    this.onToggleCellEditMode = this.onToggleCellEditMode.bind(this)
    this.onDoneEdit = this.onDoneEdit.bind(this)

  }



  onCellChange =(event) => {
    const {previous, selectedProject}=this.state    
    this.setState({
      selectedProject: {   
        ...selectedProject,[event.target.name]: event.target.value       
    }
})
      
  };
 
  onToggleCellEditMode (selectedProj) {
    const {projects}=this.props
    projects.map(project => {
      if (project._id === selectedProj._id) {
       project.isEditMode = !project.isEditMode
      }
    })
    this.setState({
      selectedProject: selectedProj,
      previous: selectedProj 
    })  
   
  };

  handleDeleteProject(){
    const{deleteProject}=this.props
    const{selectedProjectId}=this.state
    this.setState({
      isDeleteModalOpen: false,
    })
    deleteProject(selectedProjectId)
  }

  onRevert (id) {
    const { previous,selectedProject}=this.state
    const {projects,editProject}=this.props
    if(previous[id] === id){
      this.setState({selectedProject: previous})
    }
    this.onToggleCellEditMode(previous);
  
  };
  
  onDoneEdit () {
    const { previous,selectedProject}=this.state
    const {projects,editProject}=this.props
    this.onToggleCellEditMode(selectedProject);    
    editProject(
      selectedProject._id,
      selectedProject.title,
      selectedProject.description,
      selectedProject.geo,
      selectedProject.nexus,
      selectedProject.avatar,
      selectedProject.tags,
    );
  };

  
  handleCloseDialog(){
    const{setProjectModalOpen}=this.props
    this.setState({
      isDeleteModalOpen: false,
      isEditMode: false,
    })
    setProjectModalOpen(false)

  }

  handleClickDelete(id){
    this.setState({
      isDeleteModalOpen: true,
      selectedProjectId: id
    })
  }

  render() {
    const { classes,projects,editProject } = this.props
    const { 
      isEditMode,
      isDeleteModalOpen,
      selectedProject,
     } = this.state 
     const cellChange =this.onCellChange

    const CustomTableCell = ({ project, name,cellChange }) => {
      const { isEditMode } = project;
      return (<TableCell align="left" className={classes.tableCell}>
          {isEditMode ? (
            <Input
              value={selectedProject[name]}
              name={name}
              onChange={(e) => cellChange(e)}
              className={classes.input}
            />
          ) 
          : (
            project[name]
          )}
        </TableCell>)
    }
 
    return (
        <>
        <Dialog open={isDeleteModalOpen} onClose={this.handleCloseDialog}>
                <DialogTitle id="delete-dialog">Delete Item</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Are you sure?
                    </DialogContentText>         
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialog}>
                    Cancel
                    </Button>
                    <Button onClick={this.handleDeleteProject}>
                    Ok
                    </Button>
                </DialogActions>
            </Dialog>
        <Paper className={classes.paper}>
          <Table className={classes.table} classes={{root: classes.tableRoot}} size='small'>
            <TableHead>
              <TableRow>                
                <TableCell align='left' className={classes.tableHeader}>
                  Id
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                  Avatar
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                  Title
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                  Description
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                  Tags
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                  Geo
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                  Nexus
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                </TableCell>
                <TableCell align='left' className={classes.tableHeader}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects && projects.map((project) => (
                <TableRow key={project._id}>
                  <CustomTableCell {...{ project, name: "_id", cellChange}} />
                  <TableCell align="left" className={classes.tableCell}>
                    <Avatar src={project.avatar} />
                  </TableCell>
                  <CustomTableCell {...{ project, name: "title", cellChange}} key="title" />
                  <CustomTableCell {...{ project, name: "description", cellChange }} key="description" />
                  <CustomTableCell {...{ project, name: "tags", cellChange}} key="tags" />
                  <CustomTableCell {...{ project, name: "geo", cellChange }} key="geo" />
                  <CustomTableCell {...{ project, name: "nexus", cellChange }} key="nexus" />
                  <TableCell className={classes.selectTableCell}>
                    <Button>Open</Button>
                  </TableCell>
                  <TableCell className={classes.selectTableCell}>
                  {project.isEditMode ? (
                    <>
                      <IconButton
                        aria-label="done"
                        onClick={this.onDoneEdit}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton
                        aria-label="revert"
                        onClick={() => this.onRevert(project._id)}
                      >
                        <RevertIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      aria-label="edit"
                      onClick={() => this.onToggleCellEditMode(project)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
                  
                  <TableCell className={classes.selectTableCell}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => this.handleClickDelete(project._id)}
                    >
                    <DeleteIcon />
                    </IconButton>
                  </TableCell>
                            
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
    
            
   
      </>
    
        )
  }
}
TableView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setMapWigetView: PropTypes.func.isRequired,
  setProjectModalOpen: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    geo: PropTypes.string,
    nexus: PropTypes.string,
    tags: PropTypes.string,
    avatar: PropTypes.string,
  })).isRequired,
  createProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
}
export default withStyles(styles)(TableView)
