import {
  bindActionCreators
} from 'redux';
import {
  connect
} from 'react-redux';
import PropTypes from 'prop-types';
import {
  logout,
  receiveAuth,
} from '../actions/auth';
import {
  setDarkMode,
} from '../actions/services';


import {
  editUser,
  searchUsers,
} from '../actions/users';
import {
  socketsConnect,
  missingSocketConnection,

} from '../actions/sockets';

import * as fromState from '../reducers';
import Main from '../components/Main';


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isFetching: state.services.isFetching,
    activeUser: {
      ...state.auth.user,
      token: state.auth.token,
      otp: state.auth.otp,
    },
   
  
   
    isConnected: state.services.isConnected,
    isDark: state.services.isDarkMode,
    conference: state.services.conference,
    projects: state.services.projects,

    classes: {},
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logout,
    editUser,
    socketsConnect,
    missingSocketConnection,

    setDarkMode,
    receiveAuth,
    searchUsers,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Main);
