import { store } from 'react-notifications-component';
import validateEmailAddress from '../utils/legit';
import * as types from '../constants/auth';
import callApi from '../utils/call-api';
import { redirect } from './services';
import i18n from '../i18n';
import { handleBeforeUnload } from './sockets';
/*import { unregister } from '../registerServiceWorker';*/

export function signup(email, displayName, fullname, mobile,affiliation, password, t) {
  return (dispatch, getState) => {
    const { isFetching } = getState().services;

    if (isFetching.signup) {
      return Promise.resolve();
    }
    validateEmailAddress(email)
      .then((result) => {
        if (result.isValid) {
          dispatch({
            type: types.SIGNUP_REQUEST,
          });
          store.addNotification({
            title: t('INFO!'),
            message: t('Email is valid ! Now requesting signup'),
            type: 'info',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
          return callApi(
            '/register',
            undefined,
            undefined,
            { method: 'POST' },
            {
              email,
              displayName,
              fullname,
              mobile,
              affiliation,
              password,
            },
          )
            .then((json) => {
              if (!json.token) {
                store.addNotification({
                  title: t('ERROR!'),
                  message: t('Token has not been provided'),
                  type: 'danger',
                  insert: 'bottom',
                  container: 'bottom-left',
                  animationIn: ['animated', 'fadeIn'],
                  animationOut: ['animated', 'fadeOut'],
                  dismiss: {
                    duration: 6000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                    waitForAnimation: false,
                  },
                });
              }

              // Save JWT to localStorage
              localStorage.setItem('nexusustain-token', json.token);
              localStorage.setItem('nexusustain-user', JSON.stringify(json.user));
              localStorage.setItem('nexusustain-otp', json.otp);
              dispatch({
                type: types.SIGNUP_SUCCESS,
                payload: json,
              });
            })
            .catch((reason) => {
              store.addNotification({
                title: t('ERROR!'),
                // eslint-disable-next-line
                message: t('Signup failed! Server says: ')+`${t(reason.message)}`,
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-left',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: {
                  duration: 6000,
                  onScreen: false,
                  pauseOnHover: true,
                  showIcon: true,
                  waitForAnimation: false,
                },
              });
              dispatch({
                type: types.SIGNUP_FAILURE,
                payload: reason,
              });
            });
        }
          store.addNotification({
            title: t('ERROR!'),
            message: t('Valid email has to be provided'),
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 6000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
      })
      .catch(() => {
        store.addNotification({
          title: t('ERROR!'),
          message: t('Valid email has to be provided'),
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 6000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
            waitForAnimation: false,
          },
        });
      });
  };
}

export function login(email, password, t) {
  return (dispatch, getState) => {
    const { isFetching } = getState().services;

    if (isFetching.login) {
      return Promise.resolve();
    }
    dispatch({
      type: types.LOGIN_REQUEST,
    });

    return callApi(
      '/login',
      undefined,
      undefined,
      { method: 'POST' },
      {
        email,
        password,
      },
    )
      .then((json) => {
        if (!json.token) {
          localStorage.removeItem('nexusustain-token');
          localStorage.removeItem('nexusustain-user');
          localStorage.removeItem('nexusustain-otp');
          store.addNotification({
            title: t('ERROR!'),
            message: t('Login failure, server has not sent any token!!'),
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 6000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
        } else {
          // Save JWT to localStorage
          localStorage.setItem('nexusustain-token', json.token);
          localStorage.setItem('nexusustain-user', JSON.stringify(json.user));
          localStorage.setItem('nexusustain-otp', json.otp);
          localStorage.setItem('nexusustain-isdark', json.isDark);
          store.addNotification({
            title: t('SUCCESS!'),
            // eslint-disable-next-line
            message: t('Welcome back')+'! '+`${json.user.displayName}`,
            type: 'success',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
          dispatch({
            type: types.LOGIN_SUCCESS,
            payload: json,
          });
          if (json.user.language)i18n.changeLanguage(json.user.language);
          
        }
      })
      .catch((reason) => {
        dispatch({
          type: types.LOGIN_FAILURE,
          payload: reason,
        });
        localStorage.removeItem('nexusustain-token');
        localStorage.removeItem('nexusustain-user');
        localStorage.removeItem('nexusustain-otp');
        localStorage.removeItem('nexusustain-isdark');
        store.addNotification({
          title: t('ERROR!'),
          // eslint-disable-next-line
          message: t('Login failure')+' '+t('Server says')+`: ${t(reason.message)}`,
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 6000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
            waitForAnimation: false,
          },
        });
      });
  };
}

export function logout(t) {
  return (dispatch, getState) => {
    const { isFetching } = getState().services;

    if (isFetching.logout) {
      return Promise.resolve();
    }
    const isCertain = handleBeforeUnload(t);
    if (isCertain === true) {
      store.addNotification({
        title: t('INFO!'),
        message: t('Logging out'),
        type: 'info',
        insert: 'bottom',
        container: 'bottom-left',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: false,
          pauseOnHover: true,
          showIcon: true,
          waitForAnimation: false,
        },
      });
      dispatch({
        type: types.LOGOUT_REQUEST,
      });

      return callApi('/logout')
        .then((json) => {
          // Remove JWT from localStorage
          store.addNotification({
            title: t('SUCCESS!'),
            message: t('Successfully logged out of NexuSustain!'),
            type: 'success',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
          localStorage.removeItem('nexusustain-token');
          localStorage.removeItem('nexusustain-user');
          localStorage.removeItem('nexusustain-otp');
          localStorage.removeItem('nexusustain-isdark');
          localStorage.removeItem('nexusustain-channel');

          // redirect to access in case of failure
          dispatch({
            type: types.LOGOUT_SUCCESS,
            payload: json,
          });
          /*unregister();*/
          //window.location.href = `${window.location.protocol}//${window.location.host}`;
        })
        .catch((reason) => {
          store.addNotification({
            title: t('ERROR!'),
            // eslint-disable-next-line
            message: t('Logout failure, server says: ')+`${t(reason.message)}`,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 6000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
          dispatch({
            type: types.LOGOUT_FAILURE,
            payload: reason,
          });
        });
    }

      store.addNotification({
        title: t('INFO!'),
        message: t('Logout has been canceled!'),
        type: 'info',
        insert: 'bottom',
        container: 'bottom-left',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: false,
          pauseOnHover: true,
          showIcon: true,
          waitForAnimation: false,
        },
      });
  };
}

export function receiveAuth(t) {
  return (dispatch, getState) => {
    const { token, otp } = getState().auth;
    dispatch({
      type: types.RECIEVE_AUTH_REQUEST,
    });

    return callApi('/users/me', token, otp)
      .then((json) => {
        if (json.user) {
          
          localStorage.setItem('nexusustain-token', token);
          localStorage.setItem('nexusustain-user', JSON.stringify(json.user));
          localStorage.setItem('nexusustain-otp', otp);
          localStorage.setItem('nexusustain-isdark', json.isDark);
          dispatch({
            type: types.RECIEVE_AUTH_SUCCESS,
            payload: json,
          });
          
        } else {
          localStorage.removeItem('nexusustain-token');
        localStorage.removeItem('nexusustain-user');
        localStorage.removeItem('nexusustain-otp');
        localStorage.removeItem('nexusustain-isdark');
        store.addNotification({
          title: t('ERROR!'),
          // eslint-disable-next-line
          message: t('Checking security Token failure, server says')+`${t(reason.message)}`,
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 6000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
            waitForAnimation: false,
          },
        });
        dispatch({
          type: types.RECIEVE_AUTH_FAILURE,
          payload: reason,
        });
        }
      })
      .catch((reason) => {
        localStorage.removeItem('nexusustain-token');
        localStorage.removeItem('nexusustain-user');
        localStorage.removeItem('nexusustain-otp');
        localStorage.removeItem('nexusustain-isdark');
        store.addNotification({
          title: t('ERROR!'),
          // eslint-disable-next-line
          message: t('Checking security Token failure, server says')+`${t(reason.message)}`,
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 6000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
            waitForAnimation: false,
          },
        });
        dispatch({
          type: types.RECIEVE_AUTH_FAILURE,
          payload: reason,
        });
   /*     dispatch(logout());
        dispatch(redirect('/(access)?'));*/
      });
  };
}



