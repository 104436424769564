import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
const lang = localStorage.getItem('nexusustain-language');
i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: lang || 'fa',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      wait: true,
    },
});

export default i18n;
