import {
  defaultFont,
  grayColor,
  whiteColor,
  defaultPallet,
} from '../colorSystem';

import dropdownStyle from '../dropdownStyle';

const navbarLinksStyle = (theme) => ({
  ...dropdownStyle(theme),
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: '30%',
    minWidth: '300px',
    padding: theme.spacing(3),
  },
  createDirectButton: {
    iconStyle: {
      color: defaultPallet[4],
      cursor: 'pointer',
      backgroundColor: grayColor[12],
      borderRadius: '30px',
      marginLeft: '5px',
      '&:hover': {
        color: whiteColor,
        backgroundColor: defaultPallet[24],
      },
      float: 'right',
    },
    fontSize: '1em',
    maxHeight: '64px',
    opacity: '0.8',
    color: whiteColor,
    backgroundColor: defaultPallet[25],
    '&:hover': {
      backgroundColor: defaultPallet[0],
      color: defaultPallet[6],
      opacity: '1',
    },
    zIndex: '4',
    position: 'relative',
    right: '0px',
    margin: 'auto',
    display: 'block',
    bottom: '0px',
    verticalAlign: 'middle',
    borderRadius: '0 !important',
    width: '100% !important',

  },
  createDirectButtonDark: {
    fontSize: '1em',
    maxHeight: '64px',
    opacity: '0.8',
    color: defaultPallet[9],
    backgroundColor: defaultPallet[15],
    '&:hover': {
      backgroundColor: defaultPallet[10],
      color: whiteColor,
      opacity: '1',
    },
    zIndex: '4',
    position: 'relative',
    right: '0px',
    margin: 'auto',
    display: 'block',
    bottom: '0px',
    verticalAlign: 'middle',
    borderRadius: '0 !important',
    width: '100% !important',
  },
  buttonLink: {
    '&:hover': {
      backgroundColor: defaultPallet[2],
    },
  },
  icons: {
    color: whiteColor,
  },
  userImg: {
    verticalAlign: 'middle',
    display: 'inline-block',
    width: '32px',
    height: '32px',
    margin: '2px',
    textAlign: 'center',
  },
  notifications: {
    zIndex: '4',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '2px',
      border: `1px solid ${whiteColor}`,
      right: '4px',
      fontSize: '9px',
      background: defaultPallet[2],
      color: whiteColor,
      minWidth: '16px',
      height: '16px',
      borderRadius: '10px',
      textAlign: 'center',
      lineHeight: '16px',
      verticalAlign: 'middle',
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      ...defaultFont,
      fontSize: '14px',
      marginRight: '8px',
    },
  },
  manager: {
    display: 'inline-block',
  },
  buttonGroupRoot:{
    width: 580
  },
  iconButton: {
    '&:hover': {
      backgroundColor: defaultPallet[6],
    },
    padding: '5px',
    marginLeft: '5px',
  },
  iconStyle: {
    color: defaultPallet[0],
    '&:hover': {
      color: whiteColor,
    },
    fontSize: '15px',
  },
  iconButtonDark: {
    padding: '5px',
    marginLeft: '5px',
  },
  iconStyleDark: {
    color: whiteColor,
    fontSize: '15px',
  },
  closeButton: {
    position: "absolute",
    right: '16px',
    top: '16px',
    color: '#ff3300',
  },
  dialogPaper: {
    overflowY: "auto",
    padding: "10px",
     '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#808080',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#808070',
    },
    scrollbarColor: '#808080',
    scrollbarWidth: 'thin', 
  },
  sendBtn:{
    backgroundColor: '#33ccff',
    width:'90%',
  }
});

export default navbarLinksStyle;
