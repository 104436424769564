import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {
  Collapse, Dialog, DialogContent,
  Drawer,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { withDialog } from 'muibox';
import Translate from '@material-ui/icons/Translate';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import i18n from '../i18n';
import styles from '../assets/jss/components/userProfileSidebarStyle';
import OnlineStyledBadgeAvatar from './OnlineStyledBadgeAvatar';
import OfflineStyledBadgeAvatar from './OfflineStyleBadgeAvatar';
import Close from '@material-ui/icons/Close';
import ImageUploading from "react-images-uploading";
import {
  Add, Brightness4, Brightness5,
  Chat,
  Delete, DeleteOutlined, EditOutlined, ExpandLess, ExpandMore,
  ForumOutlined, HelpOutline, Image,
  NotificationsOutlined,
  PaymentOutlined, Person, Slideshow
} from '@material-ui/icons';
import Edit from '@material-ui/icons/Edit';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Lock from '@material-ui/icons/LockOutlined';

import Clear from '@material-ui/icons/Clear';

class UserProfileSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      isLangMenuOpen: false,
      isDarkMode: false,
      isHelpRunning: false,
      isGuidedTourRunning: false,
      isEditMode: false,
      isUploaderMode: true,
      isChangePasswordDialogOpen: false,
      email: props.activeUser.email || '',
      displayName: props.activeUser.displayName || '',
      avatar: props.activeUser.avatar || "",
      images: [props.activeUser.avatar] || [],
      language: props.activeUser.language || 'en',
    };
    this.handleOpenDrawer = this.handleOpenDrawer.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleChangeLang = this.handleChangeLang.bind(this);
    this.handleAvatarChange  = this.handleAvatarChange.bind(this);
    this.handleClickLang = this.handleClickLang.bind(this);
    this.handleClickHelp = this.handleClickHelp.bind(this);
    this.handleCloseHelp = this.handleCloseHelp.bind(this);

    this.handleClickChangePassword = this.handleClickChangePassword.bind(this);
    this.handleClosePasswordDialog = this.handleClosePasswordDialog.bind(this);
  }

  componentDidMount() {
    this.setState({
      email: this.props.activeUser.email,
      displayName: this.props.activeUser.displayName,
      avatar: this.props.activeUser.avatar || "",
      images: [this.props.activeUser.avatar] || [],
      language: this.props.activeUser.language,
      isDarkMode: this.props.isDark,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeUser.email !== prevProps.activeUser.email
      || this.props.activeUser.avatar !== prevProps.activeUser.avatar
      || this.props.activeUser.language !== prevProps.activeUser.language
      || this.props.activeUser.displayName !== prevProps.activeUser.displayName
      || this.props.isDark !== prevProps.isDark) {
      this.setState({
        email: this.props.activeUser.email,
        displayName: this.props.activeUser.displayName,
        avatar: this.props.activeUser.avatar,
        language: this.props.activeUser.language,
        isDarkMode: this.props.isDark,
      });
    }
  }

  handleOpenDrawer = (event) => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      isDrawerOpen: false,
    });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      isEditMode: true,
    });
  };

  handleSaveClick = () => {
    this.props.onEditProfileClick({
      email: this.state.email,
      displayName: this.state.displayName,
      avatar: this.state.avatar && this.state.avatar !== null ? this.state.avatar : this.props.activeUser.avatar,
      language: this.state.language,
      isDarkMode: this.state.isDarkMode,
    }, this.props.t);
    this.handleCloseDrawer();
    this.setState({isEditMode: false})
  };

  handleCancelClick = () => {
    this.setState({isEditMode: false})
  }

  handleLogoutClick = () => {
    this.props.onLogoutClick(this.props.t);
    this.handleCloseDrawer();
  };

  handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('nexusustain-language', lang);
    this.setState({
      language: lang,
      isLangMenuOpen: !this.state.isLangMenuOpen,
      isEditMode: true,
    });
  }

  handleAvatarChange = (imageList, addUpdateIndex) => {
    if (imageList[0]) {
      if (imageList[0].data_url) {
        this.setState({
          avatar: imageList[0].data_url,
          isEditMode: true,
          isUploaderMode: true,
        });
      } else {
        this.setState({
          avatar: "",
          images: [],
          isEditMode: true,
          isUploaderMode: true,
        });
      }
    }
  };

  handleClickLang =() => {
    this.setState({
      isLangMenuOpen: !this.state.isLangMenuOpen
    })
  }

  handleClickHelp =()=>{
    this.setState({isHelpRunning: !this.state.isHelpRunning})
  }

  handleCloseHelp =()=>{
    this.setState({isHelpRunning: false})
  }



  handleClickChangePassword = () => {
    this.setState({
      isChangePasswordDialogOpen: !this.state.isChangePasswordDialogOpen
    })
  }

  handleClosePasswordDialog = () => {
    this.setState({
      isChangePasswordDialogOpen: false
    })
  }

  render() {
    const {
      isDrawerOpen,
      avatar,
      displayName,
      email,
      isLangMenuOpen,
      isHelpRunning,
      isEditMode,
      isUploaderMode,
      isChangePasswordDialogOpen,
    } = this.state;
    const {
      classes, isConnected, activeUser, t, isDark,
    } = this.props;
    const images = [avatar];
    return (
      <>
        {isConnected ?
          <>
            <OnlineStyledBadgeAvatar
              onClick={() => this.handleOpenDrawer}
              userAvatar={avatar}
              size="medium"
            />
            <Typography className={isDark ? classes.displayNameDark : classes.displayName} variant="body1" onClick={this.handleOpenDrawer}>
              Hi,{displayName}
              <Box component="span" className={isDark ? classes.textDark : classes.text} variant="body2">
                {t('your account')}
                <KeyboardArrowDown className={classes.arrowDown} onClick={this.handleOpenDrawer} />
              </Box>
            </Typography>
          </>
          :
          <>
            <OfflineStyledBadgeAvatar
              onClick={() => this.handleOpenDrawer}
              userAvatar={avatar}
              size="medium"
            />
            <Typography className={isDark ? classes.displayNameOfflineDark : classes.displayNameOffline} variant="body1">
              {displayName}
            </Typography>
            <br />
            <Typography className={isDark ? classes.textDark : classes.text} variant="subtitle2" onClick={this.handleOpenDrawer}>{t('your account')}</Typography>
            <KeyboardArrowDown className={classes.arrowDown} onClick={this.handleOpenDrawer} />
          </>

        }


        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={this.handleCloseDrawer}
          classes={{ paper: classes.drawerPaper}}
        >
          <div className={classes.drawerHeader}>
            <Typography variant="h6" className={classes.drawerTitle}>
              {t('User Profile')}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={this.handleCloseDrawer}
            >
              <Close />
            </IconButton>
          </div>
          <div className={classes.margin} />
          <Grid container>
            <Grid item xs={12} sm={4} md={4} style={{paddingLeft: '6%'}}>
              <ImageUploading
                value={avatar}
                onChange={this.handleAvatarChange}
                maxNumber={1}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <>
                    {avatar === "" || avatar == null ? (
                      <>
                        <div
                          className={classes.imageUploadDiv}
                          {...dragProps}
                          // style={isDragging ? { backgroundColor: defaultPallet[4] } : null}
                        >
                          <Person className={classes.userIcon} />
                        </div>
                        <IconButton
                          aria-label="Add image"
                          component="span"
                          className={classes.addIcon}
                          onClick={onImageUpload}
                        >
                          <Add />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <img
                          src={imageList}
                          alt=""
                          className={classes.image}
                          onClick={onImageUpload}
                        />
                        <IconButton
                          aria-label="Edit image"
                          component="span"
                          className={classes.icon}
                          onClick={onImageUpload}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="Remove image"
                          component="span"
                          className={classes.icon}
                          onClick={() => onImageRemove(0)}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </>
                )}
              </ImageUploading> </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <TextField
                name="displayName"
                placeholder={t('Edit your display name')}
                type="text"
                value={displayName}
                className={classes.textField}
                onChange={this.handleInputChange}
              />
              <TextField
                name="email"
                placeholder={t('Edit your email address')}
                type="text"
                value={email}
                className={classes.textField}
                onChange={this.handleInputChange}
              />

              <Button
                className={classes.logoutBtn}
                size="small"
                onClick={this.handleLogoutClick}
              >
                {t('Sign Out')}
              </Button>
              <div className={classes.margin} />
            </Grid>
          </Grid>

          <Divider variant="middle" />
          <div className={classes.margin} />

          <List className={classes.root}>
            <ListItem
              button
              className={classes.listItem}
              onClick={this.handleClickChangePassword}
            >
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar} variant="rounded">
                  <Lock className={classes.listItemIcon}/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t('Password')}
                secondary={t('Change your password')}
                className={classes.primary}
                classes={{primary: classes.primary, secondary: classes.secondary}}
              />
            </ListItem>

            

            

        

            <ListItem
              button
              className={classes.listItem}
              onClick={this.handleClickLang}>
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar} variant="rounded">
                  <Translate className={classes.listItemIcon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t('Language')}
                secondary={t('select your language')}
                classes={{primary: classes.primary, secondary: classes.secondary}}
              />
              {isLangMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isLangMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}
                          onClick={() => { this.handleChangeLang('en'); }}
                >
                  <ListItemText primary="English" />
                </ListItem>
                <ListItem button className={classes.nested}
                          onClick={() => { this.handleChangeLang('fa'); }}
                >
                  <ListItemText primary="Persian" />
                </ListItem>
              </List>
            </Collapse>

            {/* <ListItem
              button
              className={classes.listItem}
              onClick={this.handleClickHelp}
            >
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar}variant="rounded" >
                  <HelpOutline className={classes.listItemIcon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t('Help')}
                secondary={t('Essential help and support')}
                classes={{primary: classes.primary, secondary: classes.secondary}}
              />
            </ListItem> */}
          </List>

          {isEditMode ?
            <div>
              <Button
                size="small"
                onClick={this.handleSaveClick}
                className={classes.saveBtn}
              >
                {t('Save')}
              </Button>
              <Button
                size="small"
                onClick={this.handleCancelClick}
                className={classes.cancelBtn}
              >
                {t('Cancel')}
              </Button>
              <div className={classes.margin} />
            </div> : null}
        </Drawer>


        <Dialog
          onClose={this.handleClosePasswordDialog }
          open={isChangePasswordDialogOpen}
          // classes={{paper: classes.dialogPaper}}
        >
          <DialogContent >
            <Typography variant="body2">
              {t('Change password')}
            </Typography>
          </DialogContent>
        </Dialog>
      </>
    );
  }

}
UserProfileSidebar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,


  activeUser: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
    language: PropTypes.string,
    isMember: PropTypes.bool,
    isCreator: PropTypes.bool,
    isManager: PropTypes.bool,
    isChannelMember: PropTypes.bool,
    token: PropTypes.string,
    channel: PropTypes.object,
    otp: PropTypes.string,

    metrics: PropTypes.array,
  }).isRequired,
  onEditProfileClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(withDialog()(withTranslation()(UserProfileSidebar)));
