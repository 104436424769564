import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { signup, login, receiveAuth} from '../actions';
import LandingPage from '../components/LandingPage';

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.services.errors.auth,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
      signup,
      login,
      receiveAuth,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
