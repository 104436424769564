import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import styles from "../assets/jss/components/landingPageStyle";
import Logo from "../assets/images/logo.png";
/* import WeatherComponentMulti from '../components/WeatherComponentMulti' */

class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isFetching: false,
      isSignUp: false,
    };
    this.goToSignUpForm = this.goToSignUpForm.bind(this);
  }

  componentDidMount() {
    this.props
      .receiveAuth(this.props.t)
      .then(() => {
        this.setState({ isFetching: false });
        console.log("component mounted");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  goToSignUpForm() {
    this.setState({ isSignUp: !this.state.isSignUp }, () => {
      console.log("isSignUp is:", this.state.isSignUp);
    });
  }

  render() {
    const {
      classes,
      signup,
      login,
      isAuthenticated,
      error,
      receiveAuth,
      t,
      location,
    } = this.props;
    const { isSignUp, isFetching } = this.state;
    if (isAuthenticated && !isFetching) {
      const loc = window.location.href;
      if (typeof loc === 'string') {
        const index = loc.indexOf("/access/");
        if (index > -1) {
          const channelId = loc.substring(index + 9, loc.length);
          if (typeof channelId === 'string') {
            if (channelId.length > 5) {
              localStorage.setItem("nexusustain-channel", channelId);
            }
          }
        }
      }

      return <Redirect to="/" />;
    }
    const loader = (
      <Loader
        type="ball-beat"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          transform: "scale(0.5, 0.5)",
        }}
      />
    );
    if (!isAuthenticated && isFetching) {
      return (
        <div className={classes.fullWidth}>
          <Typography className={classes.text} variant="body1">
            {t("Loading NexuSustain")}
            {loader}
          </Typography>
        </div>
      );
    }

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          alignItems="flex-start"
          className={classes.imageGrid}
        >
          {/*  <WeatherComponentMulti style={{buttom: '0px'}}/> */}
          <div className={classes.overlayDiv}>
            <div className={classes.brandWrapper}>
              <img src={Logo} className={classes.logoImage} />
              <div className={classes.margin}>
                <Typography variant="h5" className={classes.brand}>
                  NexuSustain
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.brandSubText}
                >
                  {t("Exploratory")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.brandSubText}
                >
                  {t("Sustainability NEXUS")}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classes.formGrid}>
          <div className={classes.paper}>
            {!isSignUp ? (
              <LoginForm
                onSubmit={login}
                isAuthenticated={isAuthenticated}
              />
            ) : (
              <SignupForm onSubmit={signup} />
            )}
          </div>
          <div className={classes.footerTextDiv}>
            <Typography variant="body1" className={classes.footerText}>
              {!isSignUp ? t("Not a member?") : t("Already a member?")}
            </Typography>
            <a href="#" onClick={this.goToSignUpForm} className={classes.link}>
              {!isSignUp ? t(" Sign up") : t(" Sign in")}
            </a>
          </div>
        </Grid>
      </Grid>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  signup: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  receiveAuth: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
};
export default withStyles(styles)(withTranslation()(LandingPage));
