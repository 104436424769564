import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import 'typeface-roboto/index.css';
import Loader from 'react-loaders';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import App from './apps/App';
import configureStore from './store';
import './assets/styles/index.css';
import 'moment/locale/fa';
import { handleBeforeUnloadMain } from './actions/sockets';
import './assets/styles/main.scss';

import i18n from './i18n';
import { register, updateServiceWorker } from './registerServiceWorker';
window.addEventListener('beforeunload', (event) => {
  event.preventDefault();
  
  //window.location.assign(window.location.host); 
  event.returnValue = 'Are you sure you want to close NexuSustain Application?';
});
window.addEventListener('unload', (event) => {
  event.preventDefault();

  //window.location.assign(window.location.host); 
  event.returnValue = 'Are you sure you want to close NexuSustain Application?';
});


const lang = localStorage.getItem('nexusustain-language');
if (lang) {
  i18n.changeLanguage(lang);
  moment.locale(lang);
}else{
  i18n.changeLanguage('en');
  moment.locale('en');
}
const appLoader = ()=> {
  return (
    <div style={{
      position: 'absolute',
      left: 'calc(42vw + 40px)',
      top: 'calc(45vh + 40px)'
    }}>
      <Typography
        style={{
          display: 'inline-block',
        }}
        variant="body1"
      >
        Loading NexuSustain
      </Typography>
      <Loader
        type="ball-beat"
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          transform: 'scale(0.5, 0.5)',
        }}
      />
    </div>
  );
};
const rootEl = document.getElementById('root');
const store = configureStore();


/*window.addEventListener('unload', (event) => {
  event.preventDefault();
  const { t } = useTranslation();
  event.returnValue = t('Are you sure you want to close NexuSustain Application?');
  /!*updateServiceWorker()*!/

});*/

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={appLoader}>
        <Component />
      </Suspense>
    </Provider>,
    rootEl,
  );
};

render(App);
  if (module.hot) {
  module.hot.accept('./apps/App', () => {
    render(App);
  });
}

/*register();*/
