import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
 Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { DialogProvider } from 'muibox';
import ReactNotification from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import PrivateRoute from '../containers/PrivateRoute';
import LandingPage from '../containers/LandingPage';
import Main from '../containers/Main';
//import Dashboard from '../containers/Dash';
import history from '../utils/history';
import '../assets/styles/dashboard/dashboard.css';
import '../assets/styles/notifications.css';
import {grayColor} from '../assets/jss/colorSystem';

const styles = (theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: grayColor[13],
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: grayColor[7],
    },
    scrollbarColor: grayColor[13],
    scrollbarWidth: 'thin',
  },
});

const App = ({ classes }) => (
  <Router history={history}>
    <div className={classes.root}>
      <ReactNotification id="nexusustain-notifications-container" isMobile={true} />
      <DialogProvider>
        <Switch>
        
        
          <Route exact path="/access" component={LandingPage} />     
          <PrivateRoute path="/" component={Main} />
          {/* <PrivateRoute path="/app/dashboard" component={Dashboard} /> */}
          
        </Switch>
      </DialogProvider>
    </div>
  </Router>
);

App.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(withTranslation()(App));
