import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Apps from '@material-ui/icons/Apps'
import List from '@material-ui/icons/List'
import { IconButton } from '@material-ui/core'
import ProjectModal from './ProjectModal';
import CardView from './CardView';
import TableView from './TableView';


const styles = (theme) => ({
  headerBtns:{
    textAlign: 'center'
  },
  addBtn:{
    float: 'right',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  card: {
    maxWidth: '90%',
    minWidth: '60%',
    width:'70%',
    margin: 'auto',
  },
  title: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
    color: theme.palette.protectedTitle,
    textAlign: 'center',
    fontSize: '1.2em'
  },
  table: {
    minWidth: 340,
    margin:20,
  },
  tableHeader: {
    paddingRight: 4,
    paddingLeft: 5,
    fontWeight: 'bold'
  },
  avatar: {
    width: 60,
    height: 60,
    // marginLeft:'35%',
    marginBottom: '10%'
  },
  cardContent: {
    textAlign: 'left',
    minHeight: 150,
  },
  dataTitle: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    display: 'inline'
  },
  subTitle: {
    fontSize: '0.7em',
    display: 'inline',
    wordBreak: 'break-word'
  },
  btn: {
    borderRadius: 20,
    border: '1px solid #333333' 
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40,
    paddingRight: 4,
    paddingLeft: 5,
  },
  input: {
    width: 130,
    height: 40
  },

  
})


class ProjectsWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCardView: true,
      isTableView: false,
      isProjectModalOpen: false,
      isEditMode: false,
      selectedProject:{},          
    }
    this.handleClickTableView = this.handleClickTableView.bind(this)
    this.handleClickCardView = this.handleClickCardView.bind(this)
    this.handleClickCreate = this.handleClickCreate.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }
  

  handleClickTableView() {
    this.setState({
      isTableView: true,
      isCardView: false
    })
  }

  handleClickCardView() {
    this.setState({
      isTableView: false,
      isCardView: true
    })
  }

  handleClickCreate(){
    this.setState({
      isProjectModalOpen: true,
      isEditMode: false,
      selectedProject:{}
    })
  }


  handleCloseDialog(){
    this.setState({
      isEditMode: false,
      isProjectModalOpen: false,
    })
  }


  render() {
    const { classes,
      createProject,
      deleteProject,
      projects,
      editProject,
      setMapWigetView
     } = this.props
    const { 
      isCardView, 
      isTableView,
      isProjectModalOpen, 
      isEditMode,
     } = this.state 
    

  
    return (
      <div>
        <ProjectModal 
                isProjectModalOpen={isProjectModalOpen} 
                onClose={this.handleCloseDialog}
                isEditMode={isEditMode}
                createProject={createProject}
            />
       
        <div className={classes.headerBtns}>
          <IconButton onClick={this.handleClickCardView}>
            <Apps />
          </IconButton>
          <IconButton onClick={this.handleClickTableView}>
            <List />
          </IconButton>
          <Button 
            variant='outlined' 
            onClick={this.handleClickCreate} 
            className={classes.addBtn}
          >
            Add
          </Button>
        </div>

          {isCardView && 
            <CardView 
              isProjectModalOpen={isProjectModalOpen}
              setProjectModalOpen={(isOpen) => this.setState({isProjectModalOpen: isOpen})}
              setMapWigetView={setMapWigetView}
              setIsCardView={(isCard) => this.setState({isCardView: isCard})}
              projects={projects} 
              createProject={createProject} 
              deleteProject={deleteProject}
              editProject={editProject} 

              />}
            {isTableView && <TableView 
              projects={projects}
              deleteProject={deleteProject}
              editProject={editProject}  
                
              />
          }
      </div>
    )
  }
}
ProjectsWidget.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setMapWigetView: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    geo: PropTypes.string,
    nexus: PropTypes.string,
    tags: PropTypes.string,
    avatar: PropTypes.string,
  })).isRequired,
  createProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
}
export default withStyles(styles)(ProjectsWidget)
