

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
const validDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'mail.com', 'apple.com', 'icloud.com', 'imail.com', 'email.com']
export default function validateEmailAddress(emailAddress) {
  return new Promise((resolve, reject) => {
    const splitEmail = emailAddress.split('@');
    const domain = splitEmail[1];
    if (!validateEmail(emailAddress) && validDomains.indexOf(domain.toLowerCase())>-1) {
      resolve({ isValid: false });
    } else {
      resolve({ isValid: true })
    }
  });
}
