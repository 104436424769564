let mapExcelConstants = {
    pmDefault: {
        D1: 'background-color: black;color: white;',
        D2: 'background-color: gray;',
        A1: 'background-color: white;',
        'A5:C10': 'background-color: gray;color: black;',
        A5: 'background-color: gray;color: black;',
        B5: 'background-color: gray;color: black;',
        C5: 'background-color: gray;color: black;',
        A6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        B6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C7: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C8: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C9: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C10: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        D3: 'background-color: blue',
        G3: 'background-color: red',
        M3: 'background-color: yellow',
        R3: 'background-color: green',
        T3: 'background-color: purple',
        D4: 'background-color: blue',
        E4: 'background-color: blue',
        F4: 'background-color: blue',
        G4: 'background-color: red',
        H4: 'background-color: red',
        I4: 'background-color: red',
        J4: 'background-color: red',
        K4: 'background-color: red',
        L4: 'background-color: red',
        M4: 'background-color: yellow',
        N4: 'background-color: yellow',
        O4: 'background-color: yellow',
        P4: 'background-color: yellow',
        Q4: 'background-color: yellow',
        R4: 'background-color: green',
        S4: 'background-color: green',
        T4: 'background-color: purple',
        U4: 'background-color: purple',
        D5: 'background-color: blue',
        E5: 'background-color: blue',
        F5: 'background-color: blue',
        G5: 'background-color: red',
        H5: 'background-color: red',
        I5: 'background-color: red',
        J5: 'background-color: red',
        K5: 'background-color: red',
        L5: 'background-color: red',
        M5: 'background-color: yellow',
        N5: 'background-color: yellow',
        O5: 'background-color: yellow',
        P5: 'background-color: yellow',
        Q5: 'background-color: yellow',
        R5: 'background-color: green',
        S5: 'background-color: green',
        T5: 'background-color: purple',
        U5: 'background-color: purple',
      },
      epmDefault: {
        A1: 'background-color: black;color: black;',
  
        D1: 'background-color: black;color: white;',
        A5: 'background-color: gray;color: black;',
        B5: 'background-color: gray;color: black;',
        C5: 'background-color: gray;color: black;',
        D2: 'background-color: gray;color: black;',
        O2: 'background-color: gray;color: black;',
        A6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        B6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C7: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C8: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C9: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C10: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        'A5:C10': 'background-color: gray;color: black;',
        D3: 'background-color: lightblue;color: black;',
        D4: 'background-color: lightblue;color: black;',
        D5: 'background-color: lightblue;color: black;',
        E4: 'background-color: lightblue;color: black;',
        E5: 'background-color: lightblue;color: black;',
        F4: 'background-color: lightblue;color: black;',
        F5: 'background-color: lightblue;color: black;',
        G3: 'background-color: red;color: black;',
        G4: 'background-color: red;color: black;',
        H4: 'background-color: red;color: black;',
        H5: 'background-color: red;color: black;',
        I4: 'background-color: red;color: black;',
        J4: 'background-color: red;color: black;',
        K4: 'background-color: red;color: black;',
        L4: 'background-color: red;color: black;',
        G5: 'background-color: red;color: black;',
        I5: 'background-color: red;color: black;',
        J5: 'background-color: red;color: black;',
        K5: 'background-color: red;color: black;',
        L5: 'background-color: red;color: black;',
        M3: 'background-color: red;color: black;',
        M4: 'background-color: red;color: black;',
        M5: 'background-color: red;color: black;',
        N3: 'background-color: green;color: black;',
        N4: 'background-color: green;color: black;',
        N5: 'background-color: green;color: black;',
        O3: 'background-color: orange;color: black;',
        Q3: 'background-color: orange;color: black;',
        S3: 'background-color: orange;color: black;',
        O4: 'background-color: orange;color: black;',
        O5: 'background-color: orange;color: black;',
        P4: 'background-color: orange;color: black;',
        P5: 'background-color: orange;color: black;',
        Q4: 'background-color: orange;color: black;',
        Q5: 'background-color: orange;color: black;',
        R4: 'background-color: orange;color: black;',
        R5: 'background-color: orange;color: black;',
        S4: 'background-color: orange;color: black;',
        S5: 'background-color: orange;color: black;',
  
  
      },
      mmmDefault: {
        D1: 'background-color: black;color: black;',
        A1: 'background-color: gray;color: black;',
        A6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        B6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C6: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C7: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C8: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C9: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        C10: 'background-color: gray;writing-mode:vertical-rl;text-orientation: mixed;',
        D2: 'background-color: gray;color: black;',
        A5: 'background-color: gray;color: black;',
        B5: 'background-color: gray;color: black;',
        C5: 'background-color: gray;color: black;',
        'A5:C10': 'background-color: gray;color: black;',
        D3: 'background-color: lightblue;color: black;',
        D4: 'background-color: lightblue;color: black;',
        D5: 'background-color: lightblue;color: black;',
        E4: 'background-color: lightblue;color: black;',
        E5: 'background-color: lightblue;color: black;',
        F4: 'background-color: lightblue;color: black;',
        F5: 'background-color: lightblue;color: black;',
        G3: 'background-color: red;color: black;',
        G4: 'background-color: red;color: black;',
        H4: 'background-color: red;color: black;',
        I4: 'background-color: red;color: black;',
        J4: 'background-color: red;color: black;',
        K4: 'background-color: red;color: black;',
        L4: 'background-color: red;color: black;',
        G5: 'background-color: red;color: black;',
        H5: 'background-color: red;color: black;',
        I5: 'background-color: red;color: black;',
        J5: 'background-color: red;color: black;',
        K5: 'background-color: red;color: black;',
        L5: 'background-color: red;color: black;',
        M3: 'background-color: yellow;color: black;',
        M4: 'background-color: yellow;color: black;',
        M5: 'background-color: yellow;color: black;',
        N4: 'background-color: yellow;color: black;',
        N5: 'background-color: yellow;color: black;',
        O4: 'background-color: yellow;color: black;',
        O5: 'background-color: yellow;color: black;',
        P4: 'background-color: yellow;color: black;',
        P5: 'background-color: yellow;color: black;',
        Q4: 'background-color: yellow;color: black;',
        Q5: 'background-color: yellow;color: black;',
        R3: 'background-color: green;color: black;',
        R4: 'background-color: green;color: black;',
        R5: 'background-color: green;color: black;',
        S4: 'background-color: green;color: black;',
        S5: 'background-color: green;color: black;',
        T3: 'background-color: purple;color: black;',
        T4: 'background-color: purple;color: black;',
        T5: 'background-color: purple;color: black;',
        U4: 'background-color: purple;color: black;',
        U5: 'background-color: purple;color: black;',
        V3: 'background-color: navy;color: white;',
        V5: 'background-color: darkblue;color: white;',
        W3: 'background-color: brown;color: black;',
        W5: 'background-color: brown;color: black;',
        X3: 'background-color: darkred;color: black;',
        X4: 'background-color: darkred;color: black;',
        X5: 'background-color: darkred;color: black;',
        Y4: 'background-color: darkred;color: black;',
        Y5: 'background-color: darkred;color: black;',
        Z4: 'background-color: darkred;color: black;',
        Z5: 'background-color: darkred;color: black;',
      }
}
export default mapExcelConstants
