export const EDIT_USER_REQUEST = Symbol('users/EDIT_USER_REQUEST');
export const EDIT_USER_SUCCESS = Symbol('users/EDIT_USER_SUCCESS');
export const EDIT_USER_FAILURE = Symbol('users/EDIT_USER_FAILURE');
export const FETCH_USER_REQUEST = Symbol('users/FETCH_USER_REQUEST');
export const FETCH_USER_SUCCESS = Symbol('users/FETCH_USER_SUCCESS');
export const FETCH_USER_FAILURE = Symbol('users/FETCH_USER_FAILURE');

export const SEARCH_USERS_REQUEST = Symbol('users/SEARCH_USERS_REQUEST');
export const SEARCH_USERS_SUCCESS = Symbol('users/SEARCH_USERS_SUCCESS');
export const SEARCH_USERS_FAILURE = Symbol('users/SEARCH_USERS_FAILURE');





