import Dashboard from '@material-ui/icons/DashboardOutlined';
import Forum from '@material-ui/icons/ForumOutlined';
import Dash from '../containers/Dash';

import {People} from '@material-ui/icons';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard,
    component: Dash,
    layout: '/app',
    isMenu: true,
  },
];

export default routes;
