import _ from 'lodash';
import * as moment from 'moment';
/* eslint no-underscore-dangle: 0 */
import conf from '../config';
import io from 'socket.io-client';
import { store } from 'react-notifications-component';
import ReactDOM from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import * as types from '../constants/sockets';
import { redirect } from './services';


import { unregister } from '../registerServiceWorker';



const configuration = conf;
export function missingSocketConnection(t) {
  store.addNotification({
    title: t('ERROR!'),
    message: t('Missing signaling connection!'),
    type: 'danger',
    insert: 'bottom',
          container: 'bottom-left',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 6000,
      onScreen: false,
      pauseOnHover: true,
      showIcon: true,
waitForAnimation: false,
    },
  });
  return {
    type: types.SOCKETS_CONNECTION_MISSING,
    payload: new Error('Missing connection!'),
  };
}

var socket = null;

export function socketsConnect(t) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp, user } = state.auth;
    const activeUser = state.auth.user;
  
  
    if (isFetching.sockets || !user) {
      return Promise.resolve();
    }

    dispatch({
      type: types.SOCKETS_CONNECTION_REQUEST,
    });

    const socketUserName = user.displayName;
    const socketUserId = user._id;

    socket = io(conf.SOCKETS_URI, {
      autoConnect: true,
      forceNew: false,
      transports: ['websocket'],
      reconnection: true,
      rejectUnauthorized: true,
      reconnectionDelay: 100,
      reconnectionAttempts: 100,
      query: {
 token, otp, socketUserName, socketUserId,
},
    });

    socket.pingInterval = 60000;
      socket.pingTimeout = 120000;
      socket.heartbeatTimeout = 60000;
      socket.heartbeatInterval = 120000;
    socket.on('reconnect_attempt', () => {
      socket.io.opts.transports = ['websocket', 'polling'];
      if (configuration.DEV_LOGGING)console.log('Socket reconnection attempt....');
    });
    socket.on('connect', () => {
      store.addNotification({
        title: t('SUCCESS!'),
        message: t('Signaling service connection is OK!'),
        type: 'success',
        insert: 'bottom',
        container: 'bottom-left',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
  /*      onRemoval: (id, removedBy) => {
          const e = document.getElementById('notification-container-bottom-left');

          //e.firstElementChild can be used.
          if (e && e !== null) {
            let child = e.lastElementChild;
            while (child) {
              e.removeChild(child);
              child = e.lastElementChild;
            }
          }
        },*/
        dismiss: {
          duration: 6000,
          onScreen: false,
          pauseOnHover: true,
          showIcon: true,
          waitForAnimation: false,
        },
      });
      dispatch({
        type: types.SOCKETS_CONNECTION_SUCCESS,
      });
    });
    socket.on('disconnect', (reason) => {
      if (reason) {
        store.addNotification({
          title: t('ERROR!'),
          // eslint-disable-next-line
          message: t('Transport disconnect! Server says: ')+` ${t(reason.message)}`,
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 6000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
            waitForAnimation: false,
          },
        });
        if (configuration.DEV_LOGGING)console.log('Sockets disconnect! reason: : ', reason);
        dispatch({
          type: types.SOCKETS_DISCONNECT_REASON,
          payload: { reason },
        });
      }
    });
    socket.on('error', (error) => {
      if (error) {
        store.addNotification({
          title: t('ERROR!'),
          // eslint-disable-next-line
          message: t('Transport error! Server says: ')+`${error}`,
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 6000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
            waitForAnimation: false,
          },
        });
        if (configuration.DEV_LOGGING)console.log('Sockets error: ', error);
        dispatch({
          type: types.SOCKETS_CONNECTION_FAILURE,
          payload: new Error(`${error}`),
        });
      }
    });
    socket.on('connect_error', (error) => {
      if (configuration.DEV_LOGGING)console.log('Sockets connection error: ', error);
      store.addNotification({
        title: t('ERROR!'),
        message: t('Server connection is lost'),
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-left',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 6000,
          onScreen: false,
          pauseOnHover: true,
          showIcon: true,
          waitForAnimation: false,
        },
      });
      dispatch({
        type: types.SOCKETS_CONNECTION_FAILURE,
        payload: new Error('Server connection is lost'),
      });
    });
    socket.on('signal-init', () => {
      //document.title = `${clientId} - VideoCall`;
      dispatch({
        type: types.PEER_INIT_SUCCESS,
      });
    });
    //{
    //  activeChannelId, userChannelId, userId, userDisplayName,
    // }
    socket.on('emit-to-active-channel', (data) => {
      // eslint-disable-next-line
      let emitargs = data.emitargs;
      const dispachMessage = emitargs.dispatch;
      if (dispachMessage) {
        delete emitargs.dispatch;
        dispatch({
          type: types[dispachMessage],
          payload: emitargs,
        });
      }
    });
   
  
 
 
 

 

    socket.on('user-disconnect', ({
 userChannelId, userId, userDisplayName,
}) => {
          if (!!userId && userId !== null ) {
            if (userId !== user._id) {
              dispatch({
                type: types.RECIEVE_USER_DISCONNECT,
                payload: {
                  userId, userDisplayName,
                },
              });
              store.addNotification({
                title: t('INFO!'),
                // eslint-disable-next-line
                message: `${userDisplayName}`+', '+ t('is offline now'),
                type: 'info',
                insert: 'bottom',
                container: 'bottom-left',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: {
                  duration: 4000,
                  onScreen: false,
                  pauseOnHover: true,
                  showIcon: true,
                  waitForAnimation: false,
                },
              });
            }
          }
        });
    socket.on('user-connect', ({
 userId, userDisplayName,
}) => {
         
          if (!!userId && userId !== null) {
            if (userId !== user._id) {
              dispatch({
                type: types.RECIEVE_USER_CONNECT,
                payload: {
                 userId, userDisplayName,
                },
              });
              store.addNotification({
                title: t('INFO!'),
                // eslint-disable-next-line
                message: `${userDisplayName}`+' '+t('is online now'),
                type: 'info',
                insert: 'bottom',
                container: 'bottom-left',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: {
                  duration: 4000,
                  onScreen: false,
                  pauseOnHover: true,
                  showIcon: true,
                  waitForAnimation: false,
                },
              });
            }
          }
        });
    socket.emit('signal-init');
    return Promise.resolve();
  };
}




export function handleBeforeUnload(t) {
  if (window.confirm(t('Are you sure you want to close NexuSustain Application'))) {
    if (socket)socket.close();
    return true;
  }
  return false;
}
export function handleBeforeUnloadMain(t) {
  if (window.confirm(t('Are you sure you want to close NexuSustain Application'))) {
    if (socket)socket.close();
    return true;
  }
  return false;
}

