import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from "@material-ui/icons/EditOutlined";
import Avatar from '@material-ui/core/Avatar'
import { IconButton } from '@material-ui/core'
import nextId from 'react-id-generator'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProjectModal from './ProjectModal';


const styles = (theme) => ({
  headerBtns:{
    textAlign: 'center'
  },
  addBtn:{
    float: 'right',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  card: {
    maxWidth: '97%',
    minWidth: '70%',
    width:'80%',
    margin: 'auto',
    backgroundImage: 'linear-gradient(225deg,#54fde7,#bcf748)',
  },
  title: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
    color: theme.palette.protectedTitle,
    textAlign: 'center',
    fontSize: '1.2em'
  },
  table: {
    minWidth: 340,
    margin:20,
  },
  tableHeader: {
    paddingRight: 4,
    paddingLeft: 5,
    fontWeight: 'bold'
  },
  avatar: {
    width: 60,
    height: 60,
    // marginLeft:'35%',
    marginBottom: '10%'
  },
  cardContent: {
    textAlign: 'left',
    minHeight: 150,
  },
  dataTitle: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    display: 'inline'
  },
  subTitle: {
    fontSize: '0.7em',
    display: 'inline',
    wordBreak: 'break-word'
  },
  btn: {
    borderRadius: 20,
    border: '1px solid #333333' 
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40,
    paddingRight: 4,
    paddingLeft: 5,
  },
  input: {
    width: 130,
    height: 40
  },

  
})


class CardView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteModalOpen: false,
      isEditMode: false,
      previous:{},
      selectedProject:{},
      selectedProjectId:''
    }
    this.handleClickDelete = this.handleClickDelete.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleDeleteProject = this.handleDeleteProject.bind(this)
    this.handleClickEditCard = this.handleClickEditCard.bind(this)
    this.handleClickOpenCard = this.handleClickOpenCard.bind(this)

  }



  handleClickEditCard(project){
    const { setProjectModalOpen }= this.props
    this.setState({
      isEditMode: true,
      selectedProject: project
    })
    setProjectModalOpen(true)
  }

  handleClickDelete(id){
    this.setState({
      isDeleteModalOpen: true,
      selectedProjectId: id
    })
  }

  handleDeleteProject(){
    const{deleteProject}=this.props
    const{selectedProjectId}=this.state
    this.setState({
      isDeleteModalOpen: false,
    })
    deleteProject(selectedProjectId)
  }

  handleClickOpenCard(id){
    const {setMapWigetView, setIsCardView, projects}= this.props
    let selectedProject = projects.find((project)=>{
      if(project._id === id){
        return project
      }
    })
    setMapWigetView(true, selectedProject);
    setIsCardView(false)
  }

  handleCloseDialog(){
    const{setProjectModalOpen}=this.props
    this.setState({
      isDeleteModalOpen: false,
      isEditMode: false,
      selectedProject:{}
    })
    setProjectModalOpen(false)

  }

 

  render() {
    const { classes,createProject,deleteProject,projects,editProject,isProjectModalOpen } = this.props
    const { 
      isEditMode,
      isDeleteModalOpen,
      selectedProject,
     } = this.state 
 
    return (
        <>
            <Dialog open={isDeleteModalOpen} onClose={this.handleCloseDialog}>
                <DialogTitle id="delete-dialog">Delete Item</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Are you sure?
                    </DialogContentText>         
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialog}>
                    Cancel
                    </Button>
                    <Button onClick={this.handleDeleteProject}>
                    Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <ProjectModal 
                isProjectModalOpen={isProjectModalOpen} 
                onClose={this.handleCloseDialog}
                isEditMode={isEditMode}
                setEditMode={(isEdit) => this.setState({isEditMode: isEdit})}
                editProject={editProject}
                selectedProject= {selectedProject}
                createProject={createProject}
            />
            
            
            <Grid container spacing={1}>
                {projects && projects.map((project) => (
                <Grid key={nextId()} item xs={12} sm={6} md={3} style={{display: 'flex'}}>
                    <Card className={classes.card}>
                        <CardHeader 
                        avatar={
                            <Avatar className={classes.avatar} src={project.avatar} />
                        }
                        />
                            
                        <CardContent className={classes.cardContent}>
                        <Typography className={classes.dataTitle}>ID:</Typography>
                        <Typography className={classes.subTitle}>
                            {' '}
                            {project._id}
                        </Typography>
                        <br />
                        <Typography className={classes.dataTitle}>Title:</Typography>
                        <Typography className={classes.subTitle}>
                            {' '}
                            {project.title}
                        </Typography>
                        <br />
                        <Typography className={classes.dataTitle}>
                            Description:
                        </Typography>
                        <Typography className={classes.subTitle}>
                            {' '}
                            {project.description}
                        </Typography>
                        <br />
                        <Typography className={classes.dataTitle}>Tags:</Typography>
                        <Typography className={classes.subTitle}>
                            {' '}
                            {project.tags !== '' ? JSON.parse(project.tags).join(", ") : ''}
                        </Typography>
                        <br />
                        <Typography className={classes.dataTitle}>Geo:</Typography>
                        <Typography className={classes.subTitle}>
                            {' '}
                            {project.geo.substring(0,100)+'...'}
                        </Typography>
                        <br />
                        <Typography className={classes.dataTitle}>Nexus:</Typography>
                        <Typography className={classes.subTitle}>
                            {' '}
                            {project.nexus}
                        </Typography>
                        
                    </CardContent>
                        <CardActions>                   
                        <IconButton 
                            onClick={() => this.handleClickEditCard(project)}
                            aria-label="Edit" 
                            >
                            <EditIcon />
                        </IconButton>
                        <IconButton 
                            onClick={() => this.handleClickDelete(project._id)}
                            aria-label="delete" 
                        >
                            <DeleteIcon />
                        </IconButton>
                        <Button
                            className={classes.btn}
                            onClick={() => this.handleClickOpenCard(project._id)}
                        >
                            Open
                        </Button>
                        </CardActions>
                    </Card>
                </Grid>
                ))}
            </Grid>
      </>
    
        )
  }
}
CardView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setMapWigetView: PropTypes.func.isRequired,
  isProjectModalOpen: PropTypes.bool.isRequired,
  setProjectModalOpen: PropTypes.func.isRequired,
  setIsCardView: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    geo: PropTypes.string,
    nexus: PropTypes.string,
    tags: PropTypes.string,
    avatar: PropTypes.string,
  })).isRequired,
  createProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
}
export default withStyles(styles)(CardView)
