export const SOCKETS_CONNECTION_REQUEST = Symbol('sockets/SOCKETS_CONNECTION_REQUEST');
export const SOCKETS_CONNECTION_SUCCESS = Symbol('sockets/SOCKETS_CONNECTION_SUCCESS');
export const SOCKETS_CONNECTION_FAILURE = Symbol('sockets/SOCKETS_CONNECTION_FAILURE');
export const SOCKETS_DISCONNECT_REASON = Symbol('sockets/SOCKETS_DISCONNECT_REASON');
export const SOCKETS_CONNECTION_MISSING = Symbol('sockets/SOCKETS_CONNECTION_MISSING');
export const RECIEVE_USER_CONNECT = Symbol('sockets/RECIEVE_USER_CONNECT');
export const RECIEVE_USER_DISCONNECT = Symbol('sockets/RECIEVE_USER_DISCONNECT');
export const PEER_INIT_SUCCESS = Symbol('sockets/PEER_INIT_SUCCESS');

export const MOUNT_CHANNEL = Symbol('sockets/MOUNT_CHANNEL');
export const UNMOUNT_CHANNEL = Symbol('sockets/UNMOUNT_CHANNEL');











