import React from 'react';
import classNames from 'classnames';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import Notifications from '@material-ui/icons/NotificationsNoneOutlined';
import Close from '@material-ui/icons/Close';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { withTranslation } from 'react-i18next';
import {
  Brightness4,
  Brightness5, Chat, Delete, ForumOutlined,
  MailOutline,
  MailOutlineRounded,
  Slideshow,
} from '@material-ui/icons';
import { defaultPallet } from '../../assets/jss/colorSystem';
import config from '../../config';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import styles from '../../assets/jss/components/navbarLinksStyle';
import Box from '@material-ui/core/Box';



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// const useStyles = makeStyles(styles);


class NavbarLinks extends React.Component {
  constructor(props){
    super(props);
    this.state={
      isDialogOpen: false,
      selectedBtn:'',
      dialogContent: 'DIALOG CONTENT',
      name:'',
      email:'',
      subject:'',
      message:''

    }
    this.handleOpenDialog= this.handleOpenDialog.bind(this)
    this.onCloseDialog= this.onCloseDialog.bind(this)
    this.handleNameChange= this.handleNameChange.bind(this)
    this.handleEmailChange= this.handleEmailChange.bind(this)
    this.handleSubjectChange= this.handleSubjectChange.bind(this)
    this.handleMessageChange= this.handleMessageChange.bind(this)
    this.handleSendMessageClick= this.handleSendMessageClick.bind(this)
  }

  handleOpenDialog =(btn) =>{
 
    if(btn=== 'ABOUT'){
      this.setState({dialogContent: config.ABOUT_US})
    }else if(btn=== 'BACKGROUND'){
      this.setState({dialogContent: config.BACKGROUND})
    }else if(btn=== 'CITATION'){
      this.setState({dialogContent: config.CITE_US})
    }else if(btn=== 'TUTORIAL'){
      this.setState({dialogContent: config.TUTORIAL})
    }else if(btn=== 'CONTACT'){
      this.setState({dialogContent:
       <>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
          <TextField
              required
              variant="filled" 
              value={this.state.name}
              label="Your name"
              type="text"
              margin="normal"
              onChange={this.handleNameChange}
              autoFocus
            />
            <TextField
              required
              variant="filled" 
              value={this.state.email}
              label="Your Email"
              type="text"
              margin="normal"
              onChange={this.handleEmailChange}
              
            />
            <TextField
              required
              variant="filled" 
              value={this.state.subject}
              label="Subject"
              type="text"
              margin="normal"
              onChange={this.handleSubjectChange}              
            />
            <TextField
              required
              variant="filled" 
              value={this.state.message}
              label="Message"
              type="text"
              margin="normal"
              onChange={this.handleMessageChange}
              multiline
            />
            <Button 
              className={this.props.classes.sendBtn}
              onClick={this.handleSendMessageClick}
            >
              Send message
              </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='h6'>Contact us</Typography>
            <Typography variant='body1'>
              Do not hesitate to contact us if you need any help regarding the tool. Your feedbacks are most welcome.
            </Typography>
            <br />
            <Typography variant='h4'>How to find us</Typography>
            <Typography variant='body1'>
              Should you have any question about the tool, wish to know the creators, or wish to participate in the future expantion of the website,please get in touch with us:
            </Typography>
          </Grid>
        </Grid>
      </>})
    }
    this.setState({isDialogOpen: !this.state.isDialogOpen})
  }

  onCloseDialog =() => {
    this.setState({isDialogOpen: false})
  }

  handleNameChange = (event)=> {
    this.setState({
      name: event.target.value
    });
  }
  handleEmailChange = (event)=> {
    this.setState({
      email: event.target.value
    });
  }
  handleSubjectChange = (event)=> {
    this.setState({
      subject: event.target.value
    });
  }
  handleMessageChange = (event)=> {
    this.setState({
      message: event.target.value
    });
  }
  handleSendMessageClick = (event)=> {
  }
  render(){
    const {classes} = this.props
    const {isDialogOpen,selectedBtn, dialogContent} = this.state
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="sm"
          scroll="paper"
          onClose={this.onCloseDialog}
          open={isDialogOpen}
          classes={{ root: classes.dialogPaper}}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={this.onCloseDialog}
          >
            <Close />
          </IconButton>
          <DialogTitle>
            {selectedBtn}
          </DialogTitle>       
          <DialogContent 
          className={classes.dialogContent} 
          classes={{root: classes.dialogContentRoot}}
          >  
          {selectedBtn !== 'CONTACT' && dialogContent.length ? dialogContent.split('\n').map(str => <p>{str}</p>): dialogContent}
          </DialogContent>
        </Dialog>
      <div style={{ display: 'inlineBlock', marginRight: '20px' }}>
        <div className={classes.manager}>
          <ButtonGroup variant="text" classes={{root: classes.buttonGroupRoot}}>
            <Button style={{ display: 'inlineBlock', width: '100px', fontSize: 13 }} onClick={()=>{this.handleOpenDialog('BACKGROUND')}}>BACKGROUND</Button>
            <Button style={{ display: 'inlineBlock', width: '160px', fontSize: 13 }} onClick={()=>{this.handleOpenDialog('ABOUT')}}>ABOUT NEXUSUSTAIN</Button>
            <Button style={{ display: 'inlineBlock', width: '90px', fontSize: 13 }} onClick={()=>{this.handleOpenDialog('CONTACT')}}>CONTACT</Button>
            <Button style={{ display: 'inlineBlock', width: '140px', fontSize: 13 }} onClick={()=>{this.handleOpenDialog('CITATION')}}>HOW TO CITE US</Button>
            <Button style={{ display: 'inlineBlock', width: '110px', fontSize: 13 }} onClick={()=>{this.handleOpenDialog('TUTORIAL')}}>TUTORIALS</Button>
          </ButtonGroup>
          
        </div>
      </div>
      </>
    );
  }
}
NavbarLinks.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,  
  error: PropTypes.instanceOf(Error),
  activeUser: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
    isMember: PropTypes.bool,
    language: PropTypes.string,
    isCreator: PropTypes.bool,
    isManager: PropTypes.bool,
    isChannelMember: PropTypes.bool,
    token: PropTypes.string,
    channel: PropTypes.object,
    otp: PropTypes.string,
  
    notes: PropTypes.array,
    events: PropTypes.array,
    metrics: PropTypes.array,
  }).isRequired,


};
export default withStyles(styles)(withTranslation()(NavbarLinks));
