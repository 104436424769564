import {
  defaultPallet,
  blueColor, defaultFont, whiteColor, grayColor,
} from '../colorSystem';

const loginFormStyle = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  signInButton: {
    marginTop: theme.spacing(2),
    backgroundColor: defaultPallet[29],
    color: whiteColor,
    borderRadius: 16,
    width: '97%',
    height: 40,
    marginBottom: '7px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: defaultPallet[0],
    },
  },
  signUpButton: {
    marginTop: theme.spacing(2),
    backgroundColor: defaultPallet[1],
    color: whiteColor,
    borderRadius: 16,
    width: '97%',
    height: 40,
    marginBottom: '7px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: defaultPallet[0],
    },
  },

 

  
  icon: {
    marginTop: '5%',
    color: grayColor[10],
  },
  visibilityIcon: {
    color: grayColor[10],
  },
  wrapper: {
    textAlign:'center',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(2),
    display: 'inline-block',
  },
  fullWidth: {
    width: '90%',
  },
  input: {
    [theme.breakpoints.down('xl')]: {
      width: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '90%',
    },
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '83%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  underline: {
    borderBottomColor: defaultPallet[1],
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  border: {
    borderBottom: '2px solid #dddddd',
    width: '100%',
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: 10,
    color: grayColor[6],
  },
  right: {
    float: 'right',
  },
  link: {
    color: grayColor[12],
    float: 'right',
    '&:hover': {
      color: defaultPallet[0],
    },
  },
});

export default loginFormStyle;
