import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { 
  fetchAllProjects,
  createProject,
  deleteProject,
  editProject
   } from '../actions/services';
import {  editUser} from '../actions/users';
import {
  socketsConnect,
  missingSocketConnection,
} from '../actions/sockets';
import * as fromState from '../reducers';
import Dash from '../components/dashboard/Dash';



const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isFetching: state.services.isFetching,
    activeUser: {
      ...state.auth.user,
      token: state.auth.token,
      otp: state.auth.otp,
    },
    isConnected: state.services.isConnected,
    isDark: state.services.isDarkMode,
    projects: state.services.projects,
    classes: {},
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchAllProjects,
    createProject,
    deleteProject,
    editProject,
    socketsConnect, 
    missingSocketConnection,
  },
  dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(Dash);
