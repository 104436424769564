import fetch from 'isomorphic-fetch';
import config from '../config';

export default function callApi(endpoint, token, otp, options, payload) {
  const authHeaders = token
    ? {
      Authorization: `Bearer ${token}`,
      otp: `Phrase ${otp}`,
    }
    : {};

  return fetch(`${config.API_PROXY}${endpoint}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Referrer-Policy': 'strict-origin-when-cross-origin',
      'Referer': 'https://www.wefneeds.com',
      ...authHeaders,
    },
    body: JSON.stringify(payload),
    ...options,
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.success) {
        return json;
      }

      throw new Error(json.message);
    });
}

