import {
  successColor,
  whiteColor,
  grayColor,
  warningColor,
  hexToRgb,
  defaultPallet, transition, defaultFont, blackColor,
} from '../colorSystem';

const dashboardStyle = (theme) => ({
  bgCardHeaderStyle: {
    marginTop: 10,
  },
  tasksCardHeader: {
    maxHeight: '80px',
    backgroundColor: defaultPallet[1],
    padding: '15px',
    paddingBottom: '2px',
    marginTop: '-20px',
    marginRight: '15px',
    borderRadius: '3px',
    marginLeft: '15px',
  },
  bgCardBody:{
    height: '40vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: defaultPallet[4],
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: defaultPallet[1],
    },
    scrollbarColor: defaultPallet[4],
    scrollbarWidth: 'thin',
  },
  grayTitle: {
    color: defaultPallet[13],
    fontSize: 23,
    marginTop: 20,
    marginLeft: 20,
    display: 'inline',
  },
  listRoot: {
    width: '100%',
  },
  listItem: {
    backgroundColor: grayColor[17],
    borderRadius: 15,
  },
  listItemPrimaryText: {
    color: defaultPallet[14],
    fontSize: 13,
  },
  listItemSecondaryText: {
    color: defaultPallet[16],
    fontSize: 17,
  },
  messagesListPrimaryText: {
    color: defaultPallet[13],
    fontSize: 13,
    fontWeight: 'bold',
  },
  messagesListSecondaryText: {
    color: defaultPallet[23],
    fontSize: 10,
  },
  upcomingListItemAvatar: {
    color: defaultPallet[16],
  },
  secondaryAction: {
    color: grayColor[18],
    fontSize: 10,
  },
  bgCardBtn: {
      backgroundColor: defaultPallet[20],
      borderRadius: 9,
      fontSize: '0.7em',
      color: defaultPallet[1],
      float: 'right',
      height: 23,
      boxShadow: 'none',
      textTransform: 'capitalize',
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  statsDark: {
    color: defaultPallet[13],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  cardStyle: {
    borderRadius: 20,
  },
  bgCard: {
    borderRadius: 20,
    height: 370,
  },
  cardIcon: {
    float: 'left',
    marginRight: '15px',
    borderRadius: '50px',
  },
  cardIconDark: {
    backgroundColor: defaultPallet[15],
    color: defaultPallet[9],
    float: 'left',
    marginRight: '15px',
    borderRadius: '50px',
  },
  channelAvatar: {
    // display: 'inline',
  },
  channelCardTitle: {
    color: blackColor,
    margin: '0',
    // fontSize: '15px',
    marginTop: '0',
    marginBottom: '0',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  channelCardTitleDark: {
    color: defaultPallet[0],
    margin: '0',
    fontSize: '15px',
    marginTop: '0',
    marginBottom: '0',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  cardCategoryWhite: {
    color: `rgba(${hexToRgb(whiteColor)},.62)`,
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardCategoryPurple: {
    color: defaultPallet[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  channelCardSubText: {
    color: grayColor[7],
    fontSize: '0.7em',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    '& small': {
      color: defaultPallet[6],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  channelCardSubTextDark: {
    color: defaultPallet[0],
    fontSize: '0.7em',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    '& small': {
      color: defaultPallet[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  cardTitlePurple: {
    color: defaultPallet[0],
    marginTop: '0px',
    display: 'inline',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  dashPanel: {
    // background: '#a8ff78',
    // background: '-webkit-linear-gradient(to right, #78ffd6, #a8ff78)',  
    // background: 'linear-gradient(to right, #78ffd6, #a8ff78)',
     //background: '#fff',
     backgroundImage: 'linear-gradient(to right, #d5f6ec, #dffece)',
     overflowY: 'auto',
     overflowX: 'hidden',
     height: '98vh',
    /*  paddingRight: '3px',
     paddingLeft: '3px', */
     paddingBottom: '75px',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: defaultPallet[4],
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: defaultPallet[1],
    },
    scrollbarColor: defaultPallet[4],
    scrollbarWidth: 'thin',
  },
  dashPanelDark: {
    paddingBottom: '75px',
    background: defaultPallet[9],
     overflowY: 'auto',
     overflowX: 'hidden',
     height: '98vh',
     paddingRight: '3px',
     paddingLeft: '15px',
     '&::-webkit-scrollbar': {
       width: '5px',
     },
     '&::-webkit-scrollbar-track': {
       boxShadow: 'inset 0 0 5px grey',
       borderRadius: '10px',
     },
     '&::-webkit-scrollbar-thumb': {
       background: grayColor[13],
       borderRadius: '10px',
     },
     '&::-webkit-scrollbar-thumb:hover': {
       background: grayColor[7],
     },
     scrollbarColor: grayColor[13],
     scrollbarWidth: 'thin',
  },
  dashPanelSided: {
    background: '#fafafa',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '90vh',
    paddingRight: '15px',
    paddingLeft: '15px',
    minWidth: '45%',
    width: 'calc(70vw - 70px)',
    float: 'right',
    display: 'inline-block',
    [theme.breakpoints.down('lg')]: {
      /*      minWidth: '30vw',*/
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      position: 'relative',
      height: '100vh',
    },
    [theme.breakpoints.up('md')]: {
      /*      minWidth: '30vw',*/
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      position: 'relative',
      height: '100vh',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      position: 'relative',
      height: '97vh',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      flexShrink: 0,
      height: '97vh',
      position: 'relative',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      flexShrink: 0,
      height: '100vh',
      position: 'relative',
    },
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: '50%',
    //   width: '50%',
    // },

    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: grayColor[13],
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: grayColor[7],
    },
    scrollbarColor: grayColor[13],
    scrollbarWidth: 'thin',
  },
  dashPanelSidedDark: {
    background: defaultPallet[9],
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '90vh',
    paddingRight: '15px',
    paddingLeft: '15px',
    minWidth: '45%',
    width: 'calc(70vw - 70px)',
    float: 'right',
    display: 'inline-block',
    [theme.breakpoints.down('lg')]: {
      /*      minWidth: '30vw',*/
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      position: 'relative',
      height: '100vh',
    },
    [theme.breakpoints.up('md')]: {
      /*      minWidth: '30vw',*/
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      position: 'relative',
      height: '100vh',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      position: 'relative',
      height: '97vh',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      flexShrink: 0,
      height: '97vh',
      position: 'relative',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '50%',
      width: 'calc(70vw - 70px)',
      flexShrink: 0,
      height: '100vh',
      position: 'relative',
    },
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: '50%',
    //   width: '50%',
    // },

    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: grayColor[13],
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: grayColor[7],
    },
    scrollbarColor: grayColor[13],
    scrollbarWidth: 'thin',
  },
  panelTitle: {
    color: whiteColor,
    fontSize: '1.4em',
    display: 'inline',
  },
  newBadge: {
    zIndex: '4',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 'auto',
      border: `1px solid ${whiteColor}`,
      right: '-10px',
      minWidth: '11px',
      height: '10px',
      borderRadius: '10px',
      textAlign: 'center',
      lineHeight: '10px',
      verticalAlign: 'middle',
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      ...defaultFont,
      fontSize: '14px',
      marginRight: '8px',
    },
  },
  btn: {
    backgroundColor: defaultPallet[1],
    borderRadius: 8,
    color: whiteColor,
    marginLeft: theme.spacing(2),
    height: 25,
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover':{
      backgroundColor: defaultPallet[2],
    }
  },
  updateIcon: {
    color: grayColor[7],
    marginTop: '3px',
    fontSize: 15,
  },
  channelCardSubTextDiv: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  moreIcon: {
    color: grayColor[8],
    fontSize: 15,
  },
  iconButton: {
    float: 'right',
    '&.MuiIconButton-root': {
      padding: 5,
    },
  },
  contactImage: {},
  statsNumStyle: {
    color: defaultPallet[1],
    fontWeight: 'bold',
  },
  statsTextStyle: {
    color: grayColor[11],
    fontSize: 16,
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  statsBlackText: {
    color: defaultPallet[12],
    fontSize: '1em',
    fontWeight: 600,
  },
  statsGreenText: {
    color: successColor[0],
    fontsize: '0.5em',
    display: 'inline',
    fontWeight: 'bold',
  },
  statsGreenNum: {
    color: successColor[0],
    fontWeight: 'bold',
    display: 'inline',
  },
  statsOrangeText: {
    color: warningColor[0],
    fontsize: '0.5em',
    display: 'inline',
    fontWeight: 'bold',
  },
  statsOrangeNum: {
    color: warningColor[0],
    fontWeight: 'bold',
    display: 'inline',
  },
  statsSubText: {
    color: grayColor[11],
    fontSize: '0.3em',
  },
  avatarGroup: {
    float: 'right',
  },
  channelMemberAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

export default dashboardStyle;
