import { withStyles, makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  mediumImg: {
    verticalAlign: 'middle',
    display: 'inline-block',
    width: theme.spacing(6),
    height: theme.spacing(6),
    margin: '2px',
    textAlign: 'center',
  },
  smallImg:{
    // width: theme.spacing(8),
    // height: theme.spacing(8),
  },
  lgImg:{
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginLeft: theme.spacing(1),
  },
});
const OfflineStyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: 'red',
    color: 'red',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid #3855aa',
      content: '""',
    },
  },
}))(Badge);
function OfflineStyledBadgeAvatar (props){
  const{classes, userAvatar, size, onClick}= props;
  return(
    <OfflineStyledBadge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
      aria-haspopup="true"
    >
      <Avatar
        alt="User image"
        src={userAvatar}
        onClick={onClick && onClick()}
        className={
          size === 'lg' ? classes.lgImg : size=="medium" ? classes.mediumImg : classes.smallImg}
      />
    </OfflineStyledBadge>
  )
}
OfflineStyledBadgeAvatar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  userAvatar: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
export default withStyles(styles)(OfflineStyledBadgeAvatar);
