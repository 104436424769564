import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor, defaultPallet,
} from '../colorSystem';

const navbarStyle = (theme) => ({
  appBar: {
    /* backgroundColor: defaultPallet[0], */
    backgroundImage: 'linear-gradient(270deg,#54fde7,#bcf748)',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'fixed',
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.5rem',
    },
  /*  [theme.breakpoints.up('md')]: {
      position: 'relative',
      width: '500px',
      height: '100vh',
    },*/
    float: 'right',
    paddingTop: '5px',
    zIndex: '1029',
    color: grayColor[7],
    border: '0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  appBarDark: {
    backgroundColor: defaultPallet[9],
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'fixed',
    width: 'calc(100vw - 64px)',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 64px)',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100vw - 64px)',
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      fontSize: '0.5rem',
    },
  /*  [theme.breakpoints.up('md')]: {
      position: 'relative',
      width: '500px',
      height: '100vh',
    },*/
    float: 'right',
    paddingTop: '5px',
    zIndex: '1029',
    color: grayColor[7],
    border: '0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  iconStyle: {
    color: defaultPallet[4],
    backgroundColor: grayColor[12],
    borderRadius: '30px',
    marginLeft: '5px',
    '&:hover': {
      color: whiteColor,
      backgroundColor: defaultPallet[24],
    },
    float: 'right',
    /*  [theme.breakpoints.down('sm')]: {
        width: '20px',
        height: '20px',
      },*/
  },
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    float: 'right',
    width:'100%',
    minHeight: '50px',
  },
  flex: {
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    [theme.breakpoints.down('xl')]:{
      marginLeft: '6%',
    },
    [theme.breakpoints.down('lg')]:{
      marginLeft: '6%',
    },
    [theme.breakpoints.down('md')]:{
      marginLeft: '8%',
    },
    [theme.breakpoints.down('sm')]:{
      marginLeft: '12%',
    },
     [theme.breakpoints.down('xs')]:{
      marginLeft: '24%',
    }
  },
  appResponsive: {
    top: '8px',
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  logoLink: {
    ...defaultFont,
    paddingTop: '0px',
    display: 'block',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '26px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor,
    },
  },
  logoImage: {
    top:'-10px',
    width: '64px',
    borderRadius:'50%',
   
    position: 'absolute',
    verticalAlign: 'middle',
  },
  logo: {
    position: 'relative',
    padding: '12px',
    zIndex: '4',
    width: '100%',
    height: '40px',
    // [theme.breakpoints.down('xl')]:{
    //   width: '90%',
    // },
    // [theme.breakpoints.down('lg')]:{
    //   width: '92%',
    // },
    // [theme.breakpoints.down('md')]:{
    //   width: '94%',
    // },
    [theme.breakpoints.down('sm')]:{
      width: '90%',
      height: '30px',
    },
     [theme.breakpoints.down('xs')]:{
      width: '90%',
    height: '30px',
    },
/*    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',

      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: `rgba(${hexToRgb(grayColor[6])}, 0.3)`,
    },*/
  },
});

export default navbarStyle;
