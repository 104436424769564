import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ChipInput from "../Chips/ChipInput";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  IconButton,
  DialogContent,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import ImageUploading from "react-images-uploading";
import { defaultPallet} from '../../assets/jss/colorSystem';



const styles = (theme) => ({
  dialogPaper: {
    borderRadius: 20,
    overflowY: 'auto',
     
  },
  dialogTitleRoot:{
    backgroundImage: 'linear-gradient(270deg,#54fde7,#bcf748)',
    /* backgroundColor:'#0beb46', */
    color:'#ffffff'
  },
  dialogContentRoot:{
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#808080',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#808070',
    },
    scrollbarColor: '#808080',
    scrollbarWidth: 'thin', 
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#ff3300',
  }, 
  right: {
    float: "right",
  },
  createBtn: {
    backgroundColor: '#99ff99',
    color: '#ffffff',
    margin: theme.spacing(1),
    textTransform: "none",
    width: 100,
    borderRadius: 11,
    "&:hover": {
      backgroundColor: '#ccffcc',
    },
  },
  cancelBtn: {
    margin: theme.spacing(1),
    backgroundColor: '#999999',
    color: '#333333',
    width: 100,
    textTransform: "none",
    borderRadius: 11,
    "&:hover": {
      backgroundColor: '#e6e6e6',
    },
  },
  image:{
    borderRadius:50,
    maxWidth:100,
    width:100
  },
  userIcon:{
    backgroundColor: '#999999',
    borderRadius:50,
    fontSize:60,
    color: '#ffffff'
  }
});

class ProjectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.selectedProject && props.selectedProject.title ? props.selectedProject.title : '' ,
      description: props.selectedProject && props.selectedProject.description ? props.selectedProject.description : '',      
      geo: props.selectedProject && props.selectedProject.geo ? props.selectedProject.geo : '',
      nexus: props.selectedProject && props.selectedProject.nexus ? props.selectedProject.nexus : '',
      avatar: props.selectedProject && props.selectedProject.avatar ? props.selectedProject.avatar : "",
      images: [],
      tags: props.selectedProject && props.selectedProject.tags ? props.selectedProject.tags : '',
      
    };
    this.handleEditProject = this.handleEditProject.bind(this);
    this.handleCreateProject = this.handleCreateProject.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.feedModal = this.feedModal.bind(this);
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);  
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);  
    this.handleGeoChange = this.handleGeoChange.bind(this);  
    this.handleNexusChange = this.handleNexusChange.bind(this);  
    this.handleChangeTag = this.handleChangeTag.bind(this);       
    this.handleKeyPress = this.handleKeyPress.bind(this);  
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isProjectModalOpen, selectedProject } = this.props;
    if (isProjectModalOpen !== prevProps.isProjectModalOpen) {
      this.toggleModal(isProjectModalOpen);
    }
    if (isProjectModalOpen !== prevProps.isProjectModalOpen &&
    isProjectModalOpen &&
    selectedProject &&
    selectedProject !== null){
      this.feedModal(selectedProject);
    }
  }

  toggleModal = (value) => {
    const{onClose}= this.props;
    if (value === false) {
      onClose();
    }
    this.setState({
      title:'',
      description: '', 
      geo: '',
      nexus: '',           
      avatar: "",
      images: [],
      tags: '',
    });
  };

  feedModal = (data) => {
    this.setState({
      title: data.title || "",
      description: data.description || "",     
      geo: data.geo || "",
      nexus: data.nexus || "",
      avatar: data.avatar || '',
      images: [data.avatar || ''] || [],     
      tags: data.tags || '',
      
    });
  };

  handleTitleChange (event) {
    this.setState({
      title: event.target.value
    });
  };

 
  handleChangeTag=(tags) => {
    this.setState({
      tags: JSON.stringify(tags)
    });
  }


  handleDescriptionChange = (event) => {
    this.setState({
      description: event.target.value
    });
  };

  handleGeoChange =(event)=>{
    this.setState({
      geo: event.target.value
    });
  }
  handleNexusChange =(event)=>{
    this.setState({
      nexus:event.target.value
    });
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleCreateProject(event);
    }
  };



  handleCreateProject = (event) => {
    event.preventDefault();

    const {
      title,
      description,
      tags,
      avatar,
      geo,
      nexus
    } = this.state;
    const { createProject } = this.props;
  

    createProject(
      title,
      description,
      geo,
      nexus,
      avatar,
      tags,
    );

    this.toggleModal(false);
      
  };

  handleEditProject = (event) => {
    event.preventDefault();

    const {
      title,
      description,
      tags,
      avatar,
      geo,
      nexus      
    } = this.state;
    const { editProject, selectedProject } = this.props;
    const { _id } = selectedProject;
    this.toggleModal(false);
    editProject(
      _id,
      title,
      description,
      geo,
      nexus,
      avatar,
      tags,
    );
    
    
  };

  handleAvatarChange = (imageList) => {
    if (imageList[0]) {
      if (imageList[0].data_url) {
        this.setState({
          avatar: imageList[0].data_url,
          // isEditMode: true,
          isUploaderMode: true,
        });
      } else {
        this.setState({
          avatar: "",
          images: [],
          // isEditMode: true,
          isUploaderMode: true,
        });
      }
    }
  };



  render() {
    const {
      classes,
      selectedProject,
      t,
      isProjectModalOpen,
      isEditMode,
      onClose,
    } = this.props;
    const {
      title,
      description,
      tags,
      avatar,
      geo,
      nexus
    } = this.state;
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth="sm"
          scroll="paper"
          onClose={this.toggleModal}
          open={isProjectModalOpen}
          classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <DialogTitle classes={{root:classes.dialogTitleRoot}}>
            {isEditMode ? 'Edit Project' : 'Create new project'}
          </DialogTitle>       
          <DialogContent className={classes.dialogContent} classes={{root: classes.dialogContentRoot}}>  
          <ImageUploading
                value={avatar}
                onChange={this.handleAvatarChange}
                maxNumber={1}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <>
                    {avatar === "" || avatar == null ? (
                      <>
                        <div
                          className={classes.imageUploadDiv}
                          {...dragProps}
                          // style={isDragging ? { backgroundColor: defaultPallet[4] } : null}
                        >
                          <Person className={classes.userIcon} />
                        </div>
                        <IconButton
                          aria-label="Add image"
                          component="span"
                          className={classes.addIcon}
                          onClick={onImageUpload}
                        >
                          <Add />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <img
                          src={imageList}
                          alt=""
                          className={classes.image}
                          onClick={onImageUpload}
                        />
                        <IconButton
                          aria-label="Edit image"
                          component="span"
                          className={classes.icon}
                          onClick={onImageUpload}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="Remove image"
                          component="span"
                          className={classes.icon}
                          onClick={() => onImageRemove(0)}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </>
                )}
              </ImageUploading>      
            <TextField
              required
              fullWidth
              value={title}
              label={isEditMode ?t("Edit title") : t("New title")}
              placeholder={t("Type the title")}
              type="text"
              margin="normal"
              onChange={this.handleTitleChange}
              // error={!title.isValid}
              // onKeyPress={this.handleKeyPress}
              autoFocus
            />
            <TextField
              fullWidth
              label={isEditMode ? `${t("Edit description")}` : "New description"}
              placeholder={t("Type description...")}
              type="text"
              margin="normal"              
              value={description}
              onChange={this.handleDescriptionChange}
              onKeyPress={this.handleKeyPress}
            />
         
             <div>
              <ChipInput 
                defaultValue={tags !== '' ? JSON.parse(tags) : ''}
                fullWidth 
                onChange={(tags) => this.handleChangeTag(tags)}
                label={isEditMode ? `${t("Edit tags")}` : "New tags"} />
            </div> 
            <TextField
              fullWidth
              multiline
              rows={4}
              label={isEditMode ? `${t("Edit geo")}` : "New geo"}
              placeholder={t("Type geo...")}
              type="text"
              margin="normal"              
              value={geo}
              onChange={this.handleGeoChange}
              onKeyPress={this.handleKeyPress}
            />  
            <TextField
              fullWidth
              multiline
              rows={4}
              label={isEditMode ? `${t("Edit nexus")}` : "New nexus"}
              placeholder={t("Type nexus...")}
              type="text"
              margin="normal"              
              value={nexus}
              onChange={this.handleNexusChange}
              onKeyPress={this.handleKeyPress}
            />        
            
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
                Cancel
                </Button>
                <Button onClick={isEditMode ? this.handleEditProject : this.handleCreateProject}>
                {isEditMode ? 'Edit' : 'Add'}
                </Button>                                 
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ProjectModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  createProject: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isProjectModalOpen: PropTypes.bool.isRequired,
  selectedProject: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,
};
export default withStyles(styles)(withTranslation()(ProjectModal));
