import React from "react";
//import { Route, Switch, Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PrivateRoute from "../containers/PrivateRoute";
import Navbar from "./Navbars/Navbar";
//import Sidebar from "./Sidebar";
import TopProgressBar from "./TopProgressBar";
import routes from "../utils/mainSideRoutes";
import Dash from '../containers/Dash';
import styles from "../assets/jss/layouts/dashboardStyle";
import logo from "../assets/images/logo.png";
import { defaultPallet, grayColor } from "../assets/jss/colorSystem";

const useStyles = makeStyles(styles);

export default function Main(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sideBarOpen, setSidebarOpen] = React.useState(true);

  const {
    activeUser,

    isFetching,
    isConnected,



    editUser,
    logout,

    setDarkMode,
    isDark,
    searchUsers,
    redirectTo,
  } = props;

  const filterObject = (obj) => {
    let result = false;
    for (const key in obj) {
      if (obj[key] === true) {
        result = true;
      }
    }
    return result;
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  /* const switchRoutes = (
    <Switch>
      
      {routes.map((prop, key) => (
        <PrivateRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      ))}
      <Redirect from="/" to="/app" />
    </Switch>
  ); */

  return (
    <main className={isDark ? classes.wrapperDark : classes.wrapper}>
      <TopProgressBar
        loadingBarProgress={0}
        isFetching={filterObject(isFetching)}
        isDark={isDark}
      />
      {/* <Sidebar
        routes={routes}
        logoText="NexuSustain"
        logo={logo}
        open={mobileOpen}
        mobileOpen={mobileOpen}
        isFetching={filterObject(isFetching)}
        isConnected={isConnected}
        activeUser={activeUser}
        isDark={isDark}
      /> */}
      <div className={isDark ? classes.mainPanelDark : classes.mainPanel}>
        <Navbar
          isFetching={filterObject(isFetching)}
          isDark={isDark}
          routes={routes}
          logoText="NexuSustain"
          logo={logo}
          color="primary"
          redirectTo={redirectTo}
          mobileOpen={mobileOpen}
          activeUser={activeUser}
      
          editUser={editUser}
          logout={logout}
          isConnected={isConnected}
          setDarkMode={setDarkMode}
          searchUsers={searchUsers}
        />
        <div className={classes.map}> <PrivateRoute
          path={'/'}
          component={Dash}
          key={'dash'}
        /> </div>
      </div>
    </main>
  );
}
Main.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,

  activeUser: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    displayName: PropTypes.string,
    language: PropTypes.string,
    avatar: PropTypes.string,
    isMember: PropTypes.bool,
    isCreator: PropTypes.bool,
    isManager: PropTypes.bool,
    isChannelMember: PropTypes.bool,
    token: PropTypes.string,
    channel: PropTypes.object,
    otp: PropTypes.string,

    notes: PropTypes.array,
    events: PropTypes.array,
    metrics: PropTypes.array,
  }).isRequired,

  editUser: PropTypes.func.isRequired,
  // eslint-disable-next-line
  /*  errorChannel: PropTypes.instanceOf(Error),
    errorContact: PropTypes.instanceOf(Error),*/
  isConnected: PropTypes.bool.isRequired,

  isFetching: PropTypes.object.isRequired,

  setDarkMode: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
  searchUsers: PropTypes.func.isRequired,
  redirectTo: PropTypes.func.isRequired,
};
