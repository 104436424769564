import {
  defaultPallet,
  blueColor, defaultFont, whiteColor, dangerColor, grayColor,
} from '../colorSystem';

const userProfileSidebarStyle = (theme) => ({
  menuItemStyle: {
    '&:hover': {
      backgroundColor: defaultPallet[24],
    },
  },
  displayName: {
    ...defaultFont,
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '1em',
    color: '#6e6868',
    fontWeight: 'bold',
    cursor: 'pointer',
    opacity: '1',
    lineHeight: '1em',

  },
  displayNameDark: {
    ...defaultFont,
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '1em',
    color: defaultPallet[0],
    fontWeight: 'bold',
    cursor: 'pointer',
    opacity: '1',
    lineHeight: '1em',

  },
  displayNameOffline: {
    ...defaultFont,
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '1em',
    color: defaultPallet[6],
    fontWeight: 'bold',
    opacity: '0.6',
    cursor: 'default',
    lineHeight: '1em',
  },
  displayNameOfflineDark: {
    ...defaultFont,
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '1em',
    color: defaultPallet[0],
    fontWeight: 'bold',
    opacity: '0.6',
    cursor: 'default',
    lineHeight: '1em',
  },
  text:{
    color: '#6e6868',
    fontSize: 10,
    display: 'block',
  },
  textDark:{
    color: defaultPallet[0],
    fontSize: 10,
    display: 'block',
  },
  arrowDown: {
    display: 'inline-block',
    // marginTop: '7px',
    cursor: 'pointer',
    fontSize: 15,
  },
  inline: {
    display: 'inline',
  },
  right: {
    float: 'right',
  },
  drawerPaper:{
    width: 400,
  },
  drawerHeader:{
    padding: 20,
  },
  drawerTitle:{
    color: defaultPallet[14],
    textTransform: 'none',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grayColor[8],
  },
  logoutBtn:{
    backgroundColor: dangerColor[4],
    color: dangerColor[0],
    borderRadius: 10,
    textTransform: 'none',
    width: '20%',
    marginTop: 20,
  },
  editableUserImage:{
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginLeft: theme.spacing(3),
    borderRadius: 20,
    '&.MuiAvatar-rounded':{
      borderRadius: 21,
    },
  },
  buttonsDiv:{
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(1),
  },
  iconButton:{
    color: grayColor[8],
    '&:hover': {
      backgroundColor: defaultPallet[17],
      color: whiteColor
    },
  },
  textField:{
    width:'80%',
    marginLeft: theme.spacing(1),
    '&.MuiTextField-root':{
      color: defaultPallet[0]
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  listItem:{
    '&:hover':{
      '& $listItemAvatar':{
        backgroundColor: defaultPallet[17],
      },
      '& $listItemIcon':{
        color: whiteColor,
      },
      '& $primary':{
        color: defaultPallet[17],
      },
    }
  },
  listItemAvatar:{
    backgroundColor: grayColor[25],
    borderRadius: 10,
  },
  listItemIcon:{
    color: defaultPallet[17],
  },
  primary:{
    color: defaultPallet[14],
    fontWeight: 500,
  },
  secondary:{},
  iconButtonRoot:{},
  linkButton:{
    color: grayColor[8],
  },
  saveBtn:{
    backgroundColor: defaultPallet[20],
    color: defaultPallet[1],
    boxShadow: 'none',
    borderRadius: 10,
    textTransform: 'none',
    width: '17%',
    height:32,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  cancelBtn:{
    backgroundColor: dangerColor[4],
    color: dangerColor[0],
    borderRadius: 10,
    boxShadow: 'none',
    textTransform: 'none',
    width: '17%',
    height:32,
    marginTop: theme.spacing(1),
  },
  deleteImageBtn:{
    position: 'absolute',
    left: theme.spacing(9),
    color: dangerColor[0],
  },
  addImageBtn:{
    position: 'absolute',
    left: theme.spacing(4),
    color: defaultPallet[0],
  },
  imageIconButton:{
    '&:hover':{
      backgroundColor: 'transparent',
    }
  },
  image:{
    width: '100px'
  },
  userIcon:{
    fontSize: 60,
    borderRadius: 50,
    backgroundColor:grayColor[0]
  },
  addIcon:{
    marginLeft:5
  }

});

export default userProfileSidebarStyle;
