import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import Mail from '@material-ui/icons/MailOutline';
import Lock from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import config from '../config';


import {
 defaultPallet, blueColor, grayColor, whiteColor,
} from '../assets/jss/colorSystem';
import { Link } from '@material-ui/core';
import styles from '../assets/jss/components/loginFormStyle';

const FormTextField = withStyles({
  root: {
    width: '90%',
    marginLeft: 10,
    '& label.Mui-focused': {
      color: defaultPallet[1],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: defaultPallet[1],
    },
    '& label': {
      color: grayColor[10],
    },
    '& .MuiInput-underline': {
      borderBottomColor: grayColor[6],
    },
    '& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: defaultPallet[1],
    },
  },
})(TextField);
const SocialMediaButton = withStyles({
  root: {
    minWidth: 10,
    minHeight: 25,
    borderRadius: 11,
  },
  outlined:{
    padding: '5px 5px',
  }
})(Button);

class LoginForm extends React.Component {
  constructor() {
  super();
  this.state = {
    email: {
      value: '',
      isValid: true,
    },
    password: {
      value: '',
      isValid: true,
    },
    captcha: {
      value: '',
      isValid: true,
    },
    showPassword: false,
  };
    this.googleAuth = null;

  this.handleEmailChange = this.handleEmailChange.bind(this);
  this.handlePasswordChange = this.handlePasswordChange.bind(this);
  this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleKeyPress = this.handleKeyPress.bind(this);
  this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
}

  componentDidMount() {
 
}

  handleEmailChange = (event) => {
    const email = event.target.value;
   this.setState({
      email: {
        value: email,
        isValid: true,
      },
});
  };

  handleCaptchaChange = (token) => {
    this.setState({
      captcha: {
        value: token,
        isValid: true,
      },
    });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  };

  handlePasswordChange = (event) => {
    const pass = event.target.value;
    this.setState({
       password: {
              value: pass,
              isValid: true,
            },
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { email, password } = this.state;
    const { onSubmit, t } = this.props;
    if (onSubmit) {
      onSubmit(email.value, password.value, t);
    } else {
      window.location.reload(true);
    }

    this.setState({
      email: {
        value: '',
        isValid: true,
      },
      password: {
        value: '',
        isValid: true,
      },
    });
  };

  /* handleGoogleSubmit = () => {
    if (this.googleAuth) {
      this.googleAuth.signIn({
        scope: 'profile email',
      }).then((user) => {
        const { onSubmitGoogle, t } = this.props;
        const response = user.getAuthResponse();
        const profile = user.getBasicProfile();
        const endUser = {
          profile,
          response,
        };
        onSubmitGoogle(endUser, t).then(() => {
          this.googleAuth.disconnect();
          this.googleAuth = null;
          this.setState({
            email: {
              value: '',
              isValid: true,
            },
            password: {
              value: '',
              isValid: true,
            },
          });
        });
      });
    } else if (gapi) {
      gapi.load('auth2', () => {
        const params = {
          client_id: config.OAUTH_CLIENT_ID,
        };
        gapi.auth2.init(params).then(() => {
          this.googleAuth = gapi.auth2.getAuthInstance();
        });
      });
    }
  }; */

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { classes, t } = this.props;
    const { email, password, showPassword } = this.state;

    return (
      <div className={classes.root} onKeyPress={this.handleKeyPress}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Mail className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                label={t('Email')}
                type="text"
                name="email"
                margin="normal"
                autoComplete="email"
                value={email.value}
                onChange={this.handleEmailChange}
                error={!email.isValid}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Lock className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                label={t('Password')}
                name="password"
                margin="normal"
                autoComplete="current-password"
                value={password.value}
                onChange={this.handlePasswordChange}
                error={!password.isValid}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.visibilityIcon} /> : <VisibilityOff className={classes.visibilityIcon} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

      
        <div className={classes.margin} />
        {/*link*/}
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item />
          <Grid item className={classes.fullWidth}>
            <Link
              href="#"
              // onClick={}
              color="inherit"
              variant="body2"
              underline="always"
              className={classes.link}
            >
              {t('Forgot password?')}
            </Link>
          </Grid>
        </Grid>

        <div className={classes.margin} />

        <Button
          type="submit"
          className={classes.signInButton}
          onClick={this.handleSubmit}
        >
          {t('Sign In')}
        </Button>

        <div className={classes.margin} />
       {/*  <div className={classes.container}>
          <div className={classes.border} />
          <span className={classes.content}>{t('OR')}</span>
          <div className={classes.border} />
        </div> */}

        <div className={classes.margin} />
        {/*SocialMediaButtons*/}
        {/* <Grid container>
          <Grid item spacing={1} xs={6} sm={6} md={6} direction="row">
           
          </Grid>

          

        </Grid> */}
      </div>
    );
  }
}
LoginForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitGoogle: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
};
export default withStyles(styles)(withTranslation()(LoginForm));
