import React, { Component, createRef } from "react";
import "../../assets/styles/chartist.css";
import "../../assets/styles/leaflet.css";
import config from "../../config";
import L from "../../utils/leaflet-src";
import ChipInput from "../Chips/ChipInput";
import Logo from "../../assets/images/logo.png";
/* import ChartistGraph from "react-chartist"; */
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Close from "@material-ui/icons/Close"
import Fullscreen from "@material-ui/icons/Fullscreen"
import AssignmentIcon from "@material-ui/icons/Assignment"
import TocIcon from "@material-ui/icons/Toc"
import BarChartIcon from "@material-ui/icons/BarChart"
import RoomIcon from "@material-ui/icons/Room"
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import "../../assets/styles/Leaflet.StyleEditor.css";
import "../../assets/styles/control.layers.minimap.css";
import setupStyleEditor from "../LeafletStyleEditor/Leaflet.StyleEditor";
import setupLocatorControl from "../LeafletControlLocate";
import setupCountrySelect from "./LeafletCountrySelect";
import setupFileApi from "./leafletFileApi";
import setupControlWindow from "./LeafletControlWindow";
import setupSimpleButton from "./leafletSimpleButton";
/* import setupTilelayerProviders from '../LeafletTilelayerProviders/tileLayerProviders' */
import setupTilelayerMinimaps from "../LeafletTilelayerProviders/tilelayerSyncMinimaps";
import "../../assets/styles/leaflet-gesture-handling.scss";
import "../../assets/styles/leaflet-control-window.css";
import "../../assets/styles/leaflet.draw.css";
import "../../assets/styles/jexcel.css";
import "../../assets/styles/jsuites.css";
import { store } from "react-notifications-component";
import "../../assets/styles/leaflet-simple-button.css";
import "leaflet-draw";
import nodata from "../../assets/images/nodata.png";
import jspreadsheet from "jspreadsheet-ce";
/* import Organogram from "./Organogram"; */
import { getCountryPolygon } from "./Countries";
//import nodeRendererWefNeeds from "../node-renderer-default";
import nodeRendererNexuSustain from "../node-renderer-nexusustain";
import SortableTree, {
  removeNode,
  getNodeAtPath,
  find,
  walk,
  map,
  changeNodeAtPath,
} from "react-sortable-tree";
import "../../assets/styles/tree.css";
import ApexChart from 'react-apexcharts'
//import "react-sortable-tree/style.css";
import "leaflet-gesture-handling";
import { Typography } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Link from "@material-ui/core/Link";
import Loader from "react-loaders";
import _ from "lodash";
import nextId from "react-id-generator";
import moment from "moment";
import RandomPointsOnPolygon from "utils/RandomPointsOnPolygon";
import mapExcelConstants from "utils/mapExcelConstants";



// defines the default image path for leaflet icon component
L.Icon.Default.imagePath = "/images/";

//imports and setsup use of Style Editor leaflet component
setupStyleEditor();
setupLocatorControl();
//imports and setsup use of TileLayer Manager leaflet component
setupTilelayerMinimaps();

//imports and setsup use of Country Select leaflet component
setupCountrySelect();

//imports and setsup use of File API leaflet component
setupFileApi();

//imports and setsup use of Control Window leaflet component
setupControlWindow();

//imports and setsup use of Simple Button leaflet component
setupSimpleButton();

// Sets the loader component for lazy loading's loading progress animation
const loader = (
  <Loader
    type="ball-beat"
    style={{
      display: "inline-block",
      verticalAlign: "middle",
      transform: "scale(0.5, 0.5)",
    }}
  />
);

// Map Component Styles
const styles = (theme) => ({
  paper: {
    height: "100%",
    overflowX: "hidden"
  },
  dialogPaper: {
    height: "80vh",
    overflowX: "auto",
    padding: "10px",
    textAlign: "center",
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#808080',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#808070',
    },
    scrollbarColor: '#808080',
    scrollbarWidth: 'thin',
  },
  dialogTitleRoot: {
    backgroundImage: 'linear-gradient(90deg,#54fde7,#bcf748)',
    color: '#ffffff'
  },
  link: {
    textDecoration: "underline",
  },
  sparkStyle: {},
  closeButton: {
    position: "absolute",
    right: '16px',
    top: '16px',
    color: '#ff3300',
  },
  fullScreenButton: {
    position: "absolute",
    right: '50px',
    top: '16px',
    color: '#ffffff',
  },
  chartButton: {
    position: "absolute",
    left: '50px',
    top: '16px',
    color: '#ffffff',
  },
  buttonsDiv: {
    display: 'inline'
  },
  topBtn: {
    backgroundColor: '#a4e8bf',
    borderColor: '#bfedd3',
    color: '#2f2e2e',
    borderRadius: 3,
    marginLeft: 1,
    marginBottom: 2,
    width: '33%',
    height: '10%',
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '13px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    },
  },
  squareBtn: {
    width: '99%',
    height: '99%',
    borderRadius: 3,
    backgroundColor: '#e08e0d',
    marginBottom: 10,
    padding: '17%'

  }
});


// Default Geojson file to initiate map
// TODO: Should read from Geo field of project loaded instead of default
// here and for new projects without GeoJson data coming from Geo field of project 
// this geojson default is used as default primal value
const geojson = JSON.parse(
  config.DEFAULT_GEOJSON
);

// Map Component leaflet base layer options
//TODO: move to config file to be configurable from outside the source code
const baselayers = {
  StamenToner: L.tileLayer(
    'https://stamen-tiles-{s}.a.ssl.fastly.net/{variant}/{z}/{x}/{y}{r}.{ext}',
    {
      attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data {attribution.OpenStreetMap}',
      subdomains: 'abcd',
      minZoom: 0,
      maxZoom: 20,
      variant: 'toner',
      ext: 'png'
    }
  ),
  StamenTonerHybrid: L.tileLayer(
    'https://stamen-tiles-{s}.a.ssl.fastly.net/{variant}/{z}/{x}/{y}{r}.{ext}',
    {
      attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data {attribution.OpenStreetMap}',
      subdomains: 'abcd',
      minZoom: 0,
      maxZoom: 20,
      variant: 'toner-hybrid',
      ext: 'png'
    }
  ),

  OpenStreetMap: L.tileLayer(
    "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      attribution: '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      variants: {
        Mapnik: {},
        DE: {
          url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
          options: {
            maxZoom: 18
          }
        },
        CH: {
          url: 'https://tile.osm.ch/switzerland/{z}/{x}/{y}.png',
          options: {
            maxZoom: 18,
            bounds: [[45, 5], [48, 11]]
          }
        },
        France: {
          url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
          options: {
            maxZoom: 20,
            attribution: '&copy; OpenStreetMap France | {attribution.OpenStreetMap}'
          }
        },
        HOT: {
          url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
          options: {
            attribution:
              '{attribution.OpenStreetMap}, ' +
              'Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> ' +
              'hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
          }
        },
        BZH: {
          url: 'https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png',
          options: {
            attribution: '{attribution.OpenStreetMap}, Tiles courtesy of <a href="http://www.openstreetmap.bzh/" target="_blank">Breton OpenStreetMap Team</a>',
            bounds: [[46.2, -5.5], [50, 0.7]]
          }
        }
      },
      variant: 'HOT'
    }
  ),
  OpenTopoMap: L.tileLayer(
    `https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png`,
    {
      maxZoom: 17,
      attribution: 'Map data: {attribution.OpenStreetMap}, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'

    }
  ),
  USGS: L.tileLayer(
    `https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}`,
    {
      maxZoom: 20,
      attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'

    }
  ),
  CyclOSM: L.tileLayer(
    `https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png`,
    {
      maxZoom: 20,
      attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: {attribution.OpenStreetMap}'

    }
  ),
  /* MapBoxOutdoor: L.tileLayer(
    `https://api.mapbox.com/styles/v1/arshahvaran/cklev43ys5fu117nutoiubi9h/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXJzaGFodmFyYW4iLCJhIjoiY2txYjJmdGU3MDNyazJxczF0ZHgwc2kyNCJ9.gRYAzRe10GsHOvZrvBbJlg`,
    {
      attribution: '&copy; <a href="http://mapbox.com">MapBox Outdoor</a>',
    }
  ), */

  /* MapBoxStreet: L.tileLayer(
    `https://api.mapbox.com/styles/v1/arshahvaran/cklev672e3yt917ql4d30aufh/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXJzaGFodmFyYW4iLCJhIjoiY2tsZXZ1MzdzMTA0bTJ6bWcwdG80Ym96NyJ9.zY4UsfqUQxkrXn8ocdaDhA`,
    {
      attribution: '&copy; <a href="http://mapbox.com">MapBox Streets</a>',
    }
  ), */

  /* MapBoxSattelite: L.tileLayer(
    `https://api.mapbox.com/styles/v1/arshahvaran/ckleuy3ku1s4b17qlyoebgp36/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXJzaGFodmFyYW4iLCJhIjoiY2tsZXZ1MzdzMTA0bTJ6bWcwdG80Ym96NyJ9.zY4UsfqUQxkrXn8ocdaDhA`,
    {
      attribution: '&copy; <a href="http://mapbox.com">MapBox Sattelite</a>',
    }
  ), */
};

class MapWidget extends Component {
  constructor() {
    super();
    // Handles Excel Component's change events
    //this.handleWefExcelChange = this.handleWefExcelChange.bind(this);

    // Global location value for location picker which works on location columns of excel views
    this.locationColumn = null;

    // Global marker value for location picker
    this.marker = null;

    // Global map value to be used with this component (react leaftlet map)
    this.map = null;

    //Global spreadsheet options object
    this.jspreadsheetOptions = config.PARAMS_DEFAULT_OPTS;

    //Global excel nexusustain table object
    this.excelNexuTable = null;
    // Handles Excel Component's focus events
    this.handleExcelFocus = this.handleExcelFocus.bind(this);
    //Utility function to convert AlphaNumeric
    this.convertAlphaNumeric = this.convertAlphaNumeric.bind(this);
    //Handles editing general project item
    this.handleEditProject = this.handleEditProject.bind(this);

    //Handles params excel change
    this.handleNexuParamsExcelChange = this.handleNexuParamsExcelChange.bind(this);

    // Handles Excel Component's load events
    this.handleExcelLoad = this.handleExcelLoad.bind(this);

    // Handles Excel Component's params excel component's focus events
    this.handleExcelParamDataFocus = this.handleExcelParamDataFocus.bind(this);

    // Handles Excel Component's params excel component's change events
    this.handleExcelParamDataChange = this.handleExcelParamDataChange.bind(
      this
    );

    // Handles Excel Component's params excel component's saving to backend
    this.handleSaveParamDataExcel = this.handleSaveParamDataExcel.bind(this);

    // Handles Excel Component's params excel component's loading from backend
    this.handleExcelParamDataLoad = this.handleExcelParamDataLoad.bind(this);

    // Handles Excel Component's params excel component's loading from received data
    this.handleLoadParamTreeData = this.handleLoadParamTreeData.bind(this);

    //Handles loading Recommendation excel data
    this.handleNexuLoadRecommendationExcel = this.handleNexuLoadRecommendationExcel.bind(this);

    //Handles loading Variable selection excel data
    this.handleNexuLoadVariableSelectExcel = this.handleNexuLoadVariableSelectExcel.bind(this);

    //Handles loading overview excel data
    this.handleNexuLoadOverviewMatrixExcel = this.handleNexuLoadOverviewMatrixExcel.bind(this);

    //Handles loading visualization excel data
    this.handleNexuLoadVisualizationSelectExcel = this.handleNexuLoadVisualizationSelectExcel.bind(this);

    //Handles after change variable excel tags
    this.handleNexuChangeVariableTags = this.handleNexuChangeVariableTags.bind(this);

    //Handles after change variable selection excel  tags
    this.handleNexuChangeVariableSelectionTags = this.handleNexuChangeVariableSelectionTags.bind(this);

    //Handles after change vizualization excel
    this.handleNexuChangeVisualizationTags = this.handleNexuChangeVisualizationTags.bind(this);

    //Handles after load visualuzation selection tags
    this.handleNexuAfterLoadVisualizationSelectionTags = this.handleNexuAfterLoadVisualizationSelectionTags.bind(this);

    //Handles after change visualuzation selection tags
    this.handleNexuChangeVisualizationSelectionTags = this.handleNexuChangeVisualizationSelectionTags.bind(this);

    //Handles load variable selection tags
    this.handleNexuLoadVariableSelectionTags = this.handleNexuLoadVariableSelectionTags.bind(this);
    
    //Handles after load variable selection tags
    this.handleNexuAfterLoadVariableSelectionTags = this.handleNexuAfterLoadVariableSelectionTags.bind(this);

   


    // Handles Excel Component's params excel options loading from received data param's excel data
    //this.handleWefParamExcelDefaultOptionsLoader = this.handleWefParamExcelDefaultOptionsLoader.bind(this);

    //Map Component's React State
    // Contains a full initial state of treeData used in taxonomy view
    this.state = {
      center: [52, 2],
      zoom: 3,
      location: null,
      gestureHandling: true,
      drawOptions: null,
      editableLayers: null,
      geojson: geojson,
      featuregroup: null,
      layergroup: null,
      datagroup: null,
      params: [],
      isParamsChanged: false,
      isParamsDataModalOpen: false,
      paramsDataMatrix: 'MMM',
      isRecommendationsModalOpen: false,
      isVariablesModalOpen: false,
      isParamsModalOpen: false,
      isOverviewModalOpen: false,
      isVisualizationModalOpen: false,
      isChartsModalOpen: false,
      isDialogFullScreen: false,
      isChart: false,
      chartOptions: {
        chart: {
          type: 'bar',
          width: '100%',
          id: 'nexusustain-bar-visualization',
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '8px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 200,
              cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            format: undefined,
            formatter: undefined,
            datetimeUTC: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
            },
          },
          categories: [
            "Socio-Economic System",
            "Paid Work (PW)",
            "Household (HH)",
            "Rural",
            "Urban",
            "Primary Production",
            "Service and Governance (SG)",
            "Transportation (TR)",
            "WEF Nexus Sector",
            "Building and Manufacturing (BM)",
            "Water Sector (W)",
            "Energy and Mining (EM)",
            "Agriculture (AG)",
            "Fisheries",
            "Fruits, Vegetables and Cereals",
            "Animal Products",
            "Sugar Crops",
            "Oil Crops",
            "Nuclear",
            "Hydropower",
            "CCPP",
            "Water Treatment Plant (WTP)",
            "Water Desalination Plant (WDP)",
            "Rice",
            "Tomato",
            "Milk",
            "Meat",
          ]
        }
      },
      chartSeries: [{
        name: 'series-TEST-1',
        data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,],
      },
      {
        name: 'series-TEST-2',
        data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,],
      },
      {
        name: 'series-TEST-3',
        data: [11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11,],
      }],

      pieChartOptions: {
        chart: {
          id: 'nexusustain-pie-visualization',
          width: '100%',
          type: 'pie',
        },
        labels: ["Socio-Economic System",
          "Paid Work (PW)",
          "Household (HH)",
          "Rural",
          "Urban",
          "Primary Production",
          "Service and Governance (SG)",
          "Transportation (TR)",
          "WEF Nexus Sector",
          "Building and Manufacturing (BM)",
          "Water Sector (W)",
          "Energy and Mining (EM)",
          "Agriculture (AG)",
          "Fisheries",
          "Fruits, Vegetables and Cereals",
          "Animal Products",
          "Sugar Crops",
          "Oil Crops",
          "Nuclear",
          "Hydropower",
          "CCPP",
          "Water Treatment Plant (WTP)",
          "Water Desalination Plant (WDP)",
          "Rice",
          "Tomato",
          "Milk",
          "Meat",]
      },
      pieChartSeries: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,],
      parameterIndex: 0,
      parameter: "",
      paramChartData: null,
      paramChartType: "Bar",
      variableMatrix: 'MMM',
      treeData: [
        {
          id: "root-parameter",
          index: 0,
          title: "NexuSustain Parameters",
          subtitle: "Root",
          children: [],
          color: "#90EE90",
          img: Logo,
          isRoot: true,
          expanded: true,
        },
      ],
      nsTreeData: [
        {
          id: "root-parameter",
          index: 0,
          title: "Default Hirarchical Structure",
          subtitle: "Root",
          children: [],
          color: "#8debe9",
          img: Logo,
          isRoot: true,
          expanded: true,
        },
      ],
      isTreeDataReady: false,
      isChartDataReady: false,
      isBgLayersPanelOpen: true,
      tags: {
        MMM: [],
        EPM: [],
        PM: []
      },
      tagsVis: []
    };
  }

  // On component mount and load event
  componentDidMount() {
    const that = this;
    this.treeItems = new Map();
    this.reportMap = null;
    this.paramDataIndex = new Map();
    this.vis = new Map();
    this.PMVARS = new Map();
    this.EPMVARS = new Map();
    this.MMMVARS = new Map();
    /* this.PMMATRICES = new Map();
    this.EPMMATRICES = new Map();
    this.MMMMATRICES = new Map(); */
    // Location column object struct with methods
    this.locationColumn = {
      // Location column close location editor handler
      closeEditor: function (cell, save) {
        const value = that.state.location;
        cell.innerHTML = "";
        cell.innerHTML = value;
        return value;
      },
      // Location column open location editor handler
      openEditor: function (cell) {
        var element = document.createElement("div");

        element.setAttribute("id", "location-picker-map");
        element.setAttribute("class", "jexcel-location-picker");

        const cellValue = cell.innerHTML;
        if (cellValue && typeof cellValue === "string") {
          if (cellValue.toLowerCase().indexOf("latlng") > 0) {
            this.setState({ location: cellValue });
          }
        }

        // adding editor css class name to instance so that it becomes editable
        cell.classList.add("editor");

        // Emptying the DOM structure inside cell
        cell.innerHTML = "";

        // Appending editor DOM element
        cell.appendChild(element);

        // Setting location editor DOM element's map
        that.locationColumn.map = L.map("location-picker-map", {
          // @ts-ignore
          center: that.state.center,
          zoom: that.state.zoom,
          /* gestureHandling: that.state.gestureHandling, */
          layers: [
            L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
              attribution:
                '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
            }),
          ],
        }).on("click", function (e) {
          that.setState({ location: e.latlng });
          if (that.marker && that.marker !== null) {
            this.removeLayer(that.marker);
          }
          that.marker = L.marker(e.latlng);

          that.marker.addTo(this);
        });
      },
      // Getting value from cell
      getValue: function (cell) {
        return cell.innerHTML;
      },
      // Setting cell value
      setValue: function (cell, value) {
        cell.innerHTML = value;
      },
    };

    // Resetting JSpreadSheet Options configuration object
    this.jspreadsheetOptions = config.PARAMS_DEFAULT_OPTS;

    this.jspreadsheetOptions.toolbar =
      [{
        type: "i",
        content: "print",
        onclick: function () {
          window.print();
        },
      },
      {
        type: "i",
        content: "undo",
        onclick: function () {

          /* if (config.SYSTEM === 'wefneeds')  *///that.excelTable.undo();

        },
      },
      {
        type: "i",
        content: "redo",
        onclick: function () {
         /*  if (config.SYSTEM === 'wefneeds')  *///that.excelTable.redo();

        },
      },
      {
        type: "i",
        content: "download",
        onclick: function () {

          /* if (config.SYSTEM === 'wefneeds') */ //that.excelTable.download();

        },
      },
      {
        type: "i",
        content: "add",
        onclick: function () {
          /* if (config.SYSTEM === 'wefneeds') */ //that.excelTable.insertRow();

        },
      },
      {
        type: "select",
        k: "font-family",
        v: ["Arial", "Verdana"],
      },
      {
        type: "select",
        k: "font-size",
        v: [
          "9px",
          "10px",
          "11px",
          "12px",
          "13px",
          "14px",
          "15px",
          "16px",
          "17px",
          "18px",
          "19px",
          "20px",
        ],
      },
      {
        type: "i",
        content: "format_align_left",
        k: "text-align",
        v: "left",
      },
      {
        type: "i",
        content: "format_align_center",
        k: "text-align",
        v: "center",
      },
      {
        type: "i",
        content: "format_align_right",
        k: "text-align",
        v: "right",
      },
      {
        type: "i",
        content: "format_bold",
        k: "font-weight",
        v: "bold",
      },
      {
        type: "color",
        content: "format_color_text",
        k: "color",
      },
      {
        type: "color",
        content: "format_color_fill",
        k: "background-color",
      },
      ]

    this.jspreadsheetRecommendationOptions = config.PRECOMMENDATION_DEFAULT_OPTS;
    this.jspreadsheetOverviewOptions = config.OVERVIEW_DEFAULT_OPTS;
    this.jspreadsheetVisualizationOptions = config.VISUALIZATION_DEFAULT_OPTS;
    /* this.jspreadsheetRecommendationOptions.columns = that.jspreadsheetRecommendationOptions.columns.map((column)=>{return {...column, readOnly: true, editable: true}}); */

    this.jspreadsheetVariablesMMMOptions = config.MMM_DEFAULT_OPTS;
    this.jspreadsheetVariablesEPMOptions = config.EPM_DEFAULT_OPTS;
    this.jspreadsheetVariablesPMOptions = config.PM_DEFAULT_OPTS;
    this.jspreadsheetVariablesPMOptions.style = config.PM_DEFAULT_OPTS.style = config.PM_PARAM_DEFAULT_OPTS.style = mapExcelConstants.pmDefault
    this.jspreadsheetVariablesEPMOptions.style = config.EPM_DEFAULT_OPTS.style = config.EPM_PARAM_DEFAULT_OPTS.style = mapExcelConstants.epmDefault
    this.jspreadsheetVariablesMMMOptions.style = config.MMM_DEFAULT_OPTS.style = config.MMM_PARAM_DEFAULT_OPTS.style = mapExcelConstants.mmmDefault



    this.jspreadsheetVariablesMMMOptions.toolbar =
      this.jspreadsheetVariablesEPMOptions.toolbar =
      this.jspreadsheetVariablesPMOptions.toolbar =
      [

        {
          type: "i",
          content: "download",
          onclick: function () {
            that.excelVariablesTable.download();
          },
        }, {
          type: "i",
          content: "print",
          onclick: function () {
            window.print();
          },
        },

      ]

    this.jspreadsheetVisualizationOptions.toolbar =
      [

        {
          type: "i",
          content: "download",
          onclick: function () {
            that.excelVisualizationTable.download();
          },
        },
        {
          type: "i",
          content: "print",
          onclick: function () {
            window.print();
          },
        },
        {
          type: "select",
          k: "font-family",
          v: ["Arial", "Verdana"],
        },
        {
          type: "select",
          k: "font-size",
          v: [
            "9px",
            "10px",
            "11px",
            "12px",
            "13px",
            "14px",
            "15px",
            "16px",
            "17px",
            "18px",
            "19px",
            "20px",
          ],
        },
        {
          type: "i",
          content: "format_align_left",
          k: "text-align",
          v: "left",
        },
        {
          type: "i",
          content: "format_align_center",
          k: "text-align",
          v: "center",
        },
        {
          type: "i",
          content: "format_align_right",
          k: "text-align",
          v: "right",
        },
        {
          type: "i",
          content: "format_bold",
          k: "font-weight",
          v: "bold",
        },
        {
          type: "color",
          content: "format_color_text",
          k: "color",
        },
        {
          type: "color",
          content: "format_color_fill",
          k: "background-color",
        },
      ];
    this.jspreadsheetOverviewOptions.toolbar =
      [

        {
          type: "i",
          content: "download",
          onclick: function () {
            that.excelOverviewTable.download();
          },
        },
        {
          type: "i",
          content: "print",
          onclick: function () {
            window.print();
          },
        },
        {
          type: "select",
          k: "font-family",
          v: ["Arial", "Verdana"],
        },
        {
          type: "select",
          k: "font-size",
          v: [
            "9px",
            "10px",
            "11px",
            "12px",
            "13px",
            "14px",
            "15px",
            "16px",
            "17px",
            "18px",
            "19px",
            "20px",
          ],
        },
        {
          type: "i",
          content: "format_align_left",
          k: "text-align",
          v: "left",
        },
        {
          type: "i",
          content: "format_align_center",
          k: "text-align",
          v: "center",
        },
        {
          type: "i",
          content: "format_align_right",
          k: "text-align",
          v: "right",
        },
        {
          type: "i",
          content: "format_bold",
          k: "font-weight",
          v: "bold",
        },
        {
          type: "color",
          content: "format_color_text",
          k: "color",
        },
        {
          type: "color",
          content: "format_color_fill",
          k: "background-color",
        },
      ];
    this.jspreadsheetRecommendationOptions.toolbar =
      [

        {
          type: "i",
          content: "download",
          onclick: function () {
            that.excelRecommendationTable.download();
          },
        },
        {
          type: "i",
          content: "print",
          onclick: function () {
            window.print();
          },
        },
        {
          type: "select",
          k: "font-family",
          v: ["Arial", "Verdana"],
        },
        {
          type: "select",
          k: "font-size",
          v: [
            "9px",
            "10px",
            "11px",
            "12px",
            "13px",
            "14px",
            "15px",
            "16px",
            "17px",
            "18px",
            "19px",
            "20px",
          ],
        },
        {
          type: "i",
          content: "format_align_left",
          k: "text-align",
          v: "left",
        },
        {
          type: "i",
          content: "format_align_center",
          k: "text-align",
          v: "center",
        },
        {
          type: "i",
          content: "format_align_right",
          k: "text-align",
          v: "right",
        },
        {
          type: "i",
          content: "format_bold",
          k: "font-weight",
          v: "bold",
        },
        {
          type: "color",
          content: "format_color_text",
          k: "color",
        },
        {
          type: "color",
          content: "format_color_fill",
          k: "background-color",
        },
      ];

    this.jspreadsheetOptions.columns = this.jspreadsheetOptions.columns.map((column) => {
      if (column.title === 'editor') {
        return { ...column, editor: this.locationColumn }
      } return column
    })



    // Making data structures 
    this.map = L.map("map", {
      center: this.state.center,
      zoom: this.state.zoom,
      gestureHandling: this.state.gestureHandling,
      layers: [],
      geojson: geojson,

      featuregroup: null,

      layergroup: null,
      datagroup: null,
    });

    // Setting editable layers initial value
    this.editableLayers = new L.FeatureGroup();
    /*   this.uploadedLayers = L.layerGroup([]); */

    // Setting data layers
    this.dataLayers = L.geoJson(geojson, {
      style: function (feature) {
        if (feature.geometry.type === "LineString") {
          return {
            color: "#ff00ff",
          };
        }
      },
    });

    // Setting GeoJSON layers
    this.geoJSONLayers = L.geoJson(geojson, {
      style: function (feature) {
        if (feature.geometry.type === "LineString") {
          return {
            color: "#ff00ff",
          };
        }
      },
    });

    // Setting map options onto the state
    this.setState(
      {
        drawOptions: {
          position: "topright",
          edit: {
            featureGroup: this.editableLayers,
            poly: {
              allowIntersection: false,
            },
          },
          draw: {
            polygon: {
              allowIntersection: false,
              showArea: true,
            },
          },
        },
        geojson: geojson,

        featuregroup: this.editableLayers,

        /*  layergroup: this.uploadedLayers, */
        datagroup: this.dataLayers,
      },
      () => {
        // The start map layers instances adding to leaflet instance
        // And setting leaflet draw controls
        const that = this;

        //Adding editable layers
        // @ts-ignore
        this.map.addLayer(this.editableLayers);

        //Instantiating leaflet draw controls
        this.drawControl = new L.Control.Draw(this.state.drawOptions);

        //Adding leaflet draw controls
        this.map.addControl(this.drawControl);

        //eventing leaflet draw controls
        this.map.on(L.Draw.Event.CREATED, function (e) {
          // @ts-ignore
          let type = e.layerType,
            layer = e.layer;

          if (type === "marker") {
            layer.bindPopup("NexuSustain Pops!");
          }

          // @ts-ignore
          that.editableLayers.addLayer(layer);
          that.setState(
            {
              featuregroup: that.editableLayers,
            },
            () => {
              that.layersControl.removeLayer(that.editableLayers);
              that.layersControl.addOverlay(that.editableLayers, "Editables");
            }
          );
        });
        // Instantiating leaflet simple button for params 
        const homeBtn = new L.Control.SimpleButton({
          id: "nexusustain-home-btn-control",
          faIcon: "fa-folder",
          text: "Go to project selection",
          click: function () {
            /* paramWindow.show(); */
            const { goHome } = that.props;
            goHome();

          },
        });

        // Adding leaflet simple button for re-opening the projects modal 
        homeBtn.addTo(this.map);
        const editButton = new L.Control.SimpleButton({
          id: "nexusustain-edit-btn-control",
          faIcon: "fa-save",
          text: "Save project",
          click: function () {
            /* paramWindow.show(); */
            that.handleEditProject()

          },
        });

        // Adding leaflet simple button for re-opening the projects modal 
        editButton.addTo(this.map);
        this.locateControl = L.locate({
          position: "topright",
        });

        // Adding leaflet style control
        this.map.addControl(this.locateControl);
        this.styleControl = L.control.styleEditor({
          position: "topright",
          colorRamp: ["#1abc9c", "#2ecc71", "#3498db", "red", "blue", "green", "yellow", "orange", "purple", "teal", "black", "grey", "white", "lightgreen", "darkred"],
        });

        // Adding leaflet style control
        this.map.addControl(this.styleControl);

        // Instantiating leaflet simple button for base layer backgrounds panel
        const bgLayersButton = new L.Control.SimpleButton({
          id: "nexusustain-baselayers-btn-control",
          faIcon: "fa-map-o",
          text: "Base layers Management",
          click: function () {
            that.setState({ isBgLayersPanelOpen: !that.state.isBgLayersPanelOpen });
          },
        });

        // Adding leaflet simple button for bg layers show/hide  
        bgLayersButton.addTo(this.map);

        // Instantiating leaflet simple button for params 
        const paramButton = new L.Control.SimpleButton({
          id: "nexusustain-params-btn-control",
          faIcon: "fa-list",
          text: "Parameters Management",
          click: function () {
            /* paramWindow.show(); */
            const { nsTreeData } = that.state;
            config.DEFAULT_NS_TREE_DATA.forEach((item) => nsTreeData.push(item))
            that.setState({ isParamsModalOpen: true, nsTreeData }, () => {
              setTimeout(() => {
                /* if (config.SYSTEM === 'wefneeds') {
                  that.handleLoadParamTreeData("wefneeds-params-window-div");
                }
                else {
                  that.handleLoadParamTreeData(`nexusustain-${that.state.paramsDataMatrix}-window-div`);
                } */
                that.handleLoadParamTreeData(`nexusustain-${that.state.paramsDataMatrix}-window-div`);
              }, 100);
            });
          },
        });

        // Adding leaflet simple button for params 
        paramButton.addTo(this.map);




        // Instantiating leaflet simple button for charts 
        const chartsButton = new L.Control.SimpleButton({
          id: "nexusustain-charts-btn-control",
          faIcon: "fa-bar-chart",
          text: "Parameters Charts",
          click: function (evt) {
            evt.preventDefault();

            const { params, treeData } = that.state;
            that.treeItems.set("root-parameter", treeData[0]);
            if (params) {
              if (params.length > 0) {
                params.forEach((param, index) => {
                  index = index + 1;
                  if (!that.treeItems.get(param.Level1)) {
                    let l1Item = {
                      id: param.Level1,
                      index: index,
                      title: param.Level1,
                      subtitle: "Level1",
                      children: [],
                      parent: "root-parameter",
                      color: "#8df29a",
                      img: Logo,
                      isRoot: false,
                    };
                    that.treeItems.set(param.Level1, l1Item);
                  }
                  if (!that.treeItems.get(param.Level2)) {
                    let l2Item = {
                      id: param.Level2,
                      index: index,
                      title: param.Level2,
                      subtitle: "Level2",
                      children: [],
                      parent: param.Level1,
                      color: "#fabdaf",
                      img: Logo,
                      isRoot: false,
                    };
                    that.treeItems.set(param.Level2, l2Item);
                  }
                  if (!that.treeItems.get(param.Level3)) {
                    let l3Item = {
                      id: param.Level3,
                      index: index,
                      title: param.Level3,
                      country: param.Country,
                      subtitle: "Level3",
                      children: [],
                      parent: param.Level2,
                      color: "#8debe9",
                      img: param.image || Logo,
                      isRoot: false,
                      handleClick: (
                        node,
                        paramTitle,
                        paramIndex,
                        paramCountry
                      ) => {
                        that.setState({
                          parameter: paramTitle,
                          parameterIndex: paramIndex,
                        });
                        const item = that.paramDataIndex.get(`${paramIndex}`);
                        if (item && item !== null) {
                          if (item.paramData) {
                            if (item.paramData.length > 0) {
                              const data = {
                                labels: [],
                                series: [[]],
                              };
                              item.paramData.forEach((param) => {
                                if (param[0] && !!Number(param[2])) {
                                  data.labels.push(param[0]);
                                  data.series[0].push(param[2]);
                                }
                              });
                              that.setState({
                                paramChartData: data,
                                isChartDataReady: true,
                              });
                            } else {
                              that.makeData(paramCountry);
                            }
                          } else {
                            that.makeData(paramCountry);
                          }
                        } else {
                          that.makeData(paramCountry);
                        }
                      },
                    };
                    that.treeItems.set(param.Level3, l3Item);
                  }
                });
                that.treeItems.forEach((value, key, map) => {
                  if (value.subtitle === "Level3") {
                    let l3parent = map.get(value.parent);
                    if (l3parent) {
                      l3parent.children.push(value);
                      map.delete(value.parent);
                      map.set(value.parent, l3parent);
                    }
                  }
                });
                that.treeItems.forEach((value, key, map) => {
                  if (value.subtitle === "Level2") {
                    let l2parent = map.get(value.parent);
                    if (l2parent) {
                      l2parent.children.push(value);
                      map.delete(value.parent);
                      map.set(value.parent, l2parent);
                    }
                  }
                });
                let l1parent = that.treeItems.get("root-parameter");
                that.treeItems.forEach((value, key, map) => {
                  if (value.subtitle === "Level1") {
                    if (l1parent) {
                      l1parent.children.push(value);
                    }
                  }
                });
                that.treeItems.delete("root-parameter");
                that.treeItems.set("root-parameter", l1parent);

                that.setState({ treeData: [l1parent] }, () => {
                  that.setState({ isChartsModalOpen: true }, () => {
                    setTimeout(() => {
                      that.setState({ isTreeDataReady: true }, () => {
                        that.reportMap = L.map("nexusustain-reports-map", {
                          // @ts-ignore
                          center: that.state.center,
                          zoom: that.state.zoom,
                          /* gestureHandling: that.state.gestureHandling, */
                          layers: [
                            L.tileLayer(
                              "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                              {
                                attribution:
                                  '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
                              }
                            ),
                          ],
                        });
                      });
                    }, 500);
                  });
                });
              }
            }
          },
        });

        // Adding leaflet simple button for charts
        /*  chartsButton.addTo(this.map); */


        // Instantiating & adding leaflet File API
        this.fileApi = L.Control.fileLayerLoad({
          layer: L.geoJson,
          //leaflet geojson options
          layerOptions: { style: { color: "red" } },

          addToMap: true,

          fileSizeLimit: 2048,
          formats: [".geojson", ".kml", ".json", ".gpx", ".xlsx", ".xls"],
        }).addTo(this.map);

        // Eventing File API
        this.fileApi.loader.on("data:loaded", function (event) {
          const { t } = that.props;
          if (event.format.toLowerCase().indexOf("xls") > -1) {
            if (event.filename.toLowerCase().indexOf("param") === -1) {
              that.layersControl.removeLayer(that.dataLayers);
              that.layersControl.addOverlay(event.layer, "Excel");
              store.addNotification({
                title: t("SUCCESS!"),
                // eslint-disable-next-line
                message: t("Data Layer created from Excel file data"),
                type: "success",
                insert: "bottom",
                container: "bottom-left",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 6000,
                  onScreen: false,
                  pauseOnHover: true,
                  showIcon: true,
                  waitForAnimation: false,
                },
              });
            } else {
              that.setState({
                params: event.layer,
                isParamsChanged: true,
              });
              store.addNotification({
                title: t("SUCCESS!"),
                // eslint-disable-next-line
                message: t("Parameters data have been received!"),
                type: "success",
                insert: "bottom",
                container: "bottom-left",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 6000,
                  onScreen: false,
                  pauseOnHover: true,
                  showIcon: true,
                  waitForAnimation: false,
                },
              });
            }
          } else {
            that.layersControl.removeLayer(that.geoJSONLayers);
            that.layersControl.addOverlay(event.layer, "GeoJSON");
          }
        });

        // Handling File API loader errors
        this.fileApi.loader.on("data:error", function (error) {
          const { t } = that.props;
          store.addNotification({
            title: t("ERROR!"),
            // eslint-disable-next-line
            message: error,
            type: "danger",
            insert: "bottom",
            container: "bottom-left",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 6000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
          console.error(error);
        });

        // Instantiating & adding leaflet country select
        var select = L.countrySelect({ title: "Pick a country..." }).addTo(
          this.map
        );

        // leaflet country select eventing
        select.on("change", function (e) {
          if (e.feature === undefined) {
            return;
          }
          var country = L.geoJson(e.feature);
          if (this.previousCountry != null) {
            that.map.removeLayer(this.previousCountry);
          }
          this.previousCountry = country;

          that.map.addLayer(country);
          that.map.fitBounds(country.getBounds());
        });

        // leaflet overlays declaration object
        const overlays = {
          GeoJSON: this.geoJSONLayers,

          /*   LayerGroup: this.state.layergroup, */
          Excel: this.state.datagroup,
          Editables: this.state.featuregroup,
        };
        this.layersControl = L.control.layers
          .minimap(baselayers, overlays, {
            collapsed: false,
          }).addTo(this.map)
        // leaflet background layer select control minimaps



        // leaflet location filter hash
        var filter = function () {
          var hash = window.location.hash;
          var filterIndex = hash.indexOf("filter=");
          if (filterIndex !== -1) {
            var filterString = hash.substr(filterIndex + 7).trim();
            this.layersControl.filter(filterString);
          }
        };

        // Adding base layers to current map
        baselayers.OpenTopoMap.addTo(this.map);

        // Eventing window for hashchange event and filtering upon that
        L.DomEvent.on(window, "hashchange", filter);
        filter();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isBgLayersPanelOpen !== prevState.isBgLayersPanelOpen) {
      const overlays = {
        GeoJSON: this.geoJSONLayers,

        /*   LayerGroup: this.state.layergroup, */
        Excel: this.state.datagroup,
        Editables: this.state.featuregroup,
      };
      if (this.state.isBgLayersPanelOpen && !prevState.isBgLayersPanelOpen) {
        this.layersControl.filter('');

      } else if (!this.state.isBgLayersPanelOpen) {
        this.layersControl.filter('############');
      }

    }
  }

  handleEditProject() {
    const { project, editProject } = this.props;
    const { geojson, featuregroup, layergroup, datagroup } = this.state;
    let _id = project._id
    let title = project.title
    let description = project.description
    let geo = JSON.stringify(geojson)
    let nexus = project.nexus
    let avatar = project.avatar
    let tags = project.tags
    editProject(
      _id,
      title,
      description,
      geo,
      nexus,
      avatar,
      tags,
    );
  }

  // Function to get recordsCount and produce a params excel to match that count of records
 /*  handleWefParamExcelDefaultOptionsLoader(recordsCount) {
    return {
      data: null,
      csvFileName: "wefneeds-param-data",
      tableWidth: "100%",
      minDimensions: [4, recordsCount],
      search: true,
      includeHeadersOnDownload: true,

      columns: [
        {
          type: "calendar",
          title: "ParamDate",
          width: 300,
          readOnly: false,
          editable: true,
        },
        {
          type: "text",
          title: "ParamLocation",
          width: 300,
          editor: this.locationColumn,
          readOnly: false,
          editable: true,
        },
        {
          type: "numeric",
          title: "ParamValue",
          width: 200,
          readOnly: false,
          editable: true,
        },
        {
          type: "text",
          title: "ParamDescription",
          width: 300,
          readOnly: false,
          editable: true,
        },
      ],
      columnDrag: false,
      tableOverflow: true,
      lazyLoading: false,
      tableHeight: "70%",
      filters: true,
      allowComments: false,
      toolbar: [
        {
          type: "i",
          content: "undo",
          onclick: function () {
            this.excelTable.undo();
          },
        },
        {
          type: "i",
          content: "redo",
          onclick: function () {
            this.excelTable.redo();
          },
        },
        {
          type: "i",
          content: "download",
          onclick: function () {
            this.excelTable.download();
          },
        },
        {
          type: "i",
          content: "print",
          onclick: function () {
            window.print();
          },
        },
        {
          type: "i",
          content: "add",
          onclick: function () {
            this.excelTable.insertRow();
          },
        },

        {
          type: "select",
          k: "font-family",
          v: ["Arial", "Verdana"],
        },
        {
          type: "select",
          k: "font-size",
          v: [
            "9px",
            "10px",
            "11px",
            "12px",
            "13px",
            "14px",
            "15px",
            "16px",
            "17px",
            "18px",
            "19px",
            "20px",
          ],
        },
        {
          type: "i",
          content: "format_align_left",
          k: "text-align",
          v: "left",
        },
        {
          type: "i",
          content: "format_align_center",
          k: "text-align",
          v: "center",
        },
        {
          type: "i",
          content: "format_align_right",
          k: "text-align",
          v: "right",
        },
        {
          type: "i",
          content: "format_bold",
          k: "font-weight",
          v: "bold",
        },
        {
          type: "color",
          content: "format_color_text",
          k: "color",
        },
        {
          type: "color",
          content: "format_color_fill",
          k: "background-color",
        },
      ],
    };
  } */

  // Excel change handle
 /*  handleWefExcelChange(obj, cell, val) {
    let { variableMatrix, paramsDataMatrix, paramsDataMatrixSub } = this.state
    if (!this[`${variableMatrix}MATRICES`]) this[`${variableMatrix}MATRICES`] = new Map();
    let x = Number(cell.dataset.x) + 1
    let y = Number(cell.dataset.y) + 1
    this[`${variableMatrix}MATRICES`].set(`${paramsDataMatrixSub.replaceAll(' ', '')}:${x}:${y}`, cell.innerText)
    console.log(`${x}:${y}`, this[`${variableMatrix}MATRICES`].get(`${paramsDataMatrixSub.replaceAll(' ', '')}:${x}:${y}`));
    console.log(paramsDataMatrix, paramsDataMatrixSub)
  } */

  // Excel Wefneeds param data param load handle
 /*  handleWefLoadParamDataExcel(minParamRecordCount, paramIndex, paramName) {
    const elem = document.getElementById("nexusustain-params-data-window-div");
    elem.innerHTML = "";
    const options = this.handleWefParamExcelDefaultOptionsLoader(minParamRecordCount);
    const paramRecord = this.paramDataIndex.get(`${paramIndex}`);
    const paramData = paramRecord ? paramRecord.paramData : null;
    if (paramData && options) {
      options.data = paramData;
    }
    options.onfocus = this.handleExcelParamDataFocus;
    options.onchange = this.handleExcelParamDataChange;
    options.onload = this.handleExcelParamDataLoad;
    this.excelDataTable = jspreadsheet(elem, options);
  } */

  handleNexuParamsExcelChange(obj, cell, val) {
    let { paramsDataMatrix, paramsDataMatrixSub } = this.state

    let optsStr = `jspreadsheetNexu${paramsDataMatrix}Options-${this.state.paramsDataMatrixSub.replaceAll(' ', '')}`
    let matrixMapStr = `${paramsDataMatrixSub.replaceAll(' ', '')}-${paramsDataMatrix}-MATRICE`

    if (!this[matrixMapStr]) this[matrixMapStr] = new Map();



    let x = Number(cell.dataset.x)
    let y = Number(cell.dataset.y)


    if (this[optsStr]) {
      if (this[optsStr].data) {
        if (this[optsStr].data[y]) {
          if (typeof this[optsStr].data[y][x] === 'string') {
            this[optsStr].data[y][x] = cell.innerText
            this.setState({ [optsStr]: this[optsStr].data })

          }
        }
      }
    }
    let cellAddr = `${this.convertAlphaNumeric(x)}${y}`
    this[matrixMapStr].set(`${cellAddr}`, cell.innerText)
    console.log(`${x}:${y}`, this[matrixMapStr].get(`${cellAddr}`));
    console.log(paramsDataMatrix, paramsDataMatrixSub)
  }

  handleNexuChangeVariableTags(changedtags) {
    let { tags, variableMatrix } = this.state
    tags[variableMatrix] = changedtags
    this.setState({ tags: tags })
  }
  handleNexuChangeVisualizationTags(changedtags) {
    this.setState({ tagsVis: changedtags })
  }
  handleNexuLoadVariableSelectionTags() {
    let { tags, variableMatrix } = this.state
    //tags[variableMatrix] = []
    //this[`${variableMatrix}VARS`].clear();
    //this.setState({ tags: tags })
  }

  handleNexuAfterLoadVariableSelectionTags(variableMatrix) {

    let { tags } = this.state
    const that = this;

    this[`${variableMatrix}VARS`].forEach((cell) => {
      let tagIndex = tags[variableMatrix].indexOf(cell.split(':')[0])

      if (tagIndex > -1) {

        that.excelVariablesTable.setStyle(cell.split(':')[0], 'background-color', 'lightgreen');
      }

    })

    //this.setState({ tags: tags })
    //this[`${variableMatrix}MATRICES`].clear();
    //window.alert(`${variableMatrix} MATRICES Cache Cleared`)

  }

  handleNexuChangeVariableSelectionTags(instance, x1, y1, x2, y2, origin) {

    if (this.excelVariablesTable) {
      let x = x1
      let y = y1 + 1
      let { tags, variableMatrix } = this.state
      let cell = `${this.convertAlphaNumeric(x)}${y}`

      let tagIndex = tags[variableMatrix].indexOf(cell)

      if (tagIndex === -1 && x > 2 && y > 4 && (variableMatrix !== 'EPM' || cell !== 'O9')) {
        tags[variableMatrix].push(cell)
        this.excelVariablesTable.setStyle(cell, 'background-color', 'lightgreen');

        let a = this.excelVariablesTable.getValue(`${cell[0]}4`)
        let b = this.excelVariablesTable.getValue(`${cell[0]}5`)
        let c = this.excelVariablesTable.getValue(`C${cell[1]}`)

        this[`${variableMatrix}VARS`].set(`${x}:${y1}`, `${cell}:${a}(${b}):${c}`);
        this.setState({ tags: tags })
      } else if (tagIndex === -1 && x > 2 && y > 4 && (variableMatrix === 'EPM' && cell === 'O9')) {

      } else if (x > 2 && y > 4) {
        tags[variableMatrix] = tags[variableMatrix].filter((tag) => tag !== cell)
        this.excelVariablesTable.setStyle(cell, 'background-color', 'white');
        let val = this[`${variableMatrix}VARS`].get(`${x}:${y1}`);
        let valSplit = val.split(':')
        let valJoin = valSplit[1] + ':' + valSplit[2]
        this[`${variableMatrix}VARS`].delete(`${x}:${y1}`);
        let revColItem = `${variableMatrix}:${x}:${y1}`;

        let indVal = this.jspreadsheetOverviewOptions.columns.findIndex((ci) => ci.title === valJoin)
        if (indVal > -1) {
          this.jspreadsheetOverviewOptions.columns = this.jspreadsheetOverviewOptions.columns.filter((ci) => ci.title !== valJoin)
        }

        let indValVis = this.jspreadsheetVisualizationOptions.columns.findIndex((ci) => ci.title === valJoin)
        if (indValVis > -1) {
          this.jspreadsheetVisualizationOptions.columns = this.jspreadsheetVisualizationOptions.columns.filter((ci) => ci.title !== valJoin)
        }

        if (this.revCols) {
          let ind = this.revCols.indexOf(revColItem)
          if (ind > -1) {
            this.revCols = this.revCols.filter((rc) => rc !== revColItem)
          }
        }
        this.setState({ tags: tags })
      }

    }
  }

  handleNexuConvertOverviewRows(value) {
    let ind = 0;
    config.OVERVIEW_DEFAULT_OPTS.data.forEach((item, index) => {
      if (item[1] === value) {
        ind = index
      }
    })
    return ind
  }



  handleNexuLoadVariableSelectExcel(matrixName) {
    const that = this;

    if (config.SYSTEM === 'nexusustain') {

      that.setState({ isVariablesModalOpen: true, variableMatrix: matrixName }, () => {

        setTimeout(() => {
          const elem = document.getElementById('nexusustain-variable-window-div');
          if (elem) {
            elem.innerHTML = "";
            if (matrixName === 'PM') {
              that.jspreadsheetVariablesPMOptions.data = [
                ["", "", "", "Production Matrix", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "Flow Elements", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "Processed Water", "", "", "Exosomatic Energy Carriers", "", "", "", "", "", "Food Commodities", "", "", "", "", "Agricultural Flows", "", "Monetary Flows", ""],
                ["", "", "", "Treated Water", "Desalinated Water", "Reused Water", "Electricity (Hydropower)", "Electricity (Nuclear)", "Electricity (CCPP)", "Fuel", "Biofuel/Biogas", "Heat", "Animal Products", "Cereal, Pulses and Roots", "Vegetables and Fruits", "Oil Crop and Oil Fat", "Sugar Crops", "Fertilizer", "Pesticides", "GDP", "GVA"],
                ["Level", "Functional Compartment", "Title", "Mm3/yr", "Mm3/yr", "Mm3/yr", "GWh/yr", "GWh/yr", "GWh/yr", "ktoe/yr", "ktoe/yr", "GJ/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "t/yr", "t/yr", "M$/yr", "M$/yr"],
                [`Level of Compartment ${matrixName}`, `Compartment ${matrixName}`, "Production", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],

              ];
              that.jspreadsheetVariablesPMOptions.onselection = that.handleNexuChangeVariableSelectionTags;

              that.excelVariablesTable = jspreadsheet(elem, that.jspreadsheetVariablesPMOptions);


              that.excelVariablesTable.resetSelection(false)
              that.handleNexuAfterLoadVariableSelectionTags('PM')
              //that.handleNexuLoadVariableSelectionTags();
            } else if (matrixName === 'EPM') {


              that.jspreadsheetVariablesEPMOptions.data = [
                ["", "", "", "Environmental Pressure Matrix", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "Primary Flow Elements (Supply)", "", "", "", "", "", "", "", "", "", "", "Discharge Flow Elements (Sink)", "", "", "", ""],
                ["", "", "", "Primary Water Sources", "", "", "Primary Energy Sources", "", "", "", "", "", "", "Primary Agricultural Sources", "Water Pollution", "", "Land Pollution", "", "Air Pollution"],
                ["", "", "", "Blue Water - Surface", "Blue Water - Aquifer", "Green Water", "Coal", "Natural Gas", "Crude Oil and Petrol", "Uranium", "Biomass", "Solar Radiation", "Wind Speed", "Nutrients from Soil", "Nitrate Leakage", "Phosphorous Leakage", "Solid Waste", "Nuclear Waster", "GHG Emission"],
                ["Level", "Functional Compartment", "Title", "Mm3/yr", "Mm3/yr", "Mm3/yr", "kt/yr", "Mm3/yr", "bbl/yr", "kt/yr", "kt/yr", "W/m²/yr", "m/s/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "tCO2e"],
                [`Level of Compartment ${matrixName}`, `Compartment ${matrixName}`, "Internal Pressure (Domestic Use)", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "Internal Pressure - Exports", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "External Pressure - Imports", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "Internal Pressure Losses", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "External Pressure Losses", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],

              ];
              that.jspreadsheetVariablesEPMOptions.onselection = that.handleNexuChangeVariableSelectionTags;

              that.excelVariablesTable = jspreadsheet(elem, that.jspreadsheetVariablesEPMOptions);
              that.excelVariablesTable.resetSelection(false)
              that.handleNexuAfterLoadVariableSelectionTags('EPM')
              //that.handleNexuLoadVariableSelectionTags();
            } else {
              that.jspreadsheetVariablesMMMOptions.data = [
                ["", "", "", "Multi-Level, Multi-Dimensional Matrix (Inputs from Technosphere)																					", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "Flow Elements", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "Processed Water", "", "", "Exosomatic Energy Carriers(Secondary Energy Sources)", "", "", "", "", "", "Food Commodities(Primary Nutrient Sources)", "", "", "", "", "Agricultural Flows", "", "Monetary Flows", "", "Human Activity (Labor)", "Land Use", "Power Capacity", "", ""],
                ["", "", "", "Treated Water", "Desalinated Water", "Reused Water", "Electricity (Hydropower)", "Electricity (Nuclear)", "Electricity (CCPP)", "Fuel", "Biofuel/Biogas", "Heat", "Animal Products", "Cereal / Pulses / Roots", "Vegetables and Fruits", "Oil Crop and Oil Fat", "Sugar Crops", "Fertilizer", "Pesticides", "GDP", "GVA", "", "", "Electricity", "Fuel", "Heat"],
                ["", "", "Title", "Mm3/yr", "Mm3/yr", "Mm3/yr", "GWh/yr", "GWh/yr", "GWh/yr", "ktoe/yr", "ktoe/yr", "GJ/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "t/yr", "t/yr", "M$/yr", "M$/yr", "Mhr/yr", "Mm2/yr", "GWh/yr", "ktoe/yr", "GJ/yr"],
                [`Level of Compartment ${matrixName}`, `Compartment ${matrixName}`, "Internally-generated End-Use for Local Consumption", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "Externally-generated End-Use for Local Consumption", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "Internally-generated End-Use for Exports", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "Internally-generated Losses", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "Externally-generated Losses", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],

              ];
              that.jspreadsheetVariablesMMMOptions.onselection = that.handleNexuChangeVariableSelectionTags;

              that.excelVariablesTable = jspreadsheet(elem, that.jspreadsheetVariablesMMMOptions);


              that.excelVariablesTable.resetSelection(false)
              that.handleNexuAfterLoadVariableSelectionTags('MMM')
              //that.handleNexuLoadVariableSelectionTags();
            }
          }
        }, 200);
      });

    }

  }


  handleNexuLoadOverviewMatrixExcel() {
    const that = this;
    that.revCols = that.revCols || [];
    if (config.SYSTEM === 'nexusustain') {

      that.setState({ isOverviewModalOpen: true }, () => {

        setTimeout(() => {
          const elem = document.getElementById('nexusustain-overview-window-div');

          if (elem) {
            elem.innerHTML = "";
            let mtxArray = ['PM', 'EPM', 'MMM']
            let ovData = [
              ["n", "Socio-Economic System"],
              ["n-1", "Paid Work (PW)"],
              ["n-1", "Household (HH)"],
              ["n-2", "Rural"],
              ["n-2", "Urban"],
              ["n-2", "Primary Production"],
              ["n-2", "Service and Governance (SG)"],
              ["n-2", "Transportation (TR)"],
              ["n-2", "WEF Nexus Sector"],
              ["n-3", "Building and Manufacturing (BM)"],
              ["n-3", "Water Sector (W)"],
              ["n-4", "Energy and Mining (EM)"],
              ["n-4", "Agriculture (AG)"],
              ["n-4", "Fisheries"],
              ["n-5", "Fruits, Vegetables and Cereals"],
              ["n-5", "Animal Products"],
              ["n-5", "Sugar Crops"],
              ["n-5", "Oil Crops"],
              ["n-5", "Nuclear"],
              ["n-5", "Hydropower"],
              ["n-5", "CCPP"],
              ["n-5", "Water Treatment Plant (WTP)"],
              ["n-5", "Water Desalination Plant (WDP)"],
              ["n-6", "Rice"],
              ["n-6", "Tomato"],
              ["n-6", "Milk"],
              ["n-6", "Meat"],
            ]
            let ovColumns = [];
            that.jspreadsheetOverviewOptions.columns.forEach(item => ovColumns.push(item))
            mtxArray.forEach((mtxArrayItem) => {

              that[`${mtxArrayItem}VARS`].forEach((item, key) => {
                let revColItem = `${mtxArrayItem}:${key}`
                let itemSplit = item.split(':')
                if (that.revCols.indexOf(revColItem) === -1) {
                  that.revCols.push(revColItem)
                  ovColumns.push({
                    type: "text",
                    title: itemSplit[1] + ':' + itemSplit[2],
                    readOnly: false,
                    editable: false,
                    width: 100,
                  })
                }

                ovData = ovData.map((ovitem) => {
                  let ov = that[`${ovitem[1].replaceAll(' ', '')}-${mtxArrayItem}-MATRICE`]
                  if (ov && itemSplit) {
                    let cellY = itemSplit[0][1]
                    let res = ov.get(`${itemSplit[0][0]}${Number(cellY) - 1}`)
                    res ? ovitem.push(res) : ovitem.push('')
                  }
                  return ovitem

                })


              })
            })

            that.jspreadsheetOverviewOptions.data = ovData;
            that.jspreadsheetOverviewOptions.columns = ovColumns;
            that.excelOverviewTable = jspreadsheet(elem, that.jspreadsheetOverviewOptions);
          }
        }, 200);
      });

    }

  }

  handleNexuLoadRecommendationExcel() {
    const that = this;

    if (config.SYSTEM === 'nexusustain') {

      that.setState({ isRecommendationsModalOpen: true }, () => {

        setTimeout(() => {
          const elem = document.getElementById('nexusustain-recommendation-window-div');
          if (elem) {
            elem.innerHTML = "";
            that.excelRecommendationTable = jspreadsheet(elem, that.jspreadsheetRecommendationOptions);
            that.excelRecommendationTable.setStyle('F1', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F2', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F3', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F4', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F5', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F6', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F7', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F8', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F9', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F10', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F11', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F12', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F13', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F14', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F15', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F16', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F17', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F18', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F19', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F20', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F21', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F22', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F23', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F24', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F25', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F26', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F27', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F28', 'text-align', 'left');
            that.excelRecommendationTable.setStyle('F29', 'text-align', 'left');
            /* that.excelRecommendationTable.setStyle('A1:F1', 'color', 'white') */



          }
        }, 200);
      });

    }

  }
  handleNexuAfterLoadVisualizationSelectionTags() {

    let { tagsVis } = this.state
    const that = this;
    this.vis.forEach((cell) => {
      let tagIndex = tagsVis.indexOf(cell.split(':')[0])
      if (tagIndex > -1) {
        that.excelVisualizationTable.setStyle(cell.split(':')[0], 'background-color', 'lightgreen');
      }

    })

  }

  handleNexuChangeVisualizationSelectionTags(instance, x1, y1, x2, y2, origin) {

    if (this.excelVisualizationTable) {
      let x = x1
      let y = y1 + 1
      let { tagsVis } = this.state
      let cell = `${this.convertAlphaNumeric(x)}${y}`

      let tagIndex = tagsVis.indexOf(cell)
      if (tagIndex === -1 && x > 1) {
        tagsVis.push(cell)
        this.excelVisualizationTable.setStyle(cell, 'background-color', 'lightgreen');
        let a = this.excelVisualizationTable.getValue(`C${cell[1]}`);
        this.vis.set(`${x}:${y1}`, `${cell}:${a}`);
        this.setState({ tagsVis: tagsVis })
      } else if (x > 1) {
        tagsVis = tagsVis.filter((tag) => tag !== cell)
        this.excelVisualizationTable.setStyle(cell, 'background-color', 'white');
        this.vis.delete(`${x}:${y1}`);
        this.setState({ tagsVis: tagsVis })
      }
    }
  }

  //nexusustain-visualization-window-div
  handleNexuLoadVisualizationSelectExcel() {
    const that = this;
    that.revCols = that.revCols || [];
    if (config.SYSTEM === 'nexusustain') {

      that.setState({ isVisualizationModalOpen: true }, () => {

        setTimeout(() => {
          const elem = document.getElementById('nexusustain-visualization-window-div');

          if (elem) {
            elem.innerHTML = "";
            let mtxArray = ['PM', 'EPM', 'MMM']
            let ovData = [
              ["n", "Socio-Economic System"],
              ["n-1", "Paid Work (PW)"],
              ["n-1", "Household (HH)"],
              ["n-2", "Rural"],
              ["n-2", "Urban"],
              ["n-2", "Primary Production"],
              ["n-2", "Service and Governance (SG)"],
              ["n-2", "Transportation (TR)"],
              ["n-2", "WEF Nexus Sector"],
              ["n-3", "Building and Manufacturing (BM)"],
              ["n-3", "Water Sector (W)"],
              ["n-4", "Energy and Mining (EM)"],
              ["n-4", "Agriculture (AG)"],
              ["n-4", "Fisheries"],
              ["n-5", "Fruits, Vegetables and Cereals"],
              ["n-5", "Animal Products"],
              ["n-5", "Sugar Crops"],
              ["n-5", "Oil Crops"],
              ["n-5", "Nuclear"],
              ["n-5", "Hydropower"],
              ["n-5", "CCPP"],
              ["n-5", "Water Treatment Plant (WTP)"],
              ["n-5", "Water Desalination Plant (WDP)"],
              ["n-6", "Rice"],
              ["n-6", "Tomato"],
              ["n-6", "Milk"],
              ["n-6", "Meat"],
            ]
            let ovColumns = [];
            that.jspreadsheetVisualizationOptions.columns.forEach(item => ovColumns.push(item))
            mtxArray.forEach((mtxArrayItem) => {

              that[`${mtxArrayItem}VARS`].forEach((item, key) => {
                let revColItem = `${mtxArrayItem}:${key}`
                let itemSplit = item.split(':')
                if (that.revCols.indexOf(revColItem) === -1) {
                  that.revCols.push(revColItem)
                  ovColumns.push({
                    type: "text",
                    title: itemSplit[1] + ':' + itemSplit[2],
                    readOnly: false,
                    editable: false,
                    width: 100,
                  })
                }

                ovData = ovData.map((ovitem) => {
                  let ov = that[`${ovitem[1].replaceAll(' ', '')}-${mtxArrayItem}-MATRICE`]
                  if (ov && itemSplit) {
                    let cellY = itemSplit[0][1]
                    let res = ov.get(`${itemSplit[0][0]}${Number(cellY) - 1}`)
                    res ? ovitem.push(res) : ovitem.push('')
                  }
                  return ovitem

                })


              })
            })
            that.handleNexuAfterLoadVisualizationSelectionTags()
            that.jspreadsheetVisualizationOptions.data = ovData;
            that.jspreadsheetVisualizationOptions.columns = ovColumns;
            that.jspreadsheetVisualizationOptions.onselection = that.handleNexuChangeVisualizationSelectionTags;
            that.excelVisualizationTable = jspreadsheet(elem, that.jspreadsheetVisualizationOptions);
          }
        }, 200);
      });

    }

  }



  // Excel param data load handle
  handleLoadParamTreeData(elementId) {
    const that = this;
    const params = this.state.params;
    const nsTreeData = this.state.nsTreeData;
    const elem = document.getElementById(elementId);
    /* if (config.SYSTEM === 'wefneeds') {
      if (params) {
        elem.innerHTML = "";
        this.jspreadsheetOptions.data = params;
        this.jspreadsheetOptions.onfocus = this.handleExcelFocus;
        this.jspreadsheetOptions.onchange = this.handleWefExcelChange;
        this.jspreadsheetOptions.onload = this.handleExcelLoad;
        this.excelTable = jspreadsheet(elem, this.jspreadsheetOptions);
        const columnData = this.excelTable.getColumnData(8);
        this.setState({ paramList: columnData });

        columnData.forEach((cdata, index) => {
          index = index + 1;
          const cell = this.excelTable.getCell(`I${index}`);
          const item = this.paramDataIndex.get(`${index}`);
          const clink = document.createElement("a");
          if (item) {
            if (item.paramData && item.paramData !== null) {
              clink.text = `OK- ${item.paramData.length} items`;
              clink.title = cdata;
              clink.href = `link://${index}`;
              cell.setAttribute(
                "style",
                "cursor: pointer;background-color: green; text-decoration:underline"
              );
            } else {
              clink.href = `link://${index}`;
              clink.text = "No Data";
              clink.title = cdata;
              cell.setAttribute(
                "style",
                "cursor: pointer;'background-color: red'; text-decoration:underline"
              );
            }
          } else {
            clink.href = `link://${index}`;
            clink.text = "No Data";
            clink.title = cdata;
            cell.setAttribute(
              "style",
              "cursor: pointer;'background-color: red'; text-decoration:underline"
            );
          }

          cell.onclick = (event) => {
            event.preventDefault();
            console.log(event.target.text);
            console.log(event.target.title);
            console.log(event.target.href);
            const index = event.target.href.replace("link://", "");
            this.setState(
              {
                isParamsDataModalOpen: true,
                parameter: event.target.title,
                parameterIndex: index,
              },
              () => {
                let minParamRecordCount = this.excelTable.getValue(`J${index}`);
                minParamRecordCount = Number(minParamRecordCount);
                if (minParamRecordCount > 0) {
                  setTimeout(() => {
                    this.handleWefLoadParamDataExcel(
                      minParamRecordCount,
                      index,
                      event.target.title
                    );
                  }, 100);
                } else {
                  setTimeout(() => {
                    this.handleWefLoadParamDataExcel(100, index, event.target.title);
                  }, 100);
                }
                // const item = this.paramDataIndex.get(`${index}`);
              }
            );
          };
          // cdata = `<a onClick=>${cdata}</a>`
          if (!this.paramDataIndex.get(`${index}`)) {
            this.paramDataIndex.set(`${index}`, {
              paramName: cdata,
              paramIndex: index,
              paramData: null,
            });
          }
          this.excelTable.setValue(`I${index}`, "");
          cell.appendChild(clink);
        });
      } else {
        store.addNotification({
          title: this.props.t("ERROR!"),
          // eslint-disable-next-line
          message: this.props.t("No excel data has been loaded!"),
          type: "danger",
          insert: "bottom",
          container: "bottom-left",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 4000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
            waitForAnimation: false,
          },
        });
      }
    } else  */if (config.SYSTEM === 'nexusustain') {

      let finalTreeData = nsTreeData.map((nstd, index) => {
        index = index + 1;

        if (nstd.children[0]) {
          if (nstd.children[0].title === 'MMM' || nstd.children[0].title === 'PM' || nstd.children[0].title === 'EPM') {
            nstd.children[0].subtitle = nstd.title
            if (nstd.children[1]) nstd.children[1].subtitle = nstd.title
            if (nstd.children[2]) nstd.children[2].subtitle = nstd.title
          }
        }

        return {
          id: nextId("parameter"),
          index: index,
          title: nstd.title,
          subtitle: nstd.subtitle,
          color: nstd.color || "#90EE90",
          children: nstd.children.map((ntsdChld, ind) => {
            return {
              id: nextId(ntsdChld.title),
              index: ind,
              title: ntsdChld.title,
              subtitle: ntsdChld.subtitle,
              color: "#e08e0b",
              img: Logo,
              isRoot: false,
              expanded: false,
              handleClick: (
                node,
                paramTitle,
                paramSubTitle,
                paramIndex,
                paramCountry
              ) => {
                console.log(/* node, */
                  paramTitle,
                  paramSubTitle,
                  paramIndex,
                  paramCountry);
                that.setState({ isParamsDataModalOpen: true, paramsDataMatrix: paramTitle, paramsDataMatrixSub: paramSubTitle.replaceAll(' ', '') }, () => {

                  setTimeout(() => {
                    const elem = document.getElementById(`nexusustain-${that.state.paramsDataMatrix}-window-div`);
                    if (elem) {
                      elem.innerHTML = "";
                      let paramDataMatrixSub = that.state.paramsDataMatrixSub.replaceAll(' ', '');
                      let paramsDataMatrix = that.state.paramsDataMatrix
                      const toolbar = [{
                        type: "i",
                        content: "print",
                        onclick: function () {
                          window.print();
                        },
                      },
                      {
                        type: "i",
                        content: "undo",
                        onclick: function () {

                          //if (config.SYSTEM === 'wefneeds') that.excelTable.undo();
                          if (config.SYSTEM === 'nexusustain') that[`excelNexuTable-${paramDataMatrixSub}-${that.state.paramsDataMatrix}`].undo();
                        },
                      },
                      {
                        type: "i",
                        content: "redo",
                        onclick: function () {
                          //if (config.SYSTEM === 'wefneeds') that.excelTable.redo();
                          if (config.SYSTEM === 'nexusustain') that[`excelNexuTable-${paramDataMatrixSub}-${that.state.paramsDataMatrix}`].redo();

                        },
                      },
                      {
                        type: "i",
                        content: "download",
                        onclick: function () {

                          //if (config.SYSTEM === 'wefneeds') that.excelTable.download();
                          if (config.SYSTEM === 'nexusustain') that[`excelNexuTable-${paramDataMatrixSub}-${that.state.paramsDataMatrix}`].download();
                        },
                      },
                      {
                        type: "i",
                        content: "add",
                        onclick: function () {
                          //if (config.SYSTEM === 'wefneeds') that.excelTable.insertRow();
                          if (config.SYSTEM === 'nexusustain') that[`excelNexuTable-${paramDataMatrixSub}-${that.state.paramsDataMatrix}`].insertRow();
                        },
                      },
                      {
                        type: "select",
                        k: "font-family",
                        v: ["Arial", "Verdana"],
                      },
                      {
                        type: "select",
                        k: "font-size",
                        v: [
                          "9px",
                          "10px",
                          "11px",
                          "12px",
                          "13px",
                          "14px",
                          "15px",
                          "16px",
                          "17px",
                          "18px",
                          "19px",
                          "20px",
                        ],
                      },
                      {
                        type: "i",
                        content: "format_align_left",
                        k: "text-align",
                        v: "left",
                      },
                      {
                        type: "i",
                        content: "format_align_center",
                        k: "text-align",
                        v: "center",
                      },
                      {
                        type: "i",
                        content: "format_align_right",
                        k: "text-align",
                        v: "right",
                      },
                      {
                        type: "i",
                        content: "format_bold",
                        k: "font-weight",
                        v: "bold",
                      },
                      {
                        type: "color",
                        content: "format_color_text",
                        k: "color",
                      },
                      {
                        type: "color",
                        content: "format_color_fill",
                        k: "background-color",
                      },
                      ]

                      if (paramsDataMatrix === 'PM') {
                        let pmexcelNexuInstanceString = `excelNexuTable-${paramDataMatrixSub}-${paramsDataMatrix}`;
                        let pmOptionsInstanceString = `jspreadsheetNexuPMOptions-${paramDataMatrixSub}`;
                        that[pmOptionsInstanceString] = Boolean(that[pmOptionsInstanceString]) ? that[pmOptionsInstanceString] : config.PM_PARAM_DEFAULT_OPTS;

                        that[pmOptionsInstanceString].data = that.state[pmOptionsInstanceString] && that.state[pmOptionsInstanceString] !== null ? that.state[pmOptionsInstanceString] : [
                          ["", "", "", "Production Matrix", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "", "Flow Elements", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "", "Processed Water", "", "", "Exosomatic Energy Carriers", "", "", "", "", "", "Food Commodities", "", "", "", "", "Agricultural Flows", "", "Monetary Flows", ""],
                          ["", "", "", "Treated Water", "Desalinated Water", "Reused Water", "Electricity (Hydropower)", "Electricity (Nuclear)", "Electricity (CCPP)", "Fuel", "Biofuel/Biogas", "Heat", "Animal Products", "Cereal, Pulses and Roots", "Vegetables and Fruits", "Oil Crop and Oil Fat", "Sugar Crops", "Fertilizer", "Pesticides", "GDP", "GVA"],
                          ["Level", "Functional Compartment", "Title", "Mm3/yr", "Mm3/yr", "Mm3/yr", "GWh/yr", "GWh/yr", "GWh/yr", "ktoe/yr", "ktoe/yr", "GJ/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "t/yr", "t/yr", "M$/yr", "M$/yr"],
                          [`Level of Compartment ${paramDataMatrixSub}`, `Compartment ${paramDataMatrixSub}`, "Production", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],

                        ];
                        that[pmOptionsInstanceString].onchange = that.handleNexuParamsExcelChange;
                        that[pmOptionsInstanceString].toolbar = toolbar;
                        that[pmexcelNexuInstanceString] = jspreadsheet(elem, that[pmOptionsInstanceString]);


                      } else if (that.state.paramsDataMatrix === 'EPM') {
                        let epmexcelNexuInstanceString = `excelNexuTable-${paramDataMatrixSub}-${paramsDataMatrix}`;
                        let epmOptionsInstanceString = `jspreadsheetNexuEPMOptions-${paramDataMatrixSub}`;
                        that[epmOptionsInstanceString] = Boolean(that[epmOptionsInstanceString]) ? that[epmOptionsInstanceString] : config.EPM_PARAM_DEFAULT_OPTS;

                        that[epmOptionsInstanceString].data = that.state[epmOptionsInstanceString] && that.state[epmOptionsInstanceString] !== null ? that.state[epmOptionsInstanceString] : [
                          ["", "", "", "Environmental Pressure Matrix", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "", "Primary Flow Elements (Supply)", "", "", "", "", "", "", "", "", "", "", "Discharge Flow Elements (Sink)", "", "", "", ""],
                          ["", "", "", "Primary Water Sources", "", "", "Primary Energy Sources", "", "", "", "", "", "", "Primary Agricultural Sources", "Water Pollution", "", "Land Pollution", "", "Air Pollution"],
                          ["", "", "", "Blue Water - Surface", "Blue Water - Aquifer", "Green Water", "Coal", "Natural Gas", "Crude Oil and Petrol", "Uranium", "Biomass", "Solar Radiation", "Wind Speed", "Nutrients from Soil", "Nitrate Leakage", "Phosphorous Leakage", "Solid Waste", "Nuclear Waster", "GHG Emission"],
                          ["Level", "Functional Compartment", "Title", "Mm3/yr", "Mm3/yr", "Mm3/yr", "kt/yr", "Mm3/yr", "bbl/yr", "kt/yr", "kt/yr", "W/m²/yr", "m/s/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "tCO2e"],
                          [`Level of Compartment ${paramDataMatrixSub}`, `Compartment ${paramDataMatrixSub}`, "Internal Pressure (Domestic Use)", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "Internal Pressure - Exports", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "External Pressure - Imports", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "Internal Pressure Losses", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "External Pressure Losses", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],

                        ];
                        that[epmOptionsInstanceString].onchange = that.handleNexuParamsExcelChange;
                        that[epmOptionsInstanceString].toolbar = toolbar;
                        that[epmexcelNexuInstanceString] = jspreadsheet(elem, that[epmOptionsInstanceString]);


                      } else {
                        let mmmexcelNexuInstanceString = `excelNexuTable-${paramDataMatrixSub}-${paramsDataMatrix}`;
                        let mmmOptionsInstanceString = `jspreadsheetNexuMMMOptions-${paramDataMatrixSub}`;
                        that[mmmOptionsInstanceString] = Boolean(that[mmmOptionsInstanceString]) ? that[mmmOptionsInstanceString] : config.MMM_PARAM_DEFAULT_OPTS;
                        config.MMM_PARAM_DEFAULT_OPTS.data = that.state[mmmOptionsInstanceString] && that.state[mmmOptionsInstanceString] !== null ? that.state[mmmOptionsInstanceString] : [
                          ["", "", "", "Multi-Level, Multi-Dimensional Matrix (Inputs from Technosphere)																					", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "", "Flow Elements", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "", "Processed Water", "", "", "Exosomatic Energy Carriers(Secondary Energy Sources)", "", "", "", "", "", "Food Commodities(Primary Nutrient Sources)", "", "", "", "", "Agricultural Flows", "", "Monetary Flows", "", "Human Activity (Labor)", "Land Use", "Power Capacity", "", ""],
                          ["", "", "", "Treated Water", "Desalinated Water", "Reused Water", "Electricity (Hydropower)", "Electricity (Nuclear)", "Electricity (CCPP)", "Fuel", "Biofuel/Biogas", "Heat", "Animal Products", "Cereal / Pulses / Roots", "Vegetables and Fruits", "Oil Crop and Oil Fat", "Sugar Crops", "Fertilizer", "Pesticides", "GDP", "GVA", "", "", "Electricity", "Fuel", "Heat"],
                          ["", "", "Title", "Mm3/yr", "Mm3/yr", "Mm3/yr", "GWh/yr", "GWh/yr", "GWh/yr", "ktoe/yr", "ktoe/yr", "GJ/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "kt/yr", "t/yr", "t/yr", "M$/yr", "M$/yr", "Mhr/yr", "Mm2/yr", "GWh/yr", "ktoe/yr", "GJ/yr"],
                          [`Level of Compartment ${paramDataMatrixSub}`, `Compartment ${paramDataMatrixSub}`, "Internally-generated End-Use for Local Consumption", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "Externally-generated End-Use for Local Consumption", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "Internally-generated End-Use for Exports", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "Internally-generated Losses", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                          ["", "", "Externally-generated Losses", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],

                        ];


                        that[mmmOptionsInstanceString].onchange = that.handleNexuParamsExcelChange;
                        that[mmmOptionsInstanceString].toolbar = toolbar;
                        that[mmmexcelNexuInstanceString] = jspreadsheet(elem, that[mmmOptionsInstanceString]);







                      }


                    }
                  }, 200)
                });

                console.log(paramTitle + ' ::: ' + paramSubTitle)
              }
            }

          }),
          img: Logo,
          isRoot: false,
          expanded: false,
        }

      });
      this.setState({ nsTreeData: finalTreeData })

    }

  }

  // Save param data excel
  handleSaveParamDataExcel() {
    /* if (config.SYSTEM === 'wefneeds') {
      const jsonData = this.excelDataTable.getData(false);
      const paramIndex = this.state.parameterIndex;
      const paramIndexRecord = this.paramDataIndex.get(`${paramIndex}`);
      const finalData = [];
      jsonData.forEach((json) => {
        if (Number(json[2])) {
          if (json[0].length > 0) {
            finalData.push(json);
          }
        }
      });
      if (finalData.length > 0) paramIndexRecord.paramData = finalData;
      this.paramDataIndex.set(`${paramIndex}`, paramIndexRecord);
      this.setState({ isParamsDataModalOpen: false });

      this.handleLoadParamTreeData("wefneeds-params-window-div");
    } else */ if (config.SYSTEM === 'nexusustain') {
      const jsonData = this.excelNexuTable.getData(false);
      console.log(jsonData)
    }
  }

  convertAlphaNumeric(number) {
    let cell = 'A1'
    switch (number) {
      case 0:
        cell = "A";
        break;
      case 1:
        cell = "B";
        break;
      case 2:
        cell = "C";
        break;
      case 3:
        cell = "D";
        break;
      case 4:
        cell = "E";
        break;
      case 5:
        cell = "F";
        break;
      case 6:
        cell = "G";
        break;
      case 7:
        cell = "H";
        break;
      case 8:
        cell = "I";
        break;
      case 9:
        cell = "J";
        break;
      case 10:
        cell = "K";
        break;
      case 11:
        cell = "L";
        break;
      case 12:
        cell = "M";
        break;
      case 13:
        cell = "N";
        break;
      case 14:
        cell = "O";
        break;
      case 15:
        cell = "P";
        break;
      case 16:
        cell = "Q";
        break;
      case 17:
        cell = "R";
        break;
      case 18:
        cell = "S";
        break;
      case 19:
        cell = "T";
        break;
      case 20:
        cell = "U";
        break;
      case 21:
        cell = "V";
        break;
      case 22:
        cell = "W";
        break;
      case 23:
        cell = "X";
        break;
      case 24:
        cell = "Y";
        break;
      case 25:
        cell = "Z";
        break;


    }
    return cell
  }

  // Excel focus handle
  handleExcelFocus(event) {
    //console.log(event);
  }

  // Excel load handle
  handleExcelLoad(event) {
    /*    const elem = document.getElementById(
      "nexusustain-params-window-div"
    );
    const table = jspreadsheet(elem)
   
    console.log(table); */
    //console.log(event);
  }
  // Excel param data focus handle
  handleExcelParamDataFocus(event) {
    //console.log(event);
  }

  // Excel param data load handle
  handleExcelParamDataLoad(event) {
    /*    const elem = document.getElementById(
      "nexusustain-params-window-div"
    );
    const table = jspreadsheet(elem)
   
    console.log(table); */
    //console.log(event);
  }

  // Excel param data change handle
  handleExcelParamDataChange(obj, cell, val) {
    //console.log("Value: " + val);
  }

  // Render function
  render() {
    const { isDark, classes } = this.props;
    const { treeData, nsTreeData, tags, tagsVis, variableMatrix, isDialogFullScreen, isParamsModalOpen, isChart } = this.state;
    const that = this;

    return (
      <div id="map" style={{ height: "100%" }}>
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="xl"
          fullScreen={isDialogFullScreen ? true : false}
          classes={{ paper: classes.paper }}
          style={{ padding: "10px", textAlign: "center" }}
          open={isParamsModalOpen}
          onClose={() => {
            that.setState({
              isParamsModalOpen: false,
            });
          }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              that.setState({
                isParamsModalOpen: false,
                isDialogFullScreen: false,
              });
            }}
          >
            <Close />
          </IconButton>
          <IconButton
            aria-label="fullscreen"
            className={classes.fullScreenButton}
            onClick={() => {
              that.setState({
                isDialogFullScreen: !isDialogFullScreen
              });
            }}
          >
            <Fullscreen />
          </IconButton>
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {this.state.params || config.DEFAULT_NS_TREE_DATA ? (
              <Typography variant="h6">
                {/* {`System imported ${config.SYSTEM === 'wefneeds' ? this.state.params.length : config.DEFAULT_NS_TREE_DATA.length} available items`} */}
                {`System imported ${config.DEFAULT_NS_TREE_DATA.length} available items`}
              </Typography>
            ) : (
              <Typography
                style={{
                  minHeight: "450px",
                  fontWeight: "bold",
                  color: "red",
                  backgroundSize: "100% 126%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${nodata})`,
                }}
              >
                {`No Parameters available! `}
                {<br />}
                <Link
                  href="#"
                  onClick={() => {
                    let elem = document.createElement("div");
                    elem.setAttribute("id", "template-excel-download");
                    document.children[0].appendChild(elem);
                    let excelTempTable = jspreadsheet(
                      elem,
                      that.jspreadsheetOptions
                    );
                    // let headers = excelTempTable.getHeaders()

                    excelTempTable.download(true);
                    elem.parentNode.removeChild(elem);
                  }}
                  color="inherit"
                  variant="body2"
                  underline="always"
                  className={classes.link}
                >
                  {this.props.t("Download parameters workbook template...")}
                </Link>
              </Typography>
            )}
          </DialogTitle>
          {/* {this.state.params && config.SYSTEM === 'wefneeds' && (
            <div id="wefneeds-params-window-div">
              {this.props.t("Loading WefNeeds Params")}
              {loader}
            </div>
          )} */}
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12} sm={6} md={6}>
              {config.SYSTEM === 'nexusustain' && (
                <SortableTree
                  treeData={nsTreeData}
                  nodeContentRenderer={nodeRendererNexuSustain}
                  getNodeKey={({ node }) => node.id}
                  onChange={(td) => that.setState({ nsTreeData: td })}

                  canDrag={({ node }) => {
                    //if (node.isRoot) return false;
                    return false;
                  }}
                  canDrop={({ node, nextParent }) => {
                    //if (!nextParent) return false;
                    // if (nextParent && nextParent.isEditor) return false;
                    return false;
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.buttonsDiv}>
                <Button
                  className={classes.topBtn}
                  onClick={() => {
                    that.handleNexuLoadVariableSelectExcel('MMM')
                  }}
                >
                  MMM param select
                </Button>
                <Button
                  className={classes.topBtn}
                  onClick={() => {
                    that.handleNexuLoadVariableSelectExcel('EPM')
                  }}
                >
                  EPM param select
                </Button>
                <Button
                  className={classes.topBtn}
                  onClick={() => {
                    that.handleNexuLoadVariableSelectExcel('PM')
                  }}
                >
                  PM param select
                </Button>
              </div>
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    onClick={this.handleNexuLoadRecommendationExcel}
                    style={{ backgroundColor: '#d5e8ed' }}
                    className={classes.squareBtn}
                    startIcon={<AssignmentIcon />}
                  >
                    Recommendation Matrix
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    onClick={this.handleNexuLoadOverviewMatrixExcel}
                    style={{ backgroundColor: '#edd5db' }}
                    className={classes.squareBtn}
                    startIcon={<TocIcon />}
                  >
                    Review Matrix
                  </Button>
                </Grid>
                {/* <Grid item xs={6} sm={6} md={6}>
                  <Button
                    // onClick={}
                    style={{ backgroundColor: '#edead5' }}
                    className={classes.squareBtn}
                    startIcon={<RoomIcon />}
                  >
                    Map visualization
                  </Button>
                </Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    onClick={this.handleNexuLoadVisualizationSelectExcel}
                    style={{ backgroundColor: '#e1edd5' }}
                    className={classes.squareBtn}
                    startIcon={<BarChartIcon />}
                  >
                    Visualization
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>


        </Dialog>
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="xl"
          fullScreen={isDialogFullScreen ? true : false}
          classes={{ paper: classes.paper }}
          style={{ padding: "10px", textAlign: "center" }}
          open={this.state.isRecommendationsModalOpen}
          onClose={() => {
            that.setState({
              isRecommendationsModalOpen: false,
            });
          }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              that.setState({
                isRecommendationsModalOpen: false,
                isDialogFullScreen: false,
              });
            }}
          >
            <Close />
          </IconButton>
          <IconButton
            aria-label="fullscreen"
            className={classes.fullScreenButton}
            onClick={() => {
              that.setState({
                isDialogFullScreen: !isDialogFullScreen
              });
            }}
          >
            <Fullscreen />
          </IconButton>
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {config.PRECOMMENDATION_DEFAULT_OPTS ? (
              <Typography variant="h6">
                {`NexuSustain Recommendation Matrix`}
              </Typography>
            ) : (
              <Typography
                style={{
                  minHeight: "450px",
                  fontWeight: "bold",
                  color: "red",
                  backgroundSize: "100% 126%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${nodata})`,
                }}
              >
                {`No Recommendation matrix configuration data available!`}
                {<br />}
                <Link
                  href="#"
                  onClick={() => {
                    let elem = document.createElement("div");
                    elem.setAttribute("id", "template-excel-download");
                    document.children[0].appendChild(elem);
                    let excelTempTable = jspreadsheet(
                      elem,
                      that.jspreadsheetOptions
                    );
                    // let headers = excelTempTable.getHeaders()

                    excelTempTable.download(true);
                    elem.parentNode.removeChild(elem);
                  }}
                  color="inherit"
                  variant="body2"
                  underline="always"
                  className={classes.link}
                >
                  {this.props.t("Download Recommendation workbook...")}
                </Link>
              </Typography>
            )}
          </DialogTitle>

          <div style={{ display: 'block', margin: 'auto' }} id="nexusustain-recommendation-window-div">
            {this.props.t(`Loading NexuSustain Recommendations`)}
            {loader}
          </div>


        </Dialog>
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="xl"
          fullScreen={isDialogFullScreen ? true : false}
          classes={{ paper: classes.dialogPaper }}
          open={this.state.isVariablesModalOpen}
          onClose={() => {
            that.setState({
              isVariablesModalOpen: false,
            });
          }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              that.setState({
                isVariablesModalOpen: false,
                isDialogFullScreen: false,
              });
            }}
          >
            <Close />
          </IconButton>
          <IconButton
            aria-label="fullscreen"
            className={classes.fullScreenButton}
            onClick={() => {
              that.setState({
                isDialogFullScreen: !isDialogFullScreen
              });
            }}
          >
            <Fullscreen />
          </IconButton>
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {config.MMM_PARAM_DEFAULT_OPTS && config.EPM_PARAM_DEFAULT_OPTS && config.PM_PARAM_DEFAULT_OPTS ? (
              <Typography variant="h6">
                {`NexuSustain Variables ${this.state.variableMatrix} Matrix`}
              </Typography>
            ) : (
              <Typography
                style={{
                  minHeight: "450px",
                  fontWeight: "bold",
                  color: "red",
                  backgroundSize: "100% 126%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${nodata})`,
                }}
              >
                {`No ${this.state.variableMatrix} Variable matrix configuration data available!`}
                {<br />}
                <Link
                  href="#"
                  onClick={() => {
                    let elem = document.createElement("div");
                    elem.setAttribute("id", "template-excel-download");
                    document.children[0].appendChild(elem);
                    let excelTempTable = jspreadsheet(
                      elem,
                      that.jspreadsheetOptions
                    );
                    // let headers = excelTempTable.getHeaders()

                    excelTempTable.download(true);
                    elem.parentNode.removeChild(elem);
                  }}
                  color="inherit"
                  variant="body2"
                  underline="always"
                  className={classes.link}
                >
                  {this.props.t(`Download ${this.state.variableMatrix} Variables workbook...`)}
                </Link>
              </Typography>
            )}
          </DialogTitle>

          <div style={{ display: 'block', margin: 'auto' }} id="nexusustain-variable-window-div">
            {this.props.t(`Loading NexuSustain ${this.state.variableMatrix} Variables`)}
            {loader}
          </div>

          <ChipInput
            defaultValue={tags[variableMatrix]}
            fullWidth
            onChange={(tags) => that.handleNexuChangeVariableTags(tags)}
          />


        </Dialog>

        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="xl"
          fullScreen={isDialogFullScreen ? true : false}
          classes={{ paper: classes.dialogPaper }}
          open={this.state.isOverviewModalOpen}
          onClose={() => {
            that.setState({
              isOverviewModalOpen: false,
            });
          }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              that.setState({
                isOverviewModalOpen: false,
                isDialogFullScreen: false,
              });
            }}
          >
            <Close />
          </IconButton>
          <IconButton
            aria-label="fullscreen"
            className={classes.fullScreenButton}
            onClick={() => {
              that.setState({
                isDialogFullScreen: !isDialogFullScreen
              });
            }}
          >
            <Fullscreen />
          </IconButton>
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {config.OVERVIEW_DEFAULT_OPTS ? (
              <Typography variant="h6">
                {`NexuSustain Overview Matrix`}
              </Typography>
            ) : (
              <Typography
                style={{
                  minHeight: "450px",
                  fontWeight: "bold",
                  color: "red",
                  backgroundSize: "100% 126%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${nodata})`,
                }}
              >
                {`No Overview matrix configuration data available!`}
                {<br />}
                <Link
                  href="#"
                  onClick={() => {
                    let elem = document.createElement("div");
                    elem.setAttribute("id", "template-excel-download");
                    document.children[0].appendChild(elem);
                    let excelTempTable = jspreadsheet(
                      elem,
                      that.jspreadsheetOptions
                    );
                    // let headers = excelTempTable.getHeaders()

                    excelTempTable.download(true);
                    elem.parentNode.removeChild(elem);
                  }}
                  color="inherit"
                  variant="body2"
                  underline="always"
                  className={classes.link}
                >
                  {this.props.t(`Download Overview workbook...`)}
                </Link>
              </Typography>
            )}
          </DialogTitle>

          <div style={{ display: 'block', margin: 'auto' }} id="nexusustain-overview-window-div">
            {this.props.t(`Loading NexuSustain Overview`)}
            {loader}
          </div>



        </Dialog>

        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="xl"
          fullScreen={isDialogFullScreen ? true : false}
          classes={{ paper: classes.dialogPaper }}
          open={this.state.isVisualizationModalOpen}
          onClose={() => {
            that.setState({
              isVisualizationModalOpen: false,
            });
          }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              that.setState({
                isVisualizationModalOpen: false,
                isDialogFullScreen: false,
              });
            }}
          >
            <Close />
          </IconButton>
          <IconButton
            aria-label="fullscreen"
            className={classes.fullScreenButton}
            onClick={() => {
              that.setState({
                isDialogFullScreen: !isDialogFullScreen
              });
            }}
          >
            <Fullscreen />
          </IconButton>
          <IconButton
            aria-label="chart"
            className={classes.chartButton}
            onClick={() => {
              if (isChart) {
                that.handleNexuLoadVisualizationSelectExcel()
              }
              that.setState({
                isChart: !isChart
              });
            }}
          >
            {!isChart ? <BarChartIcon /> : <TocIcon />}
          </IconButton>
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {config.VISUALIZATION_DEFAULT_OPTS ? (
              <Typography variant="h6">
                {`NexuSustain Visualization Matrix`}
              </Typography>
            ) : (
              <Typography
                style={{
                  minHeight: "450px",
                  fontWeight: "bold",
                  color: "red",
                  backgroundSize: "100% 126%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${nodata})`,
                }}
              >
                {`No Visualization matrix configuration data available!`}
                {<br />}
                <Link
                  href="#"
                  onClick={() => {
                    let elem = document.createElement("div");
                    elem.setAttribute("id", "template-excel-download");
                    document.children[0].appendChild(elem);
                    let excelTempTable = jspreadsheet(
                      elem,
                      that.jspreadsheetOptions
                    );
                    // let headers = excelTempTable.getHeaders()

                    excelTempTable.download(true);
                    elem.parentNode.removeChild(elem);
                  }}
                  color="inherit"
                  variant="body2"
                  underline="always"
                  className={classes.link}
                >
                  {this.props.t(`Download Visuzalization workbook...`)}
                </Link>
              </Typography>
            )}
          </DialogTitle>

          {!isChart && (<div style={{ display: 'block', margin: 'auto', width: '100%' }} id="nexusustain-visualization-window-div">
            {this.props.t(`Loading NexuSustain Visualization`)}
            {loader}
          </div>)}
          {isChart && (<div style={{ display: 'inline-block', width: '100%' }}>
            <ApexChart fullwidth style={{ display: 'inline-block' }} options={this.state.chartOptions} series={this.state.chartSeries} type="bar" width={500} height={320} />

            <ApexChart fullwidth style={{ display: 'inline-block' }} options={this.state.pieChartOptions} series={this.state.pieChartSeries} type="pie" width={500} height={320} />
            {/*  <ApexChart options={this.state.chartOptions} series={this.state.chartSeries} type="line" width={'100%'} height={320} /> */}
          </div>)}
          <ChipInput
            defaultValue={tagsVis}
            fullWidth
            onChange={(tags) => that.handleNexuChangeVisualizationTags(tags)}
          />
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="xl"
          scroll="paper"
          //classes={{ paper: classes.paper }}
          fullScreen={isDialogFullScreen ? true : false}
          style={{ padding: "10px", textAlign: "center", alignItems: 'center' }}
          open={this.state.isParamsDataModalOpen}
          onClose={() => {
            that.handleSaveParamDataExcel();
          }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => that.setState({
              isParamsDataModalOpen: false,
              isDialogFullScreen: false,
            })}
          >
            <Close />
          </IconButton>
          <IconButton
            aria-label="fullscreen"
            className={classes.fullScreenButton}
            onClick={() => {
              that.setState({
                isDialogFullScreen: !isDialogFullScreen
              });
            }}
          >
            <Fullscreen />
          </IconButton>
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {` ${config.SYSTEM === 'wefneeds' ? this.state.parameter : this.state.paramsDataMatrixSub + ':::' + this.state.paramsDataMatrix} parameter data:`}
            {` ${this.state.paramsDataMatrixSub + ':::' + this.state.paramsDataMatrix} parameter data:`}

          </DialogTitle>

          {/* {config.SYSTEM === 'wefneeds' && (
            <div style={{ display: 'inlineBlock' }} id="wefneeds-params-data-window-div">
              {this.props.t("Loading NexuSustain")}
              {loader}
            </div>
          )} */}
          {config.SYSTEM === 'nexusustain' && (
            <div style={{ display: 'inlineBlock' }} id={`nexusustain-${this.state.paramsDataMatrix}-window-div`}>
              {this.props.t(`Loading NexuSustain ${this.state.paramsDataMatrixSub + ':::' + this.state.paramsDataMatrix}`)}
              {loader}
            </div>
          )}
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="xl"
          scroll="paper"
          fullScreen={isDialogFullScreen ? true : false}
          //classes={{ paper: classes.paper }}
          style={{ padding: "10px", textAlign: "center" }}
          open={this.state.isChartsModalOpen}
          onClose={() => {
            that.setState({ isChartsModalOpen: false });
          }}
        >
          <DialogTitle id="charts-dialog-title">
            {` ${this.state.parameter} Reports:`}
          </DialogTitle>
          {this.state.isTreeDataReady && (
            <div style={{ width: "100%", display: "block", height: "100%" }}>
              <div
                id="nexusustain-parameters-tree"
                style={{
                  height: "500px",
                  width: "50%",
                  display: "inline-block",
                }}
              >
                <SortableTree
                  treeData={treeData}
                  nodeContentRenderer={nodeRendererNexuSustain}
                  getNodeKey={({ node }) => node.id}
                  onChange={(treeData) => that.setState({ treeData })}
                  onVisibilityToggle={({
                    treeData,
                    node,
                    expanded,
                    path,
                  }) => { }}
                  canDrag={({ node }) => {
                    if (node.isRoot) return false;
                    return false;
                  }}
                  canDrop={({ node, nextParent }) => {
                    if (!nextParent) return false;
                    // if (nextParent && nextParent.isEditor) return false;
                    return false;
                  }}
                />
              </div>
              <div
                id="nexusustain-reports"
                style={{ width: "50%", display: "inline-block" }}
              >
                <div
                  id="nexusustain-reports-map"
                  style={{ height: "200px" }}
                ></div>
                <div>
                  {/* <ChartistGraph
                    style={{ display: "block" }}
                    className="ct-chart-line"
                    data={this.state.paramChartData}
                    options={config.CHARTIST_OPTIONS}
                    type={"Line"}
                  />
                  <ChartistGraph
                    style={{ display: "block" }}
                    className="ct-chart-bar"
                    data={this.state.paramChartData}
                    options={config.CHARTIST_OPTIONS}
                    type={"Bar"}
                  /> */}
                </div>
              </div>
            </div>
          )}
          {!this.state.isTreeDataReady && (
            <>
              {" "}
              {this.props.t("Loading NexuSustain")}
              {loader}
            </>
          )}
        </Dialog>
      </div>
    );
  }
}

// Map component react prop types
MapWidget.propTypes = {
  classes: PropTypes.string.isRequired,
  goHome: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

// Map component export with styles and translation instance for i18n
export default withStyles(styles)(withTranslation()(MapWidget));
