import { store } from 'react-notifications-component';
import * as types from '../constants';
import callApi from '../utils/call-api';


// eslint-disable-next-line
export function editUser({ email, displayName, avatar, language, isDarkMode }, t) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp } = state.auth;
    if (isFetching.editUser) {
      return Promise.resolve();
    }
    store.addNotification({
          title: t('INFO!'),
      message: t('Requesting user info edit'),
      type: 'info',
      insert: 'bottom',
          container: 'bottom-left',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
        onScreen: false,
        pauseOnHover: true,
        showIcon: true,
waitForAnimation: false,
      },
    });
    dispatch({
      type: types.EDIT_USER_REQUEST,
    });

    return callApi(
      '/users/me',
      token,
      otp,
      { method: 'POST' },
      {
        data: {
 email, displayName, avatar, language, isDarkMode,
},
      },
    )
      .then((json) => {
          store.addNotification({
 waitForAnimation: false,
          title: t('SUCCESS!'),
            message: t('Successfully edited user info!'),
            type: 'success',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
waitForAnimation: false,
            },
          });
          dispatch({
            type: types.EDIT_USER_SUCCESS,
            payload: json,
          });
        dispatch({
          type: types.SET_DARK_MODE,
          payload: json.user.isDark,
        })
}).then()
      .catch((reason) => {
          store.addNotification({
 waitForAnimation: false,
          title: t('ERROR!'),
            // eslint-disable-next-line
            message: t('Failed to edit user info, server says')+': '+`${t(reason.message)}`,
            type: 'danger',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
waitForAnimation: false,
            },
          });
          dispatch({
          type: types.EDIT_USER_FAILURE,
          payload: reason,
        });
});
  };
}
/*export function editUserClass({ email, userClass }, t) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp } = state.auth;
    if (isFetching.editUser) {
      return Promise.resolve();
    }
    store.addNotification({
          title: t('INFO!'),
      message: t('Requesting user info edit'),
      type: 'info',
      insert: 'bottom',
          container: 'bottom-left',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
        onScreen: false,
        pauseOnHover: true,
        showIcon: true,
waitForAnimation: false,
      },
    });
    dispatch({
      type: types.EDIT_USER_REQUEST,
    });

    return callApi(
      '/users/me',
      token,
      otp,
      { method: 'POST' },
      {
        data: {
 email, userClass,
},
      },
    )
      .then((json) => {
          store.addNotification({
 waitForAnimation: false,
          title: t('SUCCESS!'),
            message: t('Successfully edited user info!'),
            type: 'success',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
waitForAnimation: false,
            },
          });
          dispatch({
            type: types.EDIT_USER_SUCCESS,
            payload: json,
          });
       /!* dispatch({
          type: types.SET_DARK_MODE,
          payload: json.user.isDark,
        })*!/
}).then()
      .catch((reason) => {
          store.addNotification({
 waitForAnimation: false,
          title: t('ERROR!'),
            // eslint-disable-next-line
            message: t('Failed to edit user info, server says')+': '+`${t(reason.message)}`,
            type: 'danger',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
waitForAnimation: false,
            },
          });
          dispatch({
          type: types.EDIT_USER_FAILURE,
          payload: reason,
        });
});
  };
}*/
export function editUserMetrics({ email, currentMetric }, t) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp } = state.auth;
    /*if (isFetching.editUser) {
      return Promise.resolve();
    }
    store.addNotification({
          title: t('INFO!'),
      message: t('Requesting user info edit'),
      type: 'info',
      insert: 'bottom',
          container: 'bottom-left',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
        onScreen: false,
        pauseOnHover: true,
        showIcon: true,
waitForAnimation: false,
      },
    });
    dispatch({
      type: types.EDIT_USER_REQUEST,
    });*/

    return callApi(
      '/users/metrics',
      token,
      otp,
      { method: 'POST' },
      {
        data: {
 email, currentMetric,
},
      },
    )
      .then((json) => {
         /* store.addNotification({
 waitForAnimation: false,
          title: t('SUCCESS!'),
            message: t('Successfully edited user info!'),
            type: 'success',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
waitForAnimation: false,
            },
          });*/
          dispatch({
            type: types.EDIT_USER_SUCCESS,
            payload: json,
          });
       /* dispatch({
          type: types.SET_DARK_MODE,
          payload: json.user.isDark,
        })*/
}).then()
      .catch((reason) => {
         /* store.addNotification({
 waitForAnimation: false,
          title: t('ERROR!'),
            // eslint-disable-next-line
            message: t('Failed to edit user info, server says')+': '+`${t(reason.message)}`,
            type: 'danger',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
waitForAnimation: false,
            },
          });*/
          dispatch({
          type: types.EDIT_USER_FAILURE,
          payload: reason,
        });
});
  };
}
export function fetchUser(userId, t) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp } = state.auth;
    if (isFetching.fetchUser) {
      return Promise.resolve();
    }
    store.addNotification({
      title: t('INFO!'),
      message: t('Requesting user info'),
      type: t('INFO!'),
      insert: 'bottom',
          container: 'bottom-left',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
        onScreen: false,
        pauseOnHover: true,
        showIcon: true,
waitForAnimation: false,
      },
    });
    dispatch({
      type: types.FETCH_USER_REQUEST,
    });

    return callApi(
      '/users/me',
      token,
      otp,
      { method: 'GET' },
      {
        data: {
          userId,
},
      },
    )
      .then((json) => {
          store.addNotification({
            title: t('SUCCESS!'),
            message: t('Successfully fetched user info!'),
            type: 'success',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
waitForAnimation: false,
            },
          });
          dispatch({
          type: types.FETCH_USER_SUCCESS,
          payload: json,
        });
})
      .catch((reason) => {
          store.addNotification({
            title: t('ERROR!'),
            // eslint-disable-next-line
            message: t('Failed to fetch user info, server says: ')+`${t(reason.message)}`,
            type: 'danger',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
waitForAnimation: false,
            },
          });
          dispatch({
          type: types.FETCH_USER_FAILURE,
          payload: reason,
        });
});
  };
}
export function searchUsers(query, t) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp, user } = state.auth;
    if (isFetching.searchUsers) {
      return Promise.resolve();
    }
    /*store.addNotification({
      title: t('INFO!'),
      message: t('Searching users'),
      type: 'info',
      insert: 'bottom',
      container: 'bottom-left',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
        onScreen: false,
        pauseOnHover: true,
        showIcon: true,
        waitForAnimation: false,
      },
    });*/
    dispatch({
      type: types.SEARCH_USERS_REQUEST,
    });

    return callApi(
      `/users/${query}/search`,
      token,
      otp,
      { method: 'GET' },
    )
      .then((json) => {
        if (json.users.length > 0) {
          /*store.addNotification({
            title: t('SUCCESS!'),
            message: t('Found users!'),
            type: 'success',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });*/
        }
        else{
          store.addNotification({
            title: t('INFO!'),
            message: t('No user has been found!'),
            type: 'info',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
        }
        dispatch({
          type: types.SEARCH_USERS_SUCCESS,
          payload: json.users,
        });
        return json.users;
})
      .catch((reason) => {
          store.addNotification({
            title: t('ERROR!'),
            // eslint-disable-next-line
            message: t('Failed to find any users, server says: ')+`${t(reason.message)}`,
            type: 'danger',
            insert: 'bottom',
          container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              waitForAnimation: false,
            },
          });
          dispatch({
          type: types.SEARCH_USERS_FAILURE,
          payload: reason,
        });
});
  };
}




