import {
  defaultPallet,
  blueColor, defaultFont, whiteColor, grayColor,
} from '../colorSystem';

 
const landingPageStyle = (theme) => ({
  imageGrid:{
    background: '#a8ff78',
    background: '-webkit-linear-gradient(to right, #78ffd6, #a8ff78)',  
    background: 'linear-gradient(to right, #78ffd6, #a8ff78)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    // position: 'relative',
  },
  formGrid: {
    backgroundColor: grayColor[1],
    position: 'relative',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xl')]: {
      width: '50%',
      marginLeft: '22%',
      marginTop: '25%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '53%',
      marginLeft: '22%',
      marginTop: '20%',
    },
    [theme.breakpoints.down('md')]: {
      width: '60%',
      marginLeft: '22%',
      marginRight: '17%',
      marginTop: '15%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '85%',
      marginLeft: '10%',
      marginTop: '0%',
      padding: 25,
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      marginRight: '17%',
      marginLeft: '5%',
      marginTop: 0,
      padding: 10,
      paddingBottom: 35,
    },
  },
  footerTextDiv: {
    position: 'absolute',
    bottom: 0,
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
  },
  footerText: {
    display: 'inline',
    color: defaultPallet[12],
  },
  link: {
    color: defaultPallet[1],
    '&:hover': {
      color: defaultPallet[0],
    },
  },
  fullWidth: {
    width: '100%',
  },
  text: {
    position: 'absolute',
    display: 'inline-block',
    left: 'calc(42vw + 40px)',
    top: 'calc(45vh + 40px)',
  },
  overlayDiv: {
   /*  background: '#a8ff78', */    
    backgroundImage: 'linear-gradient(to right, #78ffd6, #a8ff78)',
    width: '100%',
    height: '100%',
  },
  brand: {
    color: whiteColor,
    fontWeight: 'bold',
    fontSize:'2em',
  },
  brandSubText:{
    color:'#555',
  },
  margin: {
    marginLeft: theme.spacing(1),
    margin: 'auto',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  brandWrapper: {
    [theme.breakpoints.down('xl')]: {
    marginLeft: theme.spacing(25),
    paddingTop: theme.spacing(30),
    },
    [theme.breakpoints.down('lg')]: {
    marginLeft: theme.spacing(20),
    paddingTop: theme.spacing(30),
    },
    [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(15),
    paddingTop: theme.spacing(30),
    },
    [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(8),
    paddingTop: theme.spacing(20),
    },
    [theme.breakpoints.down('xs')]: {
    marginLeft: theme.spacing(10), 
    paddingTop: theme.spacing(5),
    },
    zIndex: '3',
  },
  logoImage:{
    verticalAlign: 'middle',
    display: 'inline-block',
    width:100,
    height: 100,
  }
});

export default landingPageStyle;
