import * as types from '../constants';

const token = localStorage.getItem('nexusustain-token');
const user = JSON.parse(localStorage.getItem('nexusustain-user'));
const otp = localStorage.getItem('nexusustain-otp');

const initialState = {
  isAuthenticated: !!token,

  user: user || null,
  token,
  otp,
};

export default function auth(state = initialState, action) {
  let afterItems;
  let user;
  let index;
  switch (action.type) {
    case types.SIGNUP_SUCCESS:
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token
        
      };
    case types.RECIEVE_AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,

      };
    case types.SIGNUP_FAILURE:
    case types.LOGIN_FAILURE:
    case types.RECIEVE_AUTH_FAILURE:
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: '',
        otp: '',
      };

    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
      };
    
   
    default:
      return state;
  }
}
