import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withDialog } from "muibox";
import { withTranslation } from "react-i18next";
import styles from "../../assets/jss/components/navbarStyle";
import UserProfileSidebar from "../UserProfileSidebar";
import Typography from "@material-ui/core/Typography";
import { Avatar } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import NavbarLinks from './NavbarLinks'


const useStyles = makeStyles(styles);

function Navbar(props) {
  const classes = useStyles();

  const {
    color,
    isConnected,
    activeUser,
    logout,
    editUser,
    t,
    setDarkMode,
    isDark,
    logo,
  } = props;

  const pathName = window.location.pathname;
  
  let title = "NexuSustain";

  
  return (
    <AppBar
      position="static"
      className={isDark ? classes.appBarDark : classes.appBar}
    >

      <Toolbar className={classes.container}>
      <div className={classes.logo}>
        <a
          href="/"
          className={classes.logoLink}
          /* target="_blank"*/
        >
          <img
            id="app-main-logo"
            src={logo}
            alt="NexuSustain"
            className={classes.logoImage}
          />
          <Typography  style={{marginLeft:'100px'}} variant="h6">
              NexuSustain
              
            </Typography>
        </a>
      </div>
      
        <NavbarLinks
          classes={classes}   
          activeUser={activeUser}       
          
        />
        <Typography>

        </Typography>
        <UserProfileSidebar
          isConnected={isConnected}
          isDark={isDark}
          activeUser={activeUser}
          onLogoutClick={logout}
          onEditProfileClick={editUser}
    
        />
      </Toolbar>
    </AppBar>
  );
}
Navbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
    .isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  activeUser: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
    language: PropTypes.string,
    isMember: PropTypes.bool,
    isCreator: PropTypes.bool,
    isManager: PropTypes.bool,
    isChannelMember: PropTypes.bool,
    token: PropTypes.string,
    channel: PropTypes.object,
    otp: PropTypes.string,
    notes: PropTypes.array,
    events: PropTypes.array,
    metrics: PropTypes.array,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  setDarkMode: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
  searchUsers: PropTypes.func.isRequired,
  redirectTo: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,

};
Navbar.defaultProps = {
  activeChannel: null,
};
export default withDialog()(withWidth()(withTranslation()(Navbar)));
