// @ts-nocheck
import toGeoJSON from "@mapbox/togeojson";
import XLSX from "xlsx";
import sexagesimal from "@mapbox/sexagesimal";
import { withStyles } from "@material-ui/core/styles";

const setupFileApi = () => {
  var FileLoader = L.Layer.extend({
    options: {
      // @ts-ignore
      layer: L.geoJson,
      layerOptions: {},
      fileSizeLimit: 2048,
    },

    initialize: function (map, options) {
      this._map = map;
      L.Util.setOptions(this, options);

      this._parsers = {
        // @ts-ignore
        geojson: this._loadGeoJSON,
        
        json: this._loadGeoJSON,
       
        gpx: this._convertToGeoJSON,
       
        kml: this._convertToGeoJSON,
     
        xlsx: this._convertXlsxToGeoJSON,
        xls: this._convertXlsxToGeoJSON,
        // @ts-ignore
        /* csv: this._convertCsvToGeoJSON, */
      };
    },

    load: function (file, ext) {
      var parser, reader;

      // Check file is defined
      if (this._isParameterMissing(file, "file")) {
        return false;
      }

      // Check file size
      if (!this._isFileSizeOk(file.size)) {
        return false;
      }

      // Get parser for this data type
      parser = this._getParser(file.name, ext);
      if (!parser) {
        return false;
      }

      // Read selected file using HTML5 File API
      reader = new FileReader();
      reader.onload = L.Util.bind(function (e) {
        var layer;
        try {
          this.fire("data:loading", {
            filename: file.name,
            format: parser.ext,
          });
          layer = parser.processor.call(this, e.target.result, parser.ext, file.name);
          this.fire("data:loaded", {
            layer: layer,
            filename: file.name,
            format: parser.ext,
          });
        } catch (err) {
          this.fire("data:error", { error: err });
        }
      }, this);
      // Testing trick: tests don't pass a real file,
      // but an object with file.testing set to true.
      // This object cannot be read by reader, just skip it.
      if (parser.ext.indexOf('xls')=== -1) {
        reader.readAsText(file);
      }else{
        reader.readAsArrayBuffer(file)
      }
      // We return this to ease testing
      return reader;
    },

    loadMultiple: function (files, ext) {
      var readers = [];
      if (files[0]) {
        files = Array.prototype.slice.apply(files);
        while (files.length > 0) {
          readers.push(this.load(files.shift(), ext));
        }
      }
      // return first reader (or false if no file),
      // which is also used for subsequent loadings
      return readers;
    },

    loadData: function (data, name, ext) {
      var parser;
      var layer;

      // Check required parameters
      if (
        this._isParameterMissing(data, "data") ||
        this._isParameterMissing(name, "name")
      ) {
        return;
      }

      // Check file size
      if (!this._isFileSizeOk(data.length)) {
        return;
      }

      // Get parser for this data type
      parser = this._getParser(name, ext);
      if (!parser) {
        return;
      }

      // Process data
      try {
        this.fire("data:loading", { filename: name, format: parser.ext });
        layer = parser.processor.call(this, data, parser.ext, name);
        this.fire("data:loaded", {
          layer: layer,
          filename: name,
          format: parser.ext,
        });
      } catch (err) {
        this.fire("data:error", { error: err });
      }
    },

    _isParameterMissing: function (v, vname) {
      if (typeof v === "undefined") {
        this.fire("data:error", {
          error: new Error("Missing parameter: " + vname),
        });
        return true;
      }
      return false;
    },

    _getParser: function (name, ext) {
      var parser;
      ext = ext || name.split(".").pop();
      parser = this._parsers[ext];
      if (!parser) {
        this.fire("data:error", {
          error: new Error("Unsupported file type (" + ext + ")"),
        });
        return undefined;
      }
      return {
        processor: parser,
        ext: ext,
      };
    },

    _isFileSizeOk: function (size) {
      var fileSize = (size / 1024).toFixed(4);
      if (fileSize > this.options.fileSizeLimit) {
        this.fire("data:error", {
          error: new Error(
            "File size exceeds limit (" +
              fileSize +
              " > " +
              this.options.fileSizeLimit +
              "kb)"
          ),
        });
        return false;
      }
      return true;
    },

    _loadGeoJSON: function _loadGeoJSON(content) {
      var layer;
      if (typeof content === "string") {
        content = JSON.parse(content);
      }
      layer = this.options.layer(content, this.options.layerOptions);

      if (layer.getLayers().length === 0) {
        throw new Error("GeoJSON has no valid layers.");
      }

      if (this.options.addToMap) {
        layer.addTo(this._map);
      }
      return layer;
    },

    _convertToGeoJSON: function _convertToGeoJSON(content, format, name) {
      var geojson;
      // Format is either 'gpx' or 'kml'
      if (typeof content === "string") {
        content = new window.DOMParser().parseFromString(content, "text/xml");
      }
      // @ts-ignore
      geojson = toGeoJSON[format](content);
      return this._loadGeoJSON(geojson);
    },
    _convertCsvToGeoJSON: function _convertCsvToGeoJSON(content, format) {
      var data = new Uint8Array(content);
      var workbook = XLSX.read(data, { type: "array" });
      var sheetName = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[sheetName];
      var jsonArray = XLSX.utils.sheet_to_json(worksheet);
      var crs = "";

      var features = [];
        var featurecollection = { type: "FeatureCollection", features: features };

      if (crs !== "") {
        featurecollection.crs = { type: "name", properties: { name: crs } };
      }
      let jsonConvArray = [];
      for (let i = 0; i < jsonArray.length; i++) {
        jsonConvArray[i] = {}
        Object.keys(jsonArray[i]).forEach((jItem) => {
          
          if (jsonArray[i][jItem]) {
            if (
              jItem === "lat" ||
              jItem === "latitude" ||
              jItem === "latitude"
            ) {
              jsonConvArray[i]["lat"] = jsonArray[i][jItem];          
              
            } else if (
              jItem === "lon" ||
              jItem === "longitude" ||
              jItem === "lng"
            ) {
              jsonConvArray[i]["lon"] = jsonArray[i][jItem];           
            }else{
              jsonConvArray[i][jItem] = jsonArray[i][jItem];
            }
          }
        });
        if (
          jsonConvArray[i]["lon"] !== undefined &&
          jsonConvArray[i]["lat"] !== undefined
        ) {
          var lonk = jsonConvArray[i]["lon"],
            latk = jsonConvArray[i]["lat"],
            lonf,
            latf,
            a;

          a = sexagesimal(lonk, "EW");
          if (a) lonk = a;
          a = sexagesimal(latk, "NS");
          if (a) latk = a;

          lonf = parseFloat(lonk);
          latf = parseFloat(latk);

          if (isNaN(lonf) || isNaN(latf)) {
            console.error({
              message:
                "A row contained an invalid value for latitude or longitude",
              row: jsonArray[i],
              index: i,
            });
          } else {
            features.push({
              type: "Feature",
              properties: jsonConvArray[i],
              geometry: {
                type: "Point",
                coordinates: [parseFloat(lonf), parseFloat(latf)],
              },
            });
          }
        }
      }
      
      
      return this._loadGeoJSON(featurecollection);
    },
    _convertXlsxToGeoJSON: function _convertXlsxToGeoJSON(content, format, name) {
      var data = new Uint8Array(content);
      var workbook = XLSX.read(data, { type: "array" });
      var sheetName = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[sheetName];
      var jsonArray = XLSX.utils.sheet_to_json(worksheet);
      if(name.toLowerCase().indexOf('param')===-1){
        var crs = "";

      var features = [];
        var featurecollection = { type: "FeatureCollection", features: features };

      if (crs !== "") {
        featurecollection.crs = { type: "name", properties: { name: crs } };
      }
      let jsonConvArray = [];
      for (let i = 0; i < jsonArray.length; i++) {
        jsonConvArray[i] = {}
        Object.keys(jsonArray[i]).forEach((jItem) => {
          
          if (jsonArray[i][jItem]) {
            if (
              jItem === "lat" ||
              jItem === "latitude" ||
              jItem === "latitude"
            ) {
              jsonConvArray[i]["lat"] = jsonArray[i][jItem];          
              
            } else if (
              jItem === "lon" ||
              jItem === "longitude" ||
              jItem === "lng"
            ) {
              jsonConvArray[i]["lon"] = jsonArray[i][jItem];           
            }else{
              jsonConvArray[i][jItem] = jsonArray[i][jItem];
            }
          }
        });
        if (
          jsonConvArray[i]["lon"] !== undefined &&
          jsonConvArray[i]["lat"] !== undefined
        ) {
          var lonk = jsonConvArray[i]["lon"],
            latk = jsonConvArray[i]["lat"],
            lonf,
            latf,
            a;

          a = sexagesimal(lonk, "EW");
          if (a) lonk = a;
          a = sexagesimal(latk, "NS");
          if (a) latk = a;

          lonf = parseFloat(lonk);
          latf = parseFloat(latk);

          if (isNaN(lonf) || isNaN(latf)) {
            console.error({
              message:
                "A row contained an invalid value for latitude or longitude",
              row: jsonArray[i],
              index: i,
            });
          } else {
            features.push({
              type: "Feature",
              properties: jsonConvArray[i],
              geometry: {
                type: "Point",
                coordinates: [parseFloat(lonf), parseFloat(latf)],
              },
            });
          }
        }
      }
      
      
      return this._loadGeoJSON(featurecollection);
      }else{
        return jsonArray
      }
    },
  });

  var FileLayerLoad = L.Control.extend({
    statics: {
      TITLE: "Load files to NexuSustain (GeoJSON, KML, GPX , XLSX, XLS & JSON)",
      LABEL: "&#9921;",
    },
    options: {
      position: "topright",
      fitBounds: true,
      layerOptions: {},
      addToMap: true,
      fileSizeLimit: 1024,
    },

    initialize: function (options) {
      L.Util.setOptions(this, options);
      this.loader = null;
    },

    onAdd: function (map) {
      // @ts-ignore
      this.loader = L.FileLayer.fileLoader(map, this.options);

      this.loader.on(
        "data:loaded",
        function (e) {
          // Fit bounds after loading
          if (this.options.fitBounds) {
            if(e.filename.toLowerCase().indexOf('param')===-1){
              window.setTimeout(function () {
                map.fitBounds(e.layer.getBounds());
              }, 500);
            }
          }
        },
        this
      );

      // Initialize Drag-and-drop
      // @ts-ignore
      this._initDragAndDrop(map);

      // Initialize map control
      // @ts-ignore
      return this._initContainer();
    },

    _initDragAndDrop: function (map) {
      var callbackName;
      // @ts-ignore
      var thisLoader = this.loader;
      var dropbox = map._container;

      var callbacks = {
        dragenter: function () {
          map.scrollWheelZoom.disable();
        },
        dragleave: function () {
          map.scrollWheelZoom.enable();
        },
        dragover: function (e) {
          e.stopPropagation();
          e.preventDefault();
        },
        drop: function (e) {
          e.stopPropagation();
          e.preventDefault();

          thisLoader.loadMultiple(e.dataTransfer.files);
          map.scrollWheelZoom.enable();
        },
      };
      for (callbackName in callbacks) {
        if (callbacks.hasOwnProperty(callbackName)) {
          dropbox.addEventListener(
            callbackName,
            callbacks[callbackName],
            false
          );
        }
      }
    },

    _initContainer: function () {
      // @ts-ignore
      var thisLoader = this.loader;

      // Create a button, and bind click on hidden file input
      var fileInput;
      var zoomName = "leaflet-control-filelayer leaflet-control-zoom";
      var barName = "leaflet-bar";
      var partName = barName + "-part";
      var container = L.DomUtil.create("div", zoomName + " " + barName);
      var link = L.DomUtil.create("a", zoomName + "-in " + partName, container);
      // @ts-ignore
      link.innerHTML = L.Control.FileLayerLoad.LABEL;
      // @ts-ignore
      link.href = "#";
      // @ts-ignore
      link.title = L.Control.FileLayerLoad.TITLE;

      // Create an invisible file input
      fileInput = L.DomUtil.create("input", "hidden", container);
      // @ts-ignore
      fileInput.type = "file";
      // @ts-ignore
      fileInput.multiple = "multiple";
      if (!this.options.formats) {
        // @ts-ignore
        fileInput.accept = ".gpx,.kml,.json,.geojson,.xlsx,.xls";
      } else {
        // @ts-ignore
        fileInput.accept = this.options.formats.join(",");
      }
      fileInput.style.display = "none";
      // Load on file change
      fileInput.addEventListener(
        "change",
        function () {
          // @ts-ignore
          thisLoader.loadMultiple(this.files);
          // reset so that the user can upload the same file again if they want to
          // @ts-ignore
          this.value = "";
        },
        false
      );

      L.DomEvent.disableClickPropagation(container);
      L.DomEvent.on(link, "click", function (e) {
        fileInput.click();
        e.preventDefault();
      });
      return container;
    },
  });

  // @ts-ignore
  L.FileLayer = {};
  // @ts-ignore
  L.FileLayer.FileLoader = FileLoader;
  // @ts-ignore
  L.FileLayer.fileLoader = function (map, options) {
    // @ts-ignore
    return new L.FileLayer.FileLoader(map, options);
  };

  // @ts-ignore
  L.Control.FileLayerLoad = FileLayerLoad;
  // @ts-ignore
  L.Control.fileLayerLoad = function (options) {
    // @ts-ignore
    return new L.Control.FileLayerLoad(options);
  };
};
export default setupFileApi;
