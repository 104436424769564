import { store } from 'react-notifications-component';
import * as types from '../constants/services';
import history from '../utils/history';
import callApi from '../utils/call-api';
import { editUser } from './users'
// eslint-disable-next-line
export function redirect(to) {
  return (dispatch) => {
    history.push(to);
    dispatch({
      type: types.REDIRECT,
      payload: { to },
    });
  };
}

export function setDarkMode(isDark, t) {
  return (dispatch, getState) => {
    store.addNotification({
      title: t('INFO!'),
      // eslint-disable-next-line
      message: t('Mode has been set to')+': '+`${isDark ? t('Night') : t('Day')}`,
      type: 'info',
      insert: 'bottom',
      container: 'bottom-left',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 2000,
        onScreen: false,
        pauseOnHover: true,
        showIcon: true,
        waitForAnimation: false,
      },
    });
    const { user } = getState().auth;

    return dispatch(editUser({
      email: user.email,
      displayName: user.displayName,
      avatar: user.avatar,
      language: user.language,
      isDarkMode: Boolean(isDark),
    }, t));
  };
}

export function fetchAllProjects() {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp } = state.auth;
    if (isFetching.allProjects) {
      return Promise.resolve();
    }

    dispatch({
      type: types.FETCH_ALL_PROJECTS_REQUEST,
    });

    return callApi('/projects', token, otp)
      .then((data) => {
        dispatch({
          type: types.FETCH_ALL_PROJECTS_SUCCESS,
          payload: data,
        })
      })
      .catch((reason) => dispatch({
          type: types.FETCH_ALL_PROJECTS_FAILURE,
          payload: reason,
        }));
  };
}

export function createProject(title, description, geo, nexus, avatar, tags) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp, user } = state.auth;
    if (isFetching.createProject) {
      return Promise.resolve();
    }

    dispatch({
      type: types.CREATE_PROJECT_REQUEST,
      payload: {
        title, description, geo, nexus, avatar, tags
      },
    });

    return callApi(
      '/projects',
      token,
      otp,
      { method: 'POST' },
      {
        data: {
         title, description, geo, nexus, avatar, tags
        },
      },
    )
      .then((data) => {
          dispatch({
            type: types.CREATE_PROJECT_SUCCESS,
            payload: data,
          });
        })
      .catch((reason) => dispatch({
          type: types.CREATE_PROJECT_FAILURE,
          payload: reason,
        }));
  };
}

export function editProject(projectId,title, description, geo, nexus, avatar, tags) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp } = state.auth;

    if (isFetching.editProject) {
      return Promise.resolve();
    }

    dispatch({
      type: types.EDIT_PROJECT_REQUEST,
      payload: { projectId },
    });

    return callApi(`/projects/${projectId}`, token, otp, { method: 'POST' },{
      data: {
       title, description, geo, nexus, avatar, tags
      },
    },)
      .then((data) => {
        dispatch({
          type: types.EDIT_PROJECT_SUCCESS,
          payload: data,
        });
        store.addNotification({
          title: 'SUCCESS!',
          // eslint-disable-next-line
          message: `Project ${data.project.title} have been updated!`,
          type: 'success',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
            waitForAnimation: false,
          },
        });

        return data;
      })
      .catch((reason) =>{ 
        dispatch({
        type: types.EDIT_PROJECT_FAILURE,
        payload: reason,
      })});
  };
}


export function deleteProject(projectId) {
  return (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.services;
    const { token, otp } = state.auth;

    if (isFetching.deleteProject) {
      return Promise.resolve();
    }

    dispatch({
      type: types.DELETE_PROJECT_REQUEST,
      payload: { projectId },
    });

    return callApi(`/projects/delete/${projectId}`, token, otp, { method: 'POST' })
      .then((data) => {
        dispatch({
          type: types.DELETE_PROJECT_SUCCESS,
          payload: data,
        });

        return data;
      })
      .catch((reason) =>{ 
        dispatch({
        type: types.DELETE_PROJECT_FAILURE,
        payload: reason,
      })});
  };
}
