import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { defaultPallet, blueColor, grayColor, whiteColor } from '../assets/jss/colorSystem';
import Grid from '@material-ui/core/Grid';
import Mail from '@material-ui/icons/MailOutline';
import FormControl from '@material-ui/core/FormControl';
import Person from '@material-ui/icons/PersonOutlineOutlined';
import Lock from '@material-ui/icons/LockOutlined';
import Mobile from '@material-ui/icons/PhoneAndroid';
import LocationCity from '@material-ui/icons/LocationCity';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import styles from '../assets/jss/components/loginFormStyle';

const FormTextField = withStyles({
  root: {
    width: '90%',
    marginLeft: 10,
    '& label.Mui-focused': {
      color: defaultPallet[1],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: defaultPallet[1],
    },
    '& label': {
      color: grayColor[10],
    },
    '& .MuiInput-underline': {
      borderBottomColor: grayColor[6],
    },
    '& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: defaultPallet[1],
    },
  },
})(TextField);

class SignupForm extends React.Component {
  constructor() {
    super();
    this.state = {
      email: {
        value: '',
        isValid: true,
      },
      displayName: {
        value: '',
        isValid: true,
      },
      fullname: {
        value: '',
        isValid: true,
      },
      mobile: {
        value: '',
        isValid: true,
      },
      affiliation: {
        value: '',
        isValid: true,
      },
      password: {
        value: '',
        isValid: true,
      },
      repeatedPassword: {
        value: '',
        isValid: true,
      },
      showPassword: false,
    };

    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.validate = this.validate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDisplayNameChange = this.handleDisplayNameChange.bind(this);
    this.handleFullnameChange = this.handleFullnameChange.bind(this);
    this.handleAffiliationChange = this.handleAffiliationChange.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);

  }

  validate = () => {
    const { password, repeatedPassword } = this.state;
    const isValid = password.value === repeatedPassword.value;

    this.setState({
      password: { ...password, isValid },
      repeatedPassword: { ...repeatedPassword, isValid },
    });

    return isValid;
  };

  handleInputChange = (event) => {
    event.stopPropagation();
    const { name, value } = event.target;

    this.setState((prevState) => ({
      [name]: {
        ...prevState[name],
        value,
      },
    }));
  };
  handleDisplayNameChange = (event) => {
    const displayName = event.target.value;
   this.setState({
    displayName: {
        value: displayName,
        isValid: true,
      },
});
  };

  handleFullnameChange = (event) => {
   this.setState({
    fullname: {
        value: event.target.value,
        isValid: true,
      },
    });
  };

  handleAffiliationChange = (event) => {
   this.setState({
    affiliation: {
        value: event.target.value,
        isValid: true,
      },
    });
  };

  handleMobileChange = (event) => {
   this.setState({
    mobile: {
        value: event.target.value,
        isValid: true,
      },
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.validate()) {
      return;
    }

    const {
    password, email, displayName,fullname, mobile, affiliation,
    } = this.state;
    const { onSubmit, t } = this.props;
        onSubmit(email.value, displayName.value,fullname.value, mobile.value, affiliation.value, password.value, t);
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { classes, t } = this.props;
    const {
     email, displayName, fullname, mobile, affiliation, password, repeatedPassword,showPassword,
    } = this.state;

    return (
      <div className={classes.root} onSubmit={this.handleSubmit}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Person className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                required
                label={t('Display name')}
                type="text"
                margin="normal"
                name="display name"
                autoComplete="displayName"
                value={displayName.value}
                onChange={this.handleDisplayNameChange}
                error={!displayName.isValid}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Person className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                required
                label={t('Full Name')}
                type="text"
                margin="normal"
                name="fullname"
                autoComplete="fullname"
                value={fullname.value}
                onChange={this.handleFullnameChange}
                error={!fullname.isValid}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Mail className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                required
                label={t('Email')}
                type="text"
                margin="normal"
                name="email"
                autoComplete="email"
                value={email.value}
                onChange={this.handleInputChange}
                error={!email.isValid}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Mobile className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                required
                label={t('Mobile')}
                type="text"
                margin="normal"
                name="mobile"
                autoComplete="mobile"
                value={mobile.value}
                onChange={this.handleMobileChange}
                error={!email.isValid}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <LocationCity className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                required
                label={t('Affiliation')}
                type="text"
                margin="normal"
                name="affiliation"
                autoComplete="affiliation"
                value={affiliation.value}
                onChange={this.handleAffiliationChange}
                error={!affiliation.isValid}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Lock className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                label={t('Password')}
                name="password"
                margin="normal"
                autoComplete="new-password"
                value={password.value}
                onChange={this.handleInputChange}
                error={!password.isValid}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.visibilityIcon} /> : <VisibilityOff className={classes.visibilityIcon} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Lock className={classes.icon} />
          </Grid>
          <Grid item className={classes.input}>
            <FormControl fullWidth>
              <FormTextField
                label={t('Confirm password')}
                margin="normal"
                name="repeatedPassword"
                autoComplete="new-password"
                value={repeatedPassword.value}
                onChange={this.handleInputChange}
                error={!repeatedPassword.isValid}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {showPassword ? <Visibility className={classes.visibilityIcon} /> : <VisibilityOff className={classes.visibilityIcon} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.margin} />

        <Button
          type="submit"
          className={classes.signUpButton}
          onClick={this.handleSubmit}
        >
          {t('Create account')}
        </Button>
      </div>
      
    );
  }
}
SignupForm.propTypes={
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
}
export default withStyles(styles)(withTranslation()(SignupForm));
