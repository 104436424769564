import {
  drawerWidth,
  transition,
  container, grayColor, defaultPallet,
} from '../colorSystem';

const appStyle = (theme) => ({
  wrapper: {
    /* background: defaultPallet[0], */
    backgroundImage: 'linear-gradient(270deg,#54fde7,#bcf748)',
    position: 'relative',
    top: '0',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
  wrapperDark: {
    background: grayColor[9],
    position: 'relative',
    top: '0',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  mainPanel: {
    /* background: defaultPallet[0], */
    backgroundImage: 'linear-gradient(270deg,#54fde7,#bcf748)',
    [theme.breakpoints.up('md')]: {
      /* width: `calc(100% - ${drawerWidth}px)`, */
      width: '100%',
    },
    overflow: 'hidden',
    height: '100vh',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    /* width: `calc(100% - ${drawerWidth}px)`, */
    width: '100%',
    overflowScrolling: 'touch',
  },
  mainPanelDark: {
    background: grayColor[9],
    [theme.breakpoints.up('md')]: {
      /* width: `calc(100% - ${drawerWidth}px)`, */
      width: '100%',
    },
    overflow: 'hidden',
    height: '100vh',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  container,
  map: {
    marginTop: '80px',
  },
});

export default appStyle;
