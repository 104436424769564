import { combineReducers } from 'redux';
import auth from './auth';
import services from './services';



export default combineReducers({
  auth,
  services,

});
// eslint-disable-next-line
export const getUserId = user => user._id;
export const getActiveUser = (state) => state.auth.user;



