import React from 'react';

import LoadingBar from 'react-top-loading-bar';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
});
class TopProgressBar extends React.Component {
  constructor() {
    super();
    this.state = {
      loadingBarProgress: 0,
    };
    this.add = this.add.bind(this);
    this.onLoaderFinished = this.onLoaderFinished.bind(this);
  }

 componentDidUpdate(prevProps, prevState, snapshot) {
    const that = this;
    if (this.props.isFetching !== prevProps.isFetching) {
      if (this.props.isFetching === true) {
        this.LoadingBar.continuousStart(30);
        setTimeout(() => {
          if (!that.props.isFetching) {
            that.setState({ loadingBarProgress: 0 });
          }
        }, 5000);
      } else if (this.props.isFetching === false) {
        this.LoadingBar.complete();
        this.setState({ loadingBarProgress: 0 });
      }
    }
 }

  onLoaderFinished() {
    this.setState({ loadingBarProgress: 0 });
  }

  add(value) {
    this.setState({
      loadingBarProgress: this.state.loadingBarProgress + value,
    });
  }


  render() {
    return (
      <LoadingBar
        onRef={(ref) => (this.LoadingBar = ref)}
        progress={this.state.loadingBarProgress}
        height={4}
        color={this.props.isDark ? '#0beb46' : 'rgb(59 102 57)'}
        onLoaderFinished={() => this.onLoaderFinished()}
      />
    );
  }
}
TopProgressBar.propTypes = {
  loadingBarProgress: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(TopProgressBar);
