// eslint-disable-next-line
export const REDIRECT = Symbol('services/REDIRECT');
export const SET_DARK_MODE = Symbol('services/SET_DARK_MODE');

export const FETCH_ALL_PROJECTS_REQUEST = Symbol('channels/FETCH_ALL_PROJECTS_REQUEST');
export const FETCH_ALL_PROJECTS_SUCCESS = Symbol('channels/FETCH_ALL_PROJECTS_SUCCESS');
export const FETCH_ALL_PROJECTS_FAILURE = Symbol('channels/FETCH_ALL_PROJECTS_FAILURE');

export const CREATE_PROJECT_REQUEST = Symbol('projects/CREATE_PROJECT_REQUEST');
export const CREATE_PROJECT_SUCCESS = Symbol('projects/CREATE_PROJECT_SUCCESS');
export const CREATE_PROJECT_FAILURE = Symbol('projects/CREATE_PROJECT_FAILURE');

export const EDIT_PROJECT_REQUEST = Symbol('projects/EDIT_PROJECT_REQUEST');
export const EDIT_PROJECT_SUCCESS = Symbol('projects/EDIT_PROJECT_SUCCESS');
export const EDIT_PROJECT_FAILURE = Symbol('projects/EDIT_PROJECT_FAILURE');

export const DELETE_PROJECT_REQUEST = Symbol('projects/DELETE_PROJECT_REQUEST');
export const DELETE_PROJECT_SUCCESS = Symbol('projects/DELETE_PROJECT_SUCCESS');
export const DELETE_PROJECT_FAILURE = Symbol('projects/DELETE_PROJECT_FAILURE');