/* eslint no-underscore-dangle: 0 */
import React from 'react';
import _ from 'lodash';
import 'chartist/dist/chartist.min.css';
import moment from 'moment';
import 'moment/locale/fa';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Loader from 'react-loaders';
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";
import styles from '../../assets/jss/views/dashboardStyle';
import MapWidget from './MapWidget'
import ProjectsWidget from './ProjectsWidget'

import {
  defaultPallet,
} from '../../assets/jss/colorSystem';
import Loadable from "react-loadable";
import Typography from '@material-ui/core/Typography';
import config from '../../config';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '@material-ui/core/Button';
import { EDITOR_EDIT_RECEIVED } from '../../constants/sockets';

const appLoader = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "block",
        width: "100%",
        minHeight:"50px",
        background: "white",
        verticalAlign:"middle",
        textAlign:"center",
        borderRadius:"15px"
    
      }}
    >
      <Typography
        style={{
          display: "inline-block",
          margin:"auto",
        }}
        variant="body1"
      >
        Loading NexuSustain
      </Typography>
      <Loader
        type="ball-beat"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          transform: "scale(0.5, 0.5)",
          margin:"auto",
        }}
      />
    </div>
  );
};

const loader = <Loader type="ball-beat" style={{ float: 'right', display: 'inline-block', transform: 'scale(0.5, 0.5)' }} />;


class Dash extends React.Component {
  constructor() {
    super();
    this.state = {
      isMapWigetView: false,
      selectedProject: null,
    };
    this.filterObject = this.filterObject.bind(this);
    
  }

  componentDidMount() {
    
    const {
      match,
      socketsConnect,
      isConnected,
      activeUser,
      fetchAllProjects,
      t,
    } = this.props;
    Promise.all([
      fetchAllProjects()
    ])
      .then(() => {
        if (!isConnected)socketsConnect(t);
      }).catch((err)=>Console.log(err))
  }

/*  componentDidUpdate(prevProps) {
    const currEvents = this.props.activeUser.events;
    const prevEvents = prevProps.activeUser.events;
    if (!_.eq(currEvents, prevEvents)) {
      this.setState({ events: currEvents });
    }
}*/

  filterObject(obj) {
    let result = false;
    for (const key in obj) {
      if (obj[key] === true) {
        result = true;
      }
    }
    return result;
  }

 
  render() {
    const {
      classes,    
      isConnected,     
      isFetching,
      t,   
      activeUser,  
      isDark,
      redirectTo,
      createProject,
      deleteProject,
      editProject,
      projects
    } = this.props;
    const that = this;
    const {isMapWigetView} = this.state;
    const isFetchRunning = this.filterObject(isFetching);
    moment.locale(activeUser.language || 'en');

    return (
      <>
        <div
          className={isDark ? classes.dashPanelDark : classes.dashPanel}>
          {/* <Typography variant="h5" className={classes.panelTitle}>{t('Recent data')}</Typography>
          <Button variant="contained" className={classes.btn} >
            {t('See all')}
          </Button> */}
          {isMapWigetView && <MapWidget  project={this.state.selectedProject} editProject={editProject} goHome={()=>{
            that.setState({isMapWigetView: false})
          }} />}
            
         {!isMapWigetView && <ProjectsWidget 
            setMapWigetView={(isMapWiget, project) => this.setState({selectedProject: project, isMapWigetView: isMapWiget})} 
            createProject={createProject}
            deleteProject={deleteProject}
            editProject={editProject}
            projects={projects}
          />}
          {/* <GridContainer justify="space-between">

            <GridItem xs={12} sm={12} md={4}>
              
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
             
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              
            </GridItem>

          </GridContainer>

          <GridContainer justify="space-between">
            
          </GridContainer>

          <GridContainer justify="space-between">
           
          </GridContainer> */}
        </div>
        
      </>
    );
  }
}

Dash.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  socketsConnect: PropTypes.func.isRequired,
  activeUser: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
    isMember: PropTypes.bool,
    isCreator: PropTypes.bool,
    language: PropTypes.string,
    isManager: PropTypes.bool,
    isChannelMember: PropTypes.bool,
    token: PropTypes.string,
    channel: PropTypes.object,
    otp: PropTypes.string,

    notes: PropTypes.array,
    events: PropTypes.array,
    metrics: PropTypes.array,
  }).isRequired, 
  fetchAllProjects: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    geo: PropTypes.string,
    nexus: PropTypes.string,
    avatar: PropTypes.string,
    tags: PropTypes.string,
  })).isRequired,
  isDark: PropTypes.bool.isRequired,


};

export default withStyles(styles)(withTranslation()(Dash));