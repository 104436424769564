import _ from 'lodash';
import { combineReducers } from 'redux';
import * as types from '../constants';


const initialState = {
  isDark: false,
  boardModal: false,
  isConnected: false,
  isFetching: {
    app: false,
    signup: false,
    login: false,
    logout: false,
    receiveAuth: false,
    sockets: false,
    editUser: false,
    fetchUser: false,
    uploadInit: false,
    searchUsers: false,
    createProject: false,
    editProject: false,
    deleteProject: false,
    allProjects: false,
  },
  errors: {
    auth: null,
  },
  foundUsers: [],
  projects: [],
};

export const isFetching = (state = initialState.isFetching, action) => {
  switch (action.type) {
    case types.SIGNUP_REQUEST:
      return { ...state, signup: true };

    case types.SEARCH_USERS_REQUEST:
      return { ...state, searchUsers: true };
    case types.LOGIN_REQUEST:
      return { ...state, login: true };
    case types.LOGOUT_REQUEST:
      return { ...state, logout: true };

    case types.SOCKETS_CONNECTION_REQUEST:
      return { ...state, sockets: true };
    case types.EDIT_USER_REQUEST:
      return { ...state, editUser: true };
    case types.SIGNUP_SUCCESS:
    case types.SIGNUP_FAILURE:
      return { ...state, signup: false };
    case types.LOGIN_SUCCESS:
    case types.LOGIN_FAILURE:
      return { ...state, login: false };
    case types.LOGOUT_SUCCESS:
    case types.LOGOUT_FAILURE:
      return { ...state, logout: false };
    case types.RECIEVE_AUTH_SUCCESS:
    case types.RECIEVE_AUTH_FAILURE:
      return { ...state, receiveAuth: false };

    case types.SEARCH_USERS_SUCCESS:
    case types.SEARCH_USERS_FAILURE:
      return { ...state, searchUsers: false };

    case types.EDIT_USER_SUCCESS:
    case types.EDIT_USER_FAILURE:
      return { ...state, editUser: false };

    case types.SOCKETS_CONNECTION_SUCCESS:
    case types.SOCKETS_CONNECTION_FAILURE:
      return { ...state, sockets: false };

    case types.CREATE_PROJECT_REQUEST:
      return { ...state, createProject: true };
    case types.CREATE_PROJECT_SUCCESS:
    case types.CREATE_PROJECT_FAILURE:
      return { ...state, createProject: false };

    case types.EDIT_PROJECT_REQUEST:
      return { ...state, editProject: true };
    case types.EDIT_PROJECT_SUCCESS:
    case types.EDIT_PROJECT_FAILURE:
      return { ...state, editProject: false };

    case types.DELETE_PROJECT_REQUEST:
      return { ...state, deleteProject: true };
    case types.DELETE_PROJECT_SUCCESS:
    case types.DELETE_PROJECT_FAILURE:
      return { ...state, deleteProject: false };

    case types.FETCH_ALL_PROJECTS_REQUEST:
      return { ...state, allProjects: true };
    case types.FETCH_ALL_PROJECTS_SUCCESS:
    case types.FETCH_ALL_PROJECTS_FAILURE:
      return { ...state, allProjects: false };

    default:
      return state;
  }
};

export const errors = (state = initialState.errors, action) => {
  switch (action.type) {
    case types.SIGNUP_FAILURE:
    case types.LOGIN_FAILURE:
    case types.LOGOUT_FAILURE:
      // Used for internal needs
      // case types.RECIEVE_AUTH_FAILURE:
      return { ...state, auth: action.payload };
    case types.SIGNUP_SUCCESS:
    case types.LOGIN_SUCCESS:
    case types.LOGOUT_SUCCESS:
      // Used for internal needs
      // case types.RECIEVE_AUTH_SUCCESS:
      return { ...state, auth: null };
    case types.SOCKETS_CONNECTION_FAILURE:
    case types.EDIT_USER_FAILURE:
      return { ...state, channel: action.payload };
    case types.SOCKETS_CONNECTION_SUCCESS:
    case types.EDIT_USER_SUCCESS:
      return { ...state, channel: null };

    default:
      return state;
  }
};
export const foundUsers = (state = initialState.foundUsers, action) => {
  switch (action.type) {
    case types.SEARCH_USERS_SUCCESS:
      return action.payload.users;
    case types.SEARCH_USERS_FAILURE:
      return [];
    default:
      return state;
  }
};

export const isConnected = (state = initialState.isConnected, action) => {
  switch (action.type) {
    case types.SOCKETS_CONNECTION_MISSING:
    case types.SOCKETS_CONNECTION_FAILURE:
      return false;
    case types.SOCKETS_CONNECTION_SUCCESS:
      return true;
    default:
      return state;
  }
};


export const isDarkMode = (state = initialState.isDark, action) => {
  switch (action.type) {
    case types.RECIEVE_AUTH_SUCCESS:
      return action.payload.user.isDark || false;
    case types.SET_DARK_MODE:
      return action.payload;
    default:
      return state;
  }
};

export const projects = (state = initialState.projects, action) => {
  let oldState = state;
  switch (action.type) {
    case types.FETCH_ALL_PROJECTS_SUCCESS:
      let results = [];
      results = action.payload.projects
      return results;
    case types.CREATE_PROJECT_SUCCESS:
      oldState.push(action.payload.project)
      return oldState;
    case types.EDIT_PROJECT_SUCCESS:
      oldState = oldState.filter((project) => {
        if (project._id !== action.payload.project._id) {
          return project
        }
      })
      oldState.push(action.payload.project)
      return oldState;

    case types.DELETE_PROJECT_SUCCESS:
      return oldState.filter((project) => project._id !== action.payload.project._id)
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  errors,
  isConnected,
  isDarkMode,
  projects,
});
// eslint-disable-next-line


